import { uppercaseWord } from 'utils';
import { Link } from 'react-router-dom';
import { Button, Space, Tag, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { EyeTwoTone, DeleteTwoTone } from '@ant-design/icons';

import { TFunction } from 'i18next';

import { MEMBER_STATUS, Member } from 'constants/types';
import { getFormattedUserName } from 'utils';
import i18n from 'utils/i18n';
import Popover from 'components/shared/Popover';
import SelfProductionRatio from './SelfProductionRatio/SelfProductionRatio';
import { statusColor } from './constants';
import { TableHeader, StyledMemberNameLink } from './styles';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/global';
import { USER_STATUSES } from 'services/types';

export const columns: (
  t: TFunction,
  onDeleteConsumer?: ({
    userId,
    userName,
  }: {
    userId: string;
    userName: string;
  }) => void,
) => ColumnsType<Member> = (t, onDeleteConsumer) => [
  {
    title: <TableHeader>{uppercaseWord(i18n.t('member_name'))}</TableHeader>,
    render: (name, member: any) => (
      <Space>
        <StyledMemberNameLink
          to={`/members/${member._id}?role=${member.role}&community=${
            member.communityName
          }&name=${member.firstName} ${member.middleName} ${member.lastName}${
            member?.communityId ? `&communityId=${member?.communityId}` : ''
          }`}
        >
          {`${name.firstName} ${name.middleName} ${name.lastName}`}
        </StyledMemberNameLink>
        {member.status === USER_STATUSES.DEACTIVATED && (
          <Popover context={t('consumer_deactivated')} />
        )}
      </Space>
    ),
    sorter: (firstMember, nextMember) =>
      firstMember.firstName.localeCompare(nextMember.firstName),
    width: '15%',
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('member_role'))}</TableHeader>,
    dataIndex: 'role',
    render: (role) => t(role?.toLowerCase()),
    filters: [
      { text: 'PMO', value: 'PMO' },
      { text: t('admin'), value: 'OYO_Admin' },
      { text: t('consumer'), value: 'Consumer' },
      { text: t('producer'), value: 'Producer' },
    ],
    onFilter: (value, record) => record.role?.includes(String(value)),
    width: '5%',
  },

  {
    title: <TableHeader>{uppercaseWord(i18n.t('address'))}</TableHeader>,
    render: (record) => record.address?.street,
    sorter: (firstMember, nextMember) =>
      firstMember.address?.street.localeCompare(nextMember.address?.street),
    width: '15%',
  },

  {
    title: <TableHeader>{uppercaseWord(i18n.t('community'))}</TableHeader>,
    dataIndex: 'communityName',
    width: '15%',
    sorter: (firstMember, nextMember) =>
      firstMember.communityName.localeCompare(nextMember.communityName),
  },
  {
    title: (
      <TableHeader>
        {uppercaseWord(i18n.t('self_production_ratio_ttm'))}
      </TableHeader>
    ),
    dataIndex: 'selfProductionRatio',
    render: (_?: number, user?: Member) => (
      <SelfProductionRatio userId={user?._id} role={user?.role} />
    ),
    width: '15%',
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('status'))}</TableHeader>,
    sorter: (firstMember, nextMember) => {
      const order = {
        Active: 0,
        Pending: 1,
        Conflict: 2,
        Disabled: 3,
        Indeactivation: 4,
        Deactivated: 5,
      };
      return order[firstMember.status] - order[nextMember.status];
    },
    dataIndex: 'status',
    width: '5%',
    render: (tag: keyof typeof MEMBER_STATUS) => {
      return (
        <Tag
          color={statusColor[tag]}
          style={
            tag === MEMBER_STATUS.Indeactivation ? { color: '#595959' } : {}
          }
        >
          {t(MEMBER_STATUS[tag])}
        </Tag>
      );
    },
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('payment_status'))}</TableHeader>,
    dataIndex: 'payment',
    width: '10%',
    render: (payment) => t(payment?.toLowerCase()),
    sorter: (firstMember, nextMember) => {
      const order = {
        PAYMENT_ERROR: 0,
        OVERDUE: 1,
        PAID: 2,
        AWAITING_PAYMENT: 3,
        ISSUED: 4,
        CANCELLED: 5,
      };
      return order[firstMember.payment] - order[nextMember.payment];
    },
  },
  {
    title: <TableHeader>{uppercaseWord(i18n.t('action'))}</TableHeader>,
    width: '10%',
    render: (member) => (
      <Flex gap={4}>
        <Space>
          <Link
            to={`/members/${member._id}?role=${member.role}&community=${
              member.communityName
            }&name=${member.firstName} ${member.middleName} ${member.lastName}${
              member?.communityId ? `&communityId=${member?.communityId}` : ''
            }`}
          >
            <Button size="small" icon={<EyeTwoTone />} />
          </Link>
        </Space>
        <Space>
          {onDeleteConsumer &&
            member.role === USER_ROLES.CONSUMER &&
            member.status !== MEMBER_STATUS.Deactivated && (
              <Button
                disabled={member.status === USER_STATUSES.DELETION_INITIATED}
                onClick={() =>
                  onDeleteConsumer({
                    userId: member._id,
                    userName: getFormattedUserName(member),
                  })
                }
                size="small"
                icon={<DeleteTwoTone twoToneColor={COLORS.ErrorBase} />}
              />
            )}
        </Space>
      </Flex>
    ),
  },
];
