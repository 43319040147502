import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { isDevEnvironment } from 'constants/global';
import DashboardTab from './components/DashboardTab';
import EnergyDataTab from './components/EnergyDataTab';
import FinancialDataTab from './components/FinancialDataTab';
import ProfileTab from './components/ProfileTab';
import SettingsTab from './components/SettingsTab';
import DocumentTab from './components/DocumentTab';
import { useUrlQuery } from '../../../utils/url/getUrlQuery';

export const useTabsConfig = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useUrlQuery();
  const role = query.get('role');
  const defaultTab = query.get('tab');

  const [activeTabKey, setActiveTabKey] = useState(defaultTab || 'Dashboard');

  const handleChangeTab = (key: string) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (defaultTab && defaultTab !== activeTabKey) {
      setActiveTabKey(defaultTab);
      query.delete('tab');
      navigate({ ...location, search: query.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, activeTabKey]);

  const tabsConfig = [
    {
      label: t('dashboard').toUpperCase(),
      key: 'Dashboard',
      children: <DashboardTab role={role} />,
    },
    {
      label: t('energy_data').toUpperCase(),
      key: 'Energy',
      children: <EnergyDataTab role={role} />,
      disabled: false,
    },
    {
      label: t('financial_data').toUpperCase(),
      key: 'Financial',
      disabled: false,
      children: <FinancialDataTab role={role} />,
    },
    {
      label: t('settings').toUpperCase(),
      key: 'Settings',
      disabled: false,
      children: <SettingsTab role={role} />,
    },
    {
      label: t('profile_data').toUpperCase(),
      key: 'Profile',
      disabled: false,
      children: <ProfileTab role={role} />,
    },
  ];

  if (isDevEnvironment) {
    tabsConfig.push({
      label: t('documents').toUpperCase(),
      key: 'Documents',
      disabled: false,
      children: <DocumentTab />,
    });
  }

  return { activeTabKey, handleChangeTab, tabsConfig };
};
