import qs from 'qs';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { axiosBaseQuery } from './baseQuery';
import { Document, IDocument } from './document.types';

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  endpoints: (builder) => ({
    getFileById: builder.query<
      IDocument,
      {
        id: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `${API_URLS.DOCUMENT}/${id}`,
          method: REQUEST_METHOD.GET,
        };
      },
    }),
    getAllFilesByMemberId: builder.query<
      IDocument[],
      {
        prmId?: string;
        userId?: string;
      }
    >({
      query: ({ prmId, userId }) => {
        return {
          url: `${API_URLS.DOCUMENT}?${qs.stringify({
            userId,
            prmId,
          })}`,
          method: REQUEST_METHOD.GET,
        };
      },
    }),
    upload: builder.mutation<
      IDocument,
      { documentData: Document; prmId?: string }
    >({
      query: ({ documentData, prmId }) => {
        return {
          url: `${API_URLS.SAVE_DOCUMENT}?${qs.stringify({
            prmId,
          })}`,
          method: REQUEST_METHOD.POST,
          data: documentData,
          headers: {
            'Content-type': 'multipart/form-data;',
          },
        };
      },
    }),
    deleteDocument: builder.mutation<any, { id?: string }>({
      query: ({ id }) => ({
        url: `${API_URLS.DOCUMENT}/${id}`,
        method: REQUEST_METHOD.DELETE,
      }),
    }),
  }),
  tagTypes: ['documentApi'],
});

export const {
  useGetFileByIdQuery,
  useGetAllFilesByMemberIdQuery,
  useUploadMutation,
  useDeleteDocumentMutation,
} = documentApi;
