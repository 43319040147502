import { styled } from 'styled-components';

import { Button, DatePicker, Select } from 'antd';
import { ScreenLarge } from 'constants/screen-breakpoints';
import {
  CalendarTwoTone,
  CloseCircleFilled,
  EditTwoTone,
  FileDoneOutlined,
  LineChartOutlined,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const customTooltipStyles = { display: 'inline-block', height: '16px' };
export const customAddPriceBtnStyles = {
  width: 'auto',
  fontSize: '14px',
  padding: '0 45px',
  height: 'auto',
  borderRadius: '4px',
  boxShadow: 'none',
};

export const StyledPricesWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
`;

export const StyledPricesContainer = styled.div`
  background-color: ${COLORS.BgLayout};
  display: flex;
  border-top: 1px solid ${COLORS.ColorSplit};
  margin: 0 8px;
  padding: 16px 24px;
`;

export const StyledUpcomingPricesContainer = styled.div`
  background-color: #86efac33;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 8px 24px 8px;
  padding: 16px 24px;
  align-items: flex-start;
`;

export const StyledPriceCol = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const StyledUpcomingEditCol = styled.div`
  width: 5%;
`;

export const StyledPriceItemCol = styled.div`
  width: 11%;
  ${ScreenLarge`
    width: 13%;  
  `}
`;

export const StyledUpcomingPriceItemCol = styled.div`
  width: 14.7%;
  ${ScreenLarge`
    width: 13%;  
  `}
`;

export const StyledUpcomingPriceInfoCol = styled.div`
  width: 20%;
  ${ScreenLarge`
    width: 30%;
  `}
`;

export const StyledPriceInfoCol = styled.div`
  width: 15%;
  ${ScreenLarge`
    width: 0%;  
  `}
`;

export const StyledContractInfoCol = styled.div`
  width: 25%;
  ${ScreenLarge`
    width: 30%;  
  `}
`;

export const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    font-weight: 600;
  }
`;

export const StyledHistoricalPriceLeftCol = styled(StyledPriceCol)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 25%;
  ${ScreenLarge`
    width: 100%;
  `}
`;

export const StyledHistoricalPriceCol = styled.div`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
`;

export const StyledHistoricalPriceRow = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledUpcomingPriceRow = styled(StyledHistoricalPriceRow)`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledUpcomingPriceLeftCol = styled(
  StyledHistoricalPriceLeftCol,
)``;

export const StyledPricesTitleContainer = styled.div`
  display: flex;
`;

export const StyledPricesTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ color }) => color || COLORS.TextSecondary};
  display: inline-block;
  margin-right: 4px;
`;

export const StyledCalendarIcon = styled(CalendarTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  margin-right: 6px;
`;

export const StyledIndexIcon = styled(LineChartOutlined)`
  color: ${COLORS.Green6};
  margin-right: 6px;
`;

export const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 0 12px;
  height: 100%;
  ${ScreenLarge`
    margin: 0 8px;
  `}
`;

export const StyledPricePeriodSmall = styled.div`
  display: none;
  ${ScreenLarge`
    display: block;
  `}
`;

export const StyledPricePeriodBig = styled.div`
  display: block;
  ${ScreenLarge`
    display: none;
  `}
`;

export const StyledPriceTypeText = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
`;

export const StyledPriceUnitText = styled.span`
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
`;

export const StyledPriceValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${COLORS.BgContainer};
  padding: 8px 12px;
`;

export const StyledHistoricalPriceConatiner = styled.div`
  display: flex;
`;

export const StyledHistoricalPriceWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 75%;
  ${ScreenLarge`
    width: 100%;
  `}
`;

export const StyledUpcomingPriceWrapper = styled(
  StyledHistoricalPriceWrapper,
)``;

export const StyledHistoricalPricesContainer = styled.div<{ isShown: boolean }>`
  background-color: ${COLORS.BgLayout};
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: ${({ isShown }) =>
    isShown ? `1px solid ${COLORS.ColorSplit}` : 'none'};
  margin: 0 8px;
  padding: ${({ isShown }) => (isShown ? '16px 24px' : '0 24px')};
  height: ${({ isShown }) => (isShown ? 'auto' : '0')};
  transition: height 0.25s;
  overflow: hidden;
`;

export const StyledConsumerWrapper = styled.div`
  background-color: ${COLORS.BgContainer};
  padding: 16px 0;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

export const StyledConsumerSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
`;

export const StyledConsumerName = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-left: 16px;
`;

export const StyledConsumerInfoItem = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const FileIcon = styled(FileDoneOutlined)`
  color: ${COLORS.Green6};
  font-size: 12px;
  margin-right: 6px;
`;

export const StyledDurationText = styled.span`
  color: ${COLORS.ErrorBase};
`;

export const StyledDivider = styled.div<{ isExpanded: boolean }>`
  border-top: ${({ isExpanded }) =>
    isExpanded ? '1px solid #0000000F' : 'none'};
  margin-top: ${({ isExpanded }) => (isExpanded ? '16px' : '0')};
  padding-top: ${({ isExpanded }) => (isExpanded ? '8px' : '0')};
`;

export const StyledNoPricesContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

export const StyledNoPricesText = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000a6;
  margin-bottom: 12px;
`;

export const StyledTopFormBlock = styled.div`
  background: ${COLORS.BgLayout};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 24px 12px;
`;

export const StyledBottomFormBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
`;

export const StyledBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  gap: 8px;
`;

export const StyledDatePicker = styled(DatePicker)<any>`
  width: 264px;
  border-width: 1px;
  height: 32px;
`;

export const StyledTopFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const StyledTopFormCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  flex-basis: 50%;
  gap: 20px;
`;

export const customInputGroupStyles = {
  flexDirection: 'row',
  alignItems: 'center',
  textTransform: 'capitalize',
  justifyContent: 'space-between',
};

export const customInputLabelStyles = {
  marginRight: '10px',
  width: '104px',
};

export const ErrorIcon = styled(CloseCircleFilled)`
  font-size: 12px;
  color: ${COLORS.ErrorBase};
  margin-right: 4px;
`;

export const ErrorText = styled.div`
  color: ${COLORS.ErrorBase};
`;

export const EditIcon = styled(EditTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const StyledEditButton = styled(Button)`
  color: ${COLORS.TextBase};
  padding: 0;
`;

export const StyledDateTitle = styled.div`
  color: ${COLORS.Grey4};
  line-height: 1;
  width: 64px;
`;

export const StyledDateEndTitle = styled.div`
  color: ${COLORS.Grey4};
  margin-left: 12px;
`;
