import { styled } from 'styled-components';
import { MAX_CONTENT_WIDTH } from 'constants/screen-breakpoints';
import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const TableWrapper = styled.div`
  max-width: ${MAX_CONTENT_WIDTH}px;
  width: 100%;
`;

export const TableHeader = styled(SubHeader)`
  margin: 0;
  text-align: start;
`;

export const StyledEmptyDataWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${COLORS.BgContainerDisabled};
  display: flex;
  justify-content: center;
  align-items: center;
`;
