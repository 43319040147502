export default {
  electricityConsumption: {
    isLoading: false,
    total: 2965877,
    selfConsumption: 1258500,
    gridConsumption: 1707377,
    electricityConsumption: [
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Janv',
        value: 1497422.5,
        tooltipData: {
          year: 2024,
          month: 1,
          totalConsumption: 1497422.5,
          selfConsumption: 207389.77,
          gridConsumption: 1290032.74,
          production: 508600,
          overproduction: 88040.51,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Janv',
        value: 207389.77,
        tooltipData: {
          year: 2024,
          month: 1,
          totalConsumption: 1497422.5,
          selfConsumption: 207389.77,
          gridConsumption: 1290032.74,
          production: 508600,
          overproduction: 88040.51,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Janv',
        value: 1290032.74,
        tooltipData: {
          year: 2024,
          month: 1,
          totalConsumption: 1497422.5,
          selfConsumption: 207389.77,
          gridConsumption: 1290032.74,
          production: 508600,
          overproduction: 88040.51,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Fév',
        value: 1402168,
        tooltipData: {
          year: 2024,
          month: 2,
          totalConsumption: 1402168,
          selfConsumption: 338984.84,
          gridConsumption: 1063183.16,
          production: 887868,
          overproduction: 271961.2,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Fév',
        value: 338984.84,
        tooltipData: {
          year: 2024,
          month: 2,
          totalConsumption: 1402168,
          selfConsumption: 338984.84,
          gridConsumption: 1063183.16,
          production: 887868,
          overproduction: 271961.2,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Fév',
        value: 1063183.16,
        tooltipData: {
          year: 2024,
          month: 2,
          totalConsumption: 1402168,
          selfConsumption: 338984.84,
          gridConsumption: 1063183.16,
          production: 887868,
          overproduction: 271961.2,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Mar',
        value: 1515848,
        tooltipData: {
          year: 2024,
          month: 3,
          totalConsumption: 1515848,
          selfConsumption: 560521.04,
          gridConsumption: 955326.96,
          production: 1560336,
          overproduction: 600589.37,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Mar',
        value: 560521.04,
        tooltipData: {
          year: 2024,
          month: 3,
          totalConsumption: 1515848,
          selfConsumption: 560521.04,
          gridConsumption: 955326.96,
          production: 1560336,
          overproduction: 600589.37,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Mar',
        value: 955326.96,
        tooltipData: {
          year: 2024,
          month: 3,
          totalConsumption: 1515848,
          selfConsumption: 560521.04,
          gridConsumption: 955326.96,
          production: 1560336,
          overproduction: 600589.37,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Avr',
        value: 1371256,
        tooltipData: {
          year: 2024,
          month: 4,
          totalConsumption: 1371256,
          selfConsumption: 622479.11,
          gridConsumption: 748776.89,
          production: 1621840,
          overproduction: 685551.96,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Avr',
        value: 622479.11,
        tooltipData: {
          year: 2024,
          month: 4,
          totalConsumption: 1371256,
          selfConsumption: 622479.11,
          gridConsumption: 748776.89,
          production: 1621840,
          overproduction: 685551.96,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Avr',
        value: 748776.89,
        tooltipData: {
          year: 2024,
          month: 4,
          totalConsumption: 1371256,
          selfConsumption: 622479.11,
          gridConsumption: 748776.89,
          production: 1621840,
          overproduction: 685551.96,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Mai',
        value: 1363294,
        tooltipData: {
          year: 2024,
          month: 5,
          totalConsumption: 1363294,
          selfConsumption: 782601.16,
          gridConsumption: 580692.84,
          production: 2211832,
          overproduction: 1035790.06,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Mai',
        value: 782601.16,
        tooltipData: {
          year: 2024,
          month: 5,
          totalConsumption: 1363294,
          selfConsumption: 782601.16,
          gridConsumption: 580692.84,
          production: 2211832,
          overproduction: 1035790.06,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Mai',
        value: 580692.84,
        tooltipData: {
          year: 2024,
          month: 5,
          totalConsumption: 1363294,
          selfConsumption: 782601.16,
          gridConsumption: 580692.84,
          production: 2211832,
          overproduction: 1035790.06,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Juin',
        value: 1292686,
        tooltipData: {
          year: 2024,
          month: 6,
          totalConsumption: 1292686,
          selfConsumption: 713769.83,
          gridConsumption: 578916.17,
          production: 2033416,
          overproduction: 929166.99,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Juin',
        value: 713769.83,
        tooltipData: {
          year: 2024,
          month: 6,
          totalConsumption: 1292686,
          selfConsumption: 713769.83,
          gridConsumption: 578916.17,
          production: 2033416,
          overproduction: 929166.99,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Juin',
        value: 578916.17,
        tooltipData: {
          year: 2024,
          month: 6,
          totalConsumption: 1292686,
          selfConsumption: 713769.83,
          gridConsumption: 578916.17,
          production: 2033416,
          overproduction: 929166.99,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Juil',
        value: 1360528,
        tooltipData: {
          year: 2024,
          month: 7,
          totalConsumption: 1360528,
          selfConsumption: 818538.92,
          gridConsumption: 541989.08,
          production: 2427088,
          overproduction: 1219469.76,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Juil',
        value: 818538.92,
        tooltipData: {
          year: 2024,
          month: 7,
          totalConsumption: 1360528,
          selfConsumption: 818538.92,
          gridConsumption: 541989.08,
          production: 2427088,
          overproduction: 1219469.76,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Juil',
        value: 541989.08,
        tooltipData: {
          year: 2024,
          month: 7,
          totalConsumption: 1360528,
          selfConsumption: 818538.92,
          gridConsumption: 541989.08,
          production: 2427088,
          overproduction: 1219469.76,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Août',
        value: 799930,
        tooltipData: {
          year: 2024,
          month: 8,
          totalConsumption: 799930,
          selfConsumption: 462931.65,
          gridConsumption: 336998.35,
          production: 2002170,
          overproduction: 1237626.2,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Août',
        value: 462931.65,
        tooltipData: {
          year: 2024,
          month: 8,
          totalConsumption: 799930,
          selfConsumption: 462931.65,
          gridConsumption: 336998.35,
          production: 2002170,
          overproduction: 1237626.2,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Août',
        value: 336998.35,
        tooltipData: {
          year: 2024,
          month: 8,
          totalConsumption: 799930,
          selfConsumption: 462931.65,
          gridConsumption: 336998.35,
          production: 2002170,
          overproduction: 1237626.2,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Sept',
        value: 1217094,
        tooltipData: {
          year: 2024,
          month: 9,
          totalConsumption: 1217094,
          selfConsumption: 516696.06,
          gridConsumption: 700397.94,
          production: 1285574,
          overproduction: 433979.13,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Sept',
        value: 516696.06,
        tooltipData: {
          year: 2024,
          month: 9,
          totalConsumption: 1217094,
          selfConsumption: 516696.06,
          gridConsumption: 700397.94,
          production: 1285574,
          overproduction: 433979.13,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Sept',
        value: 700397.94,
        tooltipData: {
          year: 2024,
          month: 9,
          totalConsumption: 1217094,
          selfConsumption: 516696.06,
          gridConsumption: 700397.94,
          production: 1285574,
          overproduction: 433979.13,
        },
      },
      {
        category: 'TOTAL_CONSUMPTION',
        date: 'Oct',
        value: 43282,
        tooltipData: {
          year: 2024,
          month: 10,
          totalConsumption: 43282,
          selfConsumption: 10087.04,
          gridConsumption: 33194.96,
          production: 31068,
          overproduction: 10877.66,
        },
      },
      {
        category: 'SELF-CONSUMPTION',
        date: 'Oct',
        value: 10087.04,
        tooltipData: {
          year: 2024,
          month: 10,
          totalConsumption: 43282,
          selfConsumption: 10087.04,
          gridConsumption: 33194.96,
          production: 31068,
          overproduction: 10877.66,
        },
      },
      {
        category: 'GRID_CONSUMPTION',
        date: 'Oct',
        value: 33194.96,
        tooltipData: {
          year: 2024,
          month: 10,
          totalConsumption: 43282,
          selfConsumption: 10087.04,
          gridConsumption: 33194.96,
          production: 31068,
          overproduction: 10877.66,
        },
      },
    ],
  },
  repartitionKey: {
    data: [
      {
        date: 'Janv',
        value: 13,
        tooltipData: {
          year: 2024,
          month: 1,
          repartitionKey: 13,
        },
      },
      {
        date: 'Fév',
        value: 18.9,
        tooltipData: {
          year: 2024,
          month: 2,
          repartitionKey: 18.9,
        },
      },
      {
        date: 'Mar',
        value: 23.6,
        tooltipData: {
          year: 2024,
          month: 3,
          repartitionKey: 23.6,
        },
      },
      {
        date: 'Avr',
        value: 35.7,
        tooltipData: {
          year: 2024,
          month: 4,
          repartitionKey: 35.7,
        },
      },
      {
        date: 'Mai',
        value: 41,
        tooltipData: {
          year: 2024,
          month: 5,
          repartitionKey: 41,
        },
      },
      {
        date: 'Juin',
        value: 41.5,
        tooltipData: {
          year: 2024,
          month: 6,
          repartitionKey: 41.5,
        },
      },
      {
        date: 'Juil',
        value: 42.5,
        tooltipData: {
          year: 2024,
          month: 7,
          repartitionKey: 42.5,
        },
      },
      {
        date: 'Août',
        value: 31.8,
        tooltipData: {
          year: 2024,
          month: 8,
          repartitionKey: 31.8,
        },
      },
      {
        date: 'Sept',
        value: 28.7,
        tooltipData: {
          year: 2024,
          month: 9,
          repartitionKey: 28.7,
        },
      },
      {
        date: 'Oct',
        value: 19.9,
        tooltipData: {
          year: 2024,
          month: 10,
          repartitionKey: 19.9,
        },
      },
    ],
    percent: 29.7,
    isLoading: false,
  },
};
