import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';
import { axiosBaseQuery } from 'services/baseQuery';

import { LOCAL_STORAGE_KEYS } from 'utils/local-storage';

export const userManagement = createApi({
  reducerPath: 'userManagement',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  endpoints: (build) => ({
    putCreatePassword: build.mutation<any, any>({
      query: ({ id, password }) => ({
        data: {
          id,
          password,
        },
        method: REQUEST_METHOD.PUT,
        url: API_URLS.UPDATE_PASSWORD,
      }),
    }),

    putForgotPassword: build.mutation<any, any>({
      query: (email) => ({
        data: {
          email,
        },
        method: REQUEST_METHOD.PUT,
        url: API_URLS.REQUEST_CHANGE_PASSWORD,
      }),
    }),
    validateToken: build.query<any, string>({
      query: (token: string) => ({
        url: API_URLS.VALIDATE_TOKEN(token),
        method: REQUEST_METHOD.GET,
      }),
    }),
    getUserMetadata: build.query<
      { lang: string; onboardingStep?: number },
      any
    >({
      query: () => ({
        url: API_URLS.USER_METADATA,
        method: REQUEST_METHOD.GET,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_KEYS.AccessToken,
          )}`,
        },
      }),
    }),
    setUserMetadata: build.mutation<
      any,
      { lang?: string; onboardingStep?: number }
    >({
      query: (data) => ({
        url: API_URLS.USER_METADATA,
        method: REQUEST_METHOD.PUT,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_KEYS.AccessToken,
          )}`,
        },
        data,
      }),
    }),
    deleteUser: build.mutation<any, { userId: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.MEMBERS}/${userId}`,
        method: REQUEST_METHOD.DELETE,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_KEYS.AccessToken,
          )}`,
        },
      }),
    }),
    resendInviteEmail: build.query<any, { userId: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.MEMBERS}/${userId}/${API_URLS.RESEND_INVITE}`,
        method: REQUEST_METHOD.GET,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_KEYS.AccessToken,
          )}`,
        },
      }),
    }),
    contactUs: build.mutation<
      any,
      {
        message: string;
        email: string;
        company?: string;
        name: string;
        jobTitle?: string;
      }
    >({
      query: (data) => ({
        data,
        method: REQUEST_METHOD.POST,
        url: API_URLS.CONTACT_US,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_KEYS.AccessToken,
          )}`,
        },
      }),
    }),
  }),
  tagTypes: ['UserManagement'],
});

export const {
  usePutCreatePasswordMutation,
  usePutForgotPasswordMutation,
  useValidateTokenQuery,
  useDeleteUserMutation,
  useLazyResendInviteEmailQuery,
  useContactUsMutation,
  useGetUserMetadataQuery,
  useSetUserMetadataMutation,
} = userManagement;
