import { CSSProperties, ReactNode } from 'react';

import { styled } from 'styled-components';

import { Button } from 'antd';

import { COLORS } from 'constants/colors';

const ButtonPrimary = styled(Button)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TextBase};
  background-color: ${COLORS.Green5};

  &:hover {
    color: ${COLORS.TextBase} !important;
    background-color: ${COLORS.Green6} !important;
  }

  &:disabled {
    background-color: ${COLORS.Grey2};
    color: ${COLORS.Grey3};
    border: 1px solid ${COLORS.Grey3};
  }

  &:hover:disabled {
    background-color: ${COLORS.Grey2} !important;
    color: ${COLORS.Grey3} !important;
  }
`;
interface IBtnPrimary {
  isDisabled?: boolean;
  onClick?: () => void;
  htmlType?: string;
  children: ReactNode;
  additionalStyles?: CSSProperties;
  icon?: ReactNode;
}
export const BtnPrimary = ({
  isDisabled,
  onClick,
  children,
  additionalStyles,
  icon,
}: IBtnPrimary) => (
  <ButtonPrimary
    type="primary"
    size="large"
    disabled={isDisabled}
    onClick={onClick}
    style={additionalStyles}
    icon={icon}
  >
    {children}
  </ButtonPrimary>
);
