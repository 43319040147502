export default {
  isLoading: false,
  prm: {
    id: '652faa8605224a44b4544c88',
    prmValue: '30000651205433',
    power: 50,
    name: 'AMTE PRM 4',
    status: 'Active',
    prmType: 'medium',
  },
  prmPrices: {
    _id: '66732760a4ac2a2751567adf',
    community: '651beaf9dd5ec2d0948d1b10',
    prm: {
      _id: '652faa8605224a44b4544c88',
      prmValue: '30000651205433',
      power: 50,
      name: 'AMTE PRM 4',
      prmType: 'medium',
      role: 'Consumer',
      status: 'Active',
      user: {
        _id: '652faa8505224a44b4544c7f',
        role: 'Consumer',
        status: 'Pending',
        firstName: 'AMTE',
        middleName: '',
        lastName: 'Group',
        email: 'tecake7894@ksyhtc.com',
        contactNumber: '+33381823544',
        address: {
          city: 'Besançon',
          country: 'France',
          postalCode: 25000,
          street: "1 Rue de l'Industrie, 25220 Thise, France",
          building: '',
          location: {
            type: 'Point',
            coordinates: [47.272823, 6.066924],
            _id: '652faa8505224a44b4544c80',
          },
        },
        additionalInfo: {
          type: 'large',
          siretNumber: '12367845123698',
          billingAddress: {
            city: 'Besançon',
            country: 'France',
            postalCode: 25000,
            street: '7 Rue Des Granges, 25000 Besançon, France',
            building: '',
          },
          prms: [
            '652faa8605224a44b4544c85',
            '652faa8605224a44b4544c86',
            '652faa8605224a44b4544c87',
            '652faa8605224a44b4544c88',
          ],
          billingPerson: {
            firstName: 'AMTE',
            middleName: 'Billing',
            lastName: 'Person',
            email: 'tecake7894@ksyhtc.com',
            contactNumber: '+33381823566',
            _id: '652faa8505224a44b4544c81',
          },
          legalPerson: {
            position: 'Manager',
            firstName: 'AMTE',
            middleName: '',
            lastName: 'Group',
            email: 'tecake7894@ksyhtc.com',
            contactNumber: '+33381823547',
            _id: '652faa8505224a44b4544c82',
          },
          enedisConsentSigned: true,
          cppaSigned: true,
          agreementSigned: true,
          paymentMethodAdded: true,
          hasSpecialTariffs: false,
        },
        createdAt: '2023-10-18T09:51:01.902Z',
        updatedAt: '2024-09-24T14:34:44.203Z',
        __v: 0,
        auth0Id: 'auth0|652faa86f987771557c1de53',
      },
      community: '651beaf9dd5ec2d0948d1b10',
      __v: 0,
      createdAt: '2023-01-03T09:51:02.570Z',
      updatedAt: '2024-04-22T14:26:34.756Z',
    },
    indexation: 'yearly',
    cppa: '8079997632',
    periods: [
      {
        scheduleTypes: {
          RH: 0.1,
          HS_HH: 0.1,
          HS_LH: 0.08,
          LS_HH: 0.06,
          LS_LH: 0.05,
        },
        index: 1,
        startDate: '2023-11-01T00:00:00.000Z',
        endDate: '2025-02-01T00:00:00.000Z',
        _id: '66732760a4ac2a2751567ae0',
      },
      {
        scheduleTypes: {
          RH: 0.1034,
          HS_HH: 0.1034,
          HS_LH: 0.0827,
          LS_HH: 0.062,
          LS_LH: 0.0517,
        },
        index: 1.034,
        startDate: '2025-02-01T00:00:00.000Z',
        endDate: '2026-02-01T00:00:00.000Z',
        _id: '66732fe39ae637ea2402bf99',
      },
    ],
    startDate: '2023-11-01T00:00:00.000Z',
    endDate: '2027-01-01T00:00:00.000Z',
    createdAt: '2024-06-19T18:45:52.323Z',
    updatedAt: '2024-06-19T19:22:11.153Z',
    __v: 0,
  },
  actualPrices: {
    scheduleTypes: {
      RH: 0.1,
      HS_HH: 0.1,
      HS_LH: 0.08,
      LS_HH: 0.06,
      LS_LH: 0.05,
    },
    index: 1,
    startDate: '2023-11-01T00:00:00.000Z',
    endDate: '2025-02-01T00:00:00.000Z',
    _id: '66732760a4ac2a2751567ae0',
  },
};
