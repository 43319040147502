import React from 'react';
import type { DrawerProps } from 'antd';
import { Drawer } from 'antd';
import PrmForm from './form';
import { useTranslation } from 'react-i18next';

type IUploadDocumentDrawer = DrawerProps & {
  communityId: string;
  userId: string;
  onSuccess: any;
};
const AddPrmForm = ({
  open,
  onClose,
  communityId,
  userId,
  onSuccess,
}: IUploadDocumentDrawer) => {
  const { t } = useTranslation();
  return (
    <Drawer
      title={t('add_new_prm')}
      placement="right"
      closable={true}
      onClose={onClose}
      open={open}
      zIndex={10000000}
      width={400}
      styles={{
        body: {
          padding: '24px 8px',
        },
      }}
    >
      <PrmForm
        onSuccess={onSuccess}
        initialData={{ user: userId, community: communityId }}
      />
    </Drawer>
  );
};

export default AddPrmForm;
