import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  customAddDocumentBtnStyles,
  StyledBody,
  StyledButtonDiv,
  StyledContainer,
  StyledEmptyDataWrapper,
  StyledHeader,
  SubHeader,
} from './styles';
import { useParams } from 'react-router-dom';
import { useGetAllFilesByMemberIdQuery } from '../../services/documentManagement';
import DocumentsTable from './DocumentsTable';
import { BtnPrimary } from '../shared/Button';
import { USER_ROLES } from '../../constants/types';
import { getAppRole } from '../../utils';
import { UploadOutlined } from '@ant-design/icons';
import { StyledMemberContainer, StyledHeaderWrapper } from '../members/styles';
import UploadDocumentDrawer from './UploadDrawer';
import { placement } from './constants';

const Documents = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userRole = getAppRole();
  const isAdmin = useMemo(
    () => [USER_ROLES.ADMIN, USER_ROLES.PMO_ADMIN].includes(userRole),
    [userRole],
  );

  const {
    data: documents,
    isLoading: isLoadingDocuments,
    refetch,
  } = useGetAllFilesByMemberIdQuery({ prmId: id });

  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const handleRefresh = () => {
    onClose();
    refetch();
  };
  return (
    <StyledContainer>
      <StyledMemberContainer direction="vertical">
        <StyledHeaderWrapper size={50}>
          <StyledHeader>{t('documents')}</StyledHeader>
          {isAdmin && (
            <StyledButtonDiv>
              <BtnPrimary
                onClick={showDrawer}
                additionalStyles={customAddDocumentBtnStyles}
                icon={<UploadOutlined />}
              >
                {t('add_document')}
              </BtnPrimary>
              <UploadDocumentDrawer
                open={open}
                onClose={onClose}
                placement={placement}
                title={t('upload_document')}
                userRole={userRole}
                onUploadSuccess={handleRefresh}
              />
            </StyledButtonDiv>
          )}
        </StyledHeaderWrapper>
        <StyledBody>
          {!documents ? (
            <StyledEmptyDataWrapper>
              <SubHeader>{t('no_documents_yet').toUpperCase()}</SubHeader>
            </StyledEmptyDataWrapper>
          ) : (
            <DocumentsTable
              documents={documents}
              isLoadingDocuments={isLoadingDocuments}
            />
          )}
        </StyledBody>
      </StyledMemberContainer>
    </StyledContainer>
  );
};

export default Documents;
