import React from 'react';
import { useFormikContext } from 'formik';
import Container from 'components/shared/Container';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from 'components/documents/styles';
import { InputGroup } from 'components/shared/InputGroup';
import { Select, UploadProps } from 'antd';
import {
  StyledDurationWrapper,
  StyledDropDownWrapper,
  StyledSelect,
  StyledDateWrapper,
} from './styles';
import FormikInput from 'components/shared/Input/FormikInput';
import FileUpload from 'components/shared/FileUpload';
import { StyledInputGroup } from 'components/shared/InputGroup/styles';
import { MarginTop16px } from 'components/shared/Margin';
import {
  MAX_FILE_SIZE,
  DOCUMENTS_ALLOWED_FORMATS,
  getDocumentTypeByUserRole,
} from 'utils/validation/constants';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/global';

export const FileUploaderProps: UploadProps = {
  multiple: false,
  accept: DOCUMENTS_ALLOWED_FORMATS.join(','),
  maxCount: 1,
  beforeUpload: () => false,
  style: {
    borderColor: COLORS.DraggerInitialBorderColor,
    backgroundColor: COLORS.DraggerBgColor,
  },
};
const UploadDocumentForm: React.FC<{ index: number }> = ({ index }) => {
  const { t } = useTranslation();
  const formik: any = useFormikContext();

  const handleSelectChange = (value: unknown) => {
    formik.setFieldValue(`documents.${index}.type`, value);
  };

  const onUploaderChange = async (event: any) => {
    await formik.setFieldValue(`documents.${index}.file`, event.file);
  };

  FileUploaderProps.onChange = onUploaderChange;
  FileUploaderProps.onDrop = onUploaderChange;

  return (
    <Container offset={1}>
      <StyledWrapper>
        <StyledDropDownWrapper>
          <StyledInputGroup>
            <InputGroup
              label={t('document_type') as string}
              error={formik.errors?.documents?.[index]?.type}
            >
              <StyledSelect
                isError={!!formik.errors?.documents?.[index]?.type}
                placeholder={t('choose_type')}
                onChange={handleSelectChange}
              >
                {getDocumentTypeByUserRole(USER_ROLES.CONSUMER).map((type) => (
                  <Select.Option key={`document-type-${type}`} value={type}>
                    {t(`document_types.${type.toUpperCase()}`)}
                  </Select.Option>
                ))}
              </StyledSelect>
            </InputGroup>
          </StyledInputGroup>
        </StyledDropDownWrapper>
        <StyledInputGroup>
          <InputGroup
            label={t('duration').toString()}
            error={
              formik.errors?.documents?.[index]?.startDate ||
              formik.errors?.documents?.[index]?.endDate
            }
          >
            <StyledDurationWrapper>
              <StyledDateWrapper>
                <FormikInput
                  fieldName={`documents.${index}.startDate`}
                  type="date"
                />
              </StyledDateWrapper>
              <StyledDateWrapper>
                <FormikInput
                  fieldName={`documents.${index}.endDate`}
                  type="date"
                />
              </StyledDateWrapper>
            </StyledDurationWrapper>
          </InputGroup>
        </StyledInputGroup>
        <StyledInputGroup>
          <InputGroup
            label={t('signing_date').toString()}
            error={formik.errors?.documents?.[index]?.signingDate}
          >
            <StyledDateWrapper>
              <FormikInput
                fieldName={`documents.${index}.signingDate`}
                type="date"
              />
            </StyledDateWrapper>
          </InputGroup>
        </StyledInputGroup>
        <MarginTop16px />
        <StyledInputGroup>
          <FileUpload
            uploaderProps={FileUploaderProps}
            maxFileSize={MAX_FILE_SIZE}
            errors={formik.errors?.documents?.[index]?.file}
          />
        </StyledInputGroup>
        <MarginTop16px />
      </StyledWrapper>
    </Container>
  );
};

export default UploadDocumentForm;
