import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd';
import { SkeletonLine } from 'components/shared/Skeleton';
import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import { columns } from './constants';
import { FollowUpTable, StyledTableWrapper } from './styles';

type EnergyFollowUpDataType = {
  prmNumber: string;
  period: string;
  prmName: string;
  selfConsumedEnergy: number;
};

interface EnergyFollowUpProps {
  data: EnergyFollowUpDataType[];
  isLoading?: boolean;
}

interface FilterType {
  text: string;
  value: string;
}

const EnergyFollowUpTable: FC<EnergyFollowUpProps> = ({ data, isLoading }) => {
  const { id } = useParams();
  const { data: prmData } = useGetPrmByUserIdQuery({ userId: id });
  const { t } = useTranslation();
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );
  const [filterPrmNumber, setFilterPrmNumber] = useState<FilterType[]>([]);
  const [filterPrmGivenName, setFilterPrmGivenName] = useState<FilterType[]>(
    [],
  );

  useEffect(() => {
    const prmName: FilterType[] = [];
    const prmNumber: FilterType[] = [];
    if (Array.isArray(prmData)) {
      prmData?.forEach((item) => {
        prmName.push({ text: item.name, value: item.name });
        prmNumber.push({
          text: item.prmValue?.toString() || '',
          value: item.prmValue?.toString() || '',
        });
      });
      setFilterPrmGivenName(prmName);
      setFilterPrmNumber(prmNumber);
    }
  }, [prmData]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const hasEnoughDataToShowPagination = useMemo(
    () => !isLoading && data && data?.length > MAX_PAGINATION_PAGE_SIZE,
    [isLoading, data],
  );

  const modifiedColumns = useMemo(() => {
    const updatedColumns = [
      ...columns(t),
      {
        title: t('prm_number').toUpperCase(),
        dataIndex: 'prmNumber',
        filters: filterPrmNumber,
        onFilter: (value: any, record: any) =>
          record.prmNumber.includes(String(value)),
      },
      {
        title: t('prm_given_name').toUpperCase(),
        dataIndex: 'prmName',
        filters: filterPrmGivenName,
        onFilter: (value: any, record: any) =>
          record.prmName.includes(String(value)),
      },
      {
        title: `${t('self_consumed_energy').toUpperCase()}, ${t('kwh')}`,
        dataIndex: 'selfConsumedEnergy',
        render: (text: string) => parseInt(text),
        sorter: (report: any, nextReport: any) => {
          return (
            parseInt(report.selfConsumedEnergy) -
            parseInt(nextReport.selfConsumedEnergy)
          );
        },
      },
    ];
    if (isLoading) {
      return updatedColumns.map((column: any) => ({
        ...column,
        render: () => <SkeletonLine active height={22} width={120} block />,
      }));
    } else {
      return updatedColumns;
    }
  }, [isLoading, t, filterPrmGivenName, filterPrmNumber]);

  return (
    <StyledTableWrapper>
      <FollowUpTable
        columns={modifiedColumns}
        dataSource={data}
        pagination={
          hasEnoughDataToShowPagination
            ? {
                ...tablePagination,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: handlePaginationChange,
                showTotal: (total) => `${t('total')} ${total} ${t('items')}`,
              }
            : false
        }
      />
    </StyledTableWrapper>
  );
};

export default EnergyFollowUpTable;
