import dayjs from 'dayjs';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isBetween from 'dayjs/plugin/isBetween';
import { useFormikContext } from 'formik';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { validatePrmsValues } from 'components/members/createConsumer/ConsumerDetails/utils';
import { getPrmStatusLayout } from 'components/pricing/components/communityTab/ConsumerPrmItem';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { IPeriods, PRM_STATUSES } from 'constants/types';
import {
  useUpdateUserPrmMutation,
  useDeleteUserPrmMutation,
} from 'services/prmManagement';
import { useGetPrmPriceByCommunityIdQuery } from 'services/prmPricesManagement';
import { useOnboardingTutorialContext } from 'utils/onboardingTutorial/context';
import ModalMessage from 'components/shared/ModalMessage';
import { getDateRange } from 'utils/date/date-range';
import { getFormattedUserName, getAppRole } from 'utils';
import { ONBOARDING_FLOW } from 'utils/onboardingTutorial/steps';
import { USER_ROLES } from 'constants/global';
import { initialValues } from '../../constants';
import CommunityPrices from '../CommunityPrices';
import ElectricityGridPrices from '../ElectricityGridPrices';
import { PrmData } from '../PrmBody/types';
import { SkeletonLine } from 'components/shared/Skeleton';
import MOCK_ONBOARDING_DATA from './mockOnboardingData';
import {
  CalendarIcon,
  EditIcon,
  FileIcon,
  StyledBtn,
  StyledExpiredDuration,
  StyledGivenNameWrapper,
  StyledPrmHeader,
  StyledPrmInfoItem,
  StyledPrmInfoText,
  StyledPrmRow,
  StyledPrmTitle,
  StyledPrmWrapper,
  StyledSuccessBtn,
  DeleteIcon,
  SkeletonPrmNameWrapper,
} from './styles';

dayjs.extend(isBetween);

interface PrmNumberProps {
  index: number;
  communityId: string;
  refetchPrm: () => void;
  isLoadingPrm?: boolean;
}

const PrmNumber: FC<PrmNumberProps> = ({
  index,
  communityId,
  refetchPrm,
  isLoadingPrm,
}) => {
  const { t } = useTranslation();
  const [updateUserPrm, { isSuccess }] = useUpdateUserPrmMutation();
  const [deleteUserPrm] = useDeleteUserPrmMutation();
  const { values, setFieldValue } = useFormikContext<typeof initialValues>();
  const [prmData, setPrmData] = useState<PrmData | undefined>(undefined);
  const [isEditGivenName, setIsEditGivenName] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const {
    isActive: isOnboardingActive,
    stepIndex: onboardingStepIndex,
    setRun: setRunOnboarding,
    run: onboardingRun,
  } = useOnboardingTutorialContext();
  const showMockedOnboardingData = useMemo(
    () =>
      onboardingRun &&
      (onboardingStepIndex === ONBOARDING_FLOW.STEP_10 ||
        onboardingStepIndex === ONBOARDING_FLOW.STEP_11),
    [onboardingRun, onboardingStepIndex],
  );

  const isLoadingPrmUpdated = useMemo(
    () =>
      showMockedOnboardingData ? MOCK_ONBOARDING_DATA.isLoading : isLoadingPrm,
    [showMockedOnboardingData, isLoadingPrm],
  );

  const { data: pricesData, isLoading: isLoadingPrmPricesData } =
    useGetPrmPriceByCommunityIdQuery({ communityId }, { skip: !communityId });

  const isLoadingPrmPricesDataUpdated = useMemo(
    () =>
      showMockedOnboardingData
        ? MOCK_ONBOARDING_DATA.isLoading
        : isLoadingPrmPricesData,
    [showMockedOnboardingData, isLoadingPrmPricesData],
  );

  const prm: any = useMemo(() => {
    if (showMockedOnboardingData) {
      return MOCK_ONBOARDING_DATA.prm;
    } else {
      return values.prms[index];
    }
  }, [values, index, showMockedOnboardingData]);

  const prmPrices: any = useMemo(() => {
    if (showMockedOnboardingData) {
      return MOCK_ONBOARDING_DATA.prmPrices;
    } else {
      return pricesData?.find((item) => item.prm._id === prm.id);
    }
  }, [prm, pricesData, showMockedOnboardingData]);

  const actualPrices = useMemo(() => {
    if (showMockedOnboardingData) {
      return MOCK_ONBOARDING_DATA.actualPrices;
    }
    if (prmPrices && prmPrices.periods && prmPrices.periods.length) {
      const currentDate = dayjs();
      return prmPrices.periods.find(({ startDate, endDate }: IPeriods) =>
        currentDate.isBetween(startDate, endDate),
      );
    }
    return {};
  }, [prmPrices, showMockedOnboardingData]);
  const userRole = getAppRole();
  const isAdminOrPmo =
    userRole === USER_ROLES.OYO_ADMIN || userRole === USER_ROLES.PMO;
  const canBeDeleted = useMemo(
    () =>
      isAdminOrPmo &&
      (prm.status === PRM_STATUSES.ACTIVE ||
        prm.status === PRM_STATUSES.PENDING ||
        prm.status === PRM_STATUSES.CONFLICT),
    [prm, isAdminOrPmo],
  );
  const prmUserName = useMemo(() => {
    const priceData = pricesData?.find((price) => price.prm._id === prm.id);
    if (priceData) {
      const {
        prm: { user },
      } = priceData;
      return getFormattedUserName(user as any);
    }
    return '';
  }, [pricesData, prm]);

  const onToggleEditName = useCallback(
    () => setIsEditGivenName((val: boolean) => !val),
    [],
  );

  const onCancelEditName = useCallback(() => {
    setFieldValue(`prms.${index}`, prmData);
    setIsEditGivenName(false);
  }, [prmData, index, setFieldValue]);

  const onConfirmEditName = async () => {
    const prms: any = [...values.prms];
    const validatedPrms = validatePrmsValues(prms);

    await updateUserPrm(validatedPrms[index]);
    await refetchPrm();
    setIsEditGivenName(false);
  };

  const onDeleteUserPrm = useCallback(async () => {
    setIsDeleteModalOpen(false);
    await deleteUserPrm({ prmId: prm.id });
    refetchPrm();
  }, [prm, deleteUserPrm, refetchPrm]);

  useEffect(() => {
    if (!values.prms[index].id) return;

    if (!prmData?.id) {
      // set initial prm values when got them for the first time
      setPrmData(values.prms[index]);
    }
  }, [values, index, prmData]);

  useEffect(() => {
    if (isSuccess) {
      setPrmData(values.prms[index]);
    }
  }, [isSuccess, values, index]);

  useEffect(() => setIsMounted(true), []);

  useEffect(() => {
    if (
      isOnboardingActive &&
      index === 0 &&
      isMounted &&
      (onboardingStepIndex === ONBOARDING_FLOW.STEP_10 ||
        onboardingStepIndex === ONBOARDING_FLOW.STEP_11)
    ) {
      setRunOnboarding(true);
    }
  }, [isOnboardingActive, index, isMounted, onboardingStepIndex]);

  return (
    <StyledPrmWrapper
      className={index === 0 ? 'onboarding-step-11 onboarding-step-12' : ''}
      pointerEvents={isEditGivenName ? 'visible' : 'visible'}
    >
      {isDeleteModalOpen && (
        <ModalMessage
          messageType="warining"
          title={t('delete_consumer_prm_title', {
            prmValue: prm?.prmValue,
            userName: prmUserName,
          })}
          subTitle={t('delete_consumer_prm_text')}
          btnText={t('ok')}
          handleBtn={onDeleteUserPrm}
          onCancelClick={() => setIsDeleteModalOpen(false)}
        />
      )}
      <StyledPrmHeader>
        <StyledPrmInfoItem>
          {isLoadingPrmUpdated && (
            <SkeletonLine width={260} height={24} active />
          )}
          <StyledPrmTitle>{`${t('prm').toUpperCase()} ${
            prm.prmValue
          }`}</StyledPrmTitle>
          {getPrmStatusLayout(
            prm.status,
            t,
            prm.deactivatedAt && dayjs(prm.deactivatedAt).format('DD.MM.YYYY'),
          )}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem>
          <ThunderboltIcon />
          <StyledPrmInfoText>{t('power')}</StyledPrmInfoText>
          {isLoadingPrmUpdated ? (
            <SkeletonLine active width={98} height={22} />
          ) : (
            t('power_unit', { power: prm.power })
          )}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem>
          <FileIcon />
          <StyledPrmInfoText>{t('cppa')}</StyledPrmInfoText>
          {isLoadingPrmPricesDataUpdated ? (
            <SkeletonLine active width={98} height={22} />
          ) : (
            prmPrices?.cppa
          )}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem>
          <CalendarIcon />
          <StyledPrmInfoText>{t('duration')}</StyledPrmInfoText>
          {isLoadingPrmPricesDataUpdated ? (
            <SkeletonLine active width={120} height={22} />
          ) : (
            <StyledExpiredDuration>
              {getDateRange({
                startDate: '2022-01-01T13:16:55.550Z',
                endDate: '2024-01-01T13:16:55.550Z',
              })}
            </StyledExpiredDuration>
          )}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem>
          {canBeDeleted && (
            <DeleteIcon onClick={() => setIsDeleteModalOpen(true)} />
          )}
        </StyledPrmInfoItem>
      </StyledPrmHeader>
      <StyledPrmRow>
        <StyledGivenNameWrapper>
          <InputGroup
            error={undefined}
            label={`${t('prm').toUpperCase()} ${t('given_name')}:`}
            customStyles={{ flexDirection: 'row', alignItems: 'center' }}
          >
            {isLoadingPrmUpdated ? (
              <SkeletonPrmNameWrapper>
                <SkeletonLine width={260} active />
              </SkeletonPrmNameWrapper>
            ) : (
              <FormikInput
                fieldName={`prms.${index}.name`}
                placeholder="Ex. Marc Department"
                type="text"
                suffix={<EditIcon onClick={onToggleEditName} />}
              />
            )}
          </InputGroup>
          {isEditGivenName && [
            <StyledSuccessBtn
              icon={<CheckOutlined />}
              onClick={onConfirmEditName}
            />,
            <StyledBtn icon={<CloseOutlined />} onClick={onCancelEditName} />,
          ]}
        </StyledGivenNameWrapper>
      </StyledPrmRow>
      <StyledPrmRow>
        <ElectricityGridPrices prmData={prmData} />
      </StyledPrmRow>
      <StyledPrmRow>
        <CommunityPrices
          period={actualPrices}
          indexation={prmPrices?.indexation?.toString() || '-'}
          isLoading={isLoadingPrmPricesDataUpdated}
        />
      </StyledPrmRow>
    </StyledPrmWrapper>
  );
};

export default PrmNumber;
