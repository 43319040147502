import { styled } from 'styled-components';

export const StyledUploadHint = styled.span`
  margin: 0;
  font-size: 12px;
  display: block;
  color: #667085;
`;

export const StyledUploadIcon = styled.span`
  margin: 0;
  display: block;
`;
export const StyledUploadText = styled.span`
  margin: 0;
  font-size: 12px;
  display: block;
  font-weight: bolder;
`;

export const StyledUploadFileTypeAndMaxSize = styled.p`
  margin-top: 3px;
  justify-content: space-between;
  display: flex;
  color: #667085;
  font-size: 12px;
  font-weight: normal;
`;
