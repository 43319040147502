import dayjs from 'dayjs';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';

import { Flex, Select } from 'antd';
import engLocale from 'antd/es/date-picker/locale/en_US';
import frLocale from 'antd/es/date-picker/locale/fr_FR';

import { BtnDefault, BtnSmallPrimary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import Modal from 'components/shared/Modal';
import { SUPPORTED_LANGUAGES } from 'constants/global';
import { Community } from 'constants/types';
import { useGetCommunitiesQuery } from 'services/communityManagement';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';
import {
  useAddPrmPriceMutation,
  useDeletePrmPriceMutation,
  useEditPrmPriceMutation,
} from 'services/prmPricesManagement';
import { IDBPrm, INDEXATION } from 'services/types';

import { IFormInitialData } from '../index';
import { prmPriceSchema } from './prm-price.schema';
import {
  ErrorIcon,
  ErrorText,
  StyledBottomFormBlock,
  StyledBtnContainer,
  StyledDateEndTitle,
  StyledDatePicker,
  StyledDateTitle,
  StyledSelect,
  StyledTopFormBlock,
  StyledTopFormCol,
  StyledTopFormRow,
  customInputGroupStyles,
  customInputLabelStyles,
} from './styles';

enum PriceType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

type PricesFormProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  initialData: IFormInitialData | null;
};

const PricesForm = ({
  isModalOpen,
  closeModal,
  initialData,
}: PricesFormProps) => {
  const { t, i18n } = useTranslation();
  const [canValidateOnChange, setCanValidateOnChange] = useState(false);
  const [selectedConsumerId, setSelectedConsumerId] = useState(
    initialData?.consumerId,
  );
  const { data: communities = [], isSuccess: isSuccessCommunities } =
    useGetCommunitiesQuery();

  const { data: prmData, isLoading: isLoadingPrm } = useGetPrmByUserIdQuery(
    {
      userId: selectedConsumerId,
    },
    { skip: !selectedConsumerId },
  );

  const [
    addPrmPrice,
    { isLoading: isLoadinAddPrmPrice, isSuccess: isPrmPriceAdded },
  ] = useAddPrmPriceMutation();
  const [
    editPrmPrice,
    { isLoading: isLoadinEditPrmPrice, isSuccess: isPrmPriceEdited },
  ] = useEditPrmPriceMutation();
  const [
    deletePrmPrice,
    { isLoading: isDeletePrmPriceLoading, isSuccess: isDeletePrmPriceSuccess },
  ] = useDeletePrmPriceMutation();

  const communitiesOptions = useMemo(() => {
    if (isSuccessCommunities && communities.length) {
      return communities.map(({ id, communityName }: Community) => ({
        label: communityName,
        value: id,
      }));
    }
    return [];
  }, [communities, isSuccessCommunities]);

  const priceTypeOptions = useMemo(
    () => [
      { label: t('static'), value: PriceType.STATIC },
      { label: t('dynamic'), value: PriceType.DYNAMIC },
    ],
    [t],
  );

  const indexationOptions = useMemo(
    () => [
      { label: t('no_indexation'), value: INDEXATION.NO },
      { label: t('yearly'), value: INDEXATION.YEARLY },
    ],
    [t],
  );

  const isEditMode = useMemo(() => !!initialData?.prmPriceId, [initialData]);

  const locale = useMemo(() => {
    if (i18n.language === SUPPORTED_LANGUAGES.English) return engLocale;
    return frLocale;
  }, [i18n.language]);

  const defaultPriceType = useMemo(() => {
    if (initialData) {
      const { HS_HH, HS_LH, LS_HH, LS_LH, RH } = initialData;
      // if all period prices are equal then type === static
      if (
        RH &&
        RH === HS_LH &&
        RH === HS_LH &&
        RH === LS_HH &&
        RH === LS_LH &&
        RH === HS_HH
      ) {
        return PriceType.STATIC;
      }
      return PriceType.DYNAMIC;
    }
    return PriceType.DYNAMIC;
  }, [initialData]);

  const onAddPrmPrice = useCallback(
    (values: any) => {
      const {
        communityId,
        prmId,
        cppa,
        indexation,
        endDate,
        startDate,
        RH,
        HS_HH,
        HS_LH,
        LS_HH,
        LS_LH,
      } = values;
      addPrmPrice({
        communityId,
        prmId,
        addPrmPrice: {
          indexation,
          cppa: cppa.toString(),
          startDate,
          endDate,
          scheduleTypes: {
            RH: parseFloat(RH),
            HS_HH: parseFloat(HS_HH),
            HS_LH: parseFloat(HS_LH),
            LS_HH: parseFloat(LS_HH),
            LS_LH: parseFloat(LS_LH),
          },
        },
      });
    },
    [addPrmPrice],
  );

  const onEditPrmPrice = useCallback(
    (values: any) => {
      const {
        prmId,
        cppa,
        indexation,
        endDate,
        startDate,
        RH,
        HS_HH,
        HS_LH,
        LS_HH,
        LS_LH,
        periodId,
      } = values;
      editPrmPrice({
        periodId,
        prmId,
        updatePrmPrice: {
          indexation,
          cppa: cppa.toString(),
          startDate,
          endDate,
          scheduleTypes: {
            RH: parseFloat(RH),
            HS_HH: parseFloat(HS_HH),
            HS_LH: parseFloat(HS_LH),
            LS_HH: parseFloat(LS_HH),
            LS_LH: parseFloat(LS_LH),
          },
        },
      });
    },
    [editPrmPrice],
  );

  const onSubmit = useMemo(
    () => (isEditMode ? onEditPrmPrice : onAddPrmPrice),
    [isEditMode, onEditPrmPrice, onAddPrmPrice],
  );
  const onDelete = useCallback(() => {
    if (initialData && initialData.cppa && initialData.prmId) {
      const { prmId, cppa } = initialData;
      deletePrmPrice({ prmId, cppa });
    }
  }, [initialData, deletePrmPrice]);

  useEffect(() => {
    if (isDeletePrmPriceSuccess || isPrmPriceAdded || isPrmPriceEdited) {
      closeModal();
    }
  }, [isDeletePrmPriceSuccess, isPrmPriceAdded, isPrmPriceEdited, closeModal]);

  return (
    <Modal
      isVisible={isModalOpen}
      title={t(isEditMode ? 'edit_upcoming_prices' : 'add_new_prices')}
      onClose={closeModal}
    >
      <Formik
        validateOnChange={canValidateOnChange}
        validateOnBlur={canValidateOnChange}
        initialValues={
          { indexation: INDEXATION.NO, ...(initialData || {}) } as any
        }
        validationSchema={prmPriceSchema(t)}
        onSubmit={onSubmit}
      >
        {({
          isValid,
          values,
          errors,
          validateForm,
          setFieldValue,
          handleSubmit,
        }) => {
          const selectedCommunity = communities.find(
            (community: Community) => community.id === values.communityId,
          );
          const communityConsumersMap = new Map(
            selectedCommunity?.consumers.map((consumer) => [
              consumer.user?._id,
              consumer,
            ]),
          );
          const consumersOptions = Array.from(
            communityConsumersMap,
            (consumer: any) => {
              return {
                label: `${consumer[1].user?.firstName} ${consumer[1].user?.lastName}`,
                value: consumer[1]?.user?._id,
              };
            },
          );
          const prmOptions = prmData?.map((prm: IDBPrm) => ({
            label: prm.prmValue,
            value: prm._id,
          }));

          const onCommunityChanged = (value: string) => {
            setFieldValue('communityId', value);
            setFieldValue('consumerId', undefined);
          };

          const onConsumerChanged = (value: string) => {
            setFieldValue('consumerId', value);
            setFieldValue('prmId', null);
            setSelectedConsumerId(value);
          };

          const onPriceTypeChanged = (value: PriceType) => {
            if (value === PriceType.STATIC && values.HS_HH) {
              setFieldValue('RH', values.HS_HH);
              setFieldValue('LS_LH', values.HS_HH);
              setFieldValue('LS_HH', values.HS_HH);
              setFieldValue('HS_LH', values.HS_HH);
            }
            setFieldValue('priceType', value);
          };
          const isStaticPriceType = values.priceType
            ? values.priceType === PriceType.STATIC
            : defaultPriceType === PriceType.STATIC;

          const onIndexationChanged = (value: INDEXATION) =>
            setFieldValue('indexation', value);

          const onStartDateChanged = (_: unknown, date: string | string[]) => {
            setFieldValue('startDate', date);
          };

          const onEndDateChanged = (_: unknown, date: string | string[]) => {
            setFieldValue('endDate', date);
          };

          const onSchedulePriceChanged = (numValue: any) => {
            if (isStaticPriceType) {
              setFieldValue('RH', numValue);
              setFieldValue('LS_LH', numValue);
              setFieldValue('LS_HH', numValue);
              setFieldValue('HS_LH', numValue);
            }
            setFieldValue('HS_HH', numValue);
          };

          const handleOnSave = () => {
            validateForm();
            setCanValidateOnChange(true);
            if (isValid) {
              handleSubmit();
            }
          };

          return (
            <Form>
              <StyledTopFormBlock>
                <StyledTopFormCol>
                  <StyledTopFormRow>
                    <InputGroup
                      customStyles={customInputGroupStyles as any}
                      customLabelStyles={customInputLabelStyles}
                      label={t('community').toString()}
                      isShowError={false}
                    >
                      <StyledSelect
                        onChange={onCommunityChanged as any}
                        options={communitiesOptions}
                        getPopupContainer={(node) => node.parentNode}
                        value={values.communityId}
                        style={{ width: '264px' }}
                        status={errors.communityId ? 'error' : undefined}
                        disabled={isEditMode}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={customInputGroupStyles as any}
                      customLabelStyles={customInputLabelStyles}
                      label={t('prm').toString().toUpperCase()}
                    >
                      <Select
                        defaultValue={
                          prmOptions && prmOptions.length === 1
                            ? prmOptions[0].value
                            : null
                        }
                        onChange={(value: string) =>
                          setFieldValue('prmId', value)
                        }
                        options={prmOptions || []}
                        getPopupContainer={(node) => node.parentNode}
                        value={values.prmId}
                        style={{ width: '264px' }}
                        status={errors.prmId ? 'error' : undefined}
                        loading={isLoadingPrm}
                        disabled={isEditMode}
                        placeholder={t('select_prm')}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <StyledDateTitle>{t('action_period')}</StyledDateTitle>
                    <StyledDatePicker
                      getPopupContainer={(node: HTMLElement) => node.parentNode}
                      locale={locale}
                      defaultValue={
                        initialData?.startDate
                          ? dayjs(initialData?.startDate, 'YYYY-MM-DD')
                          : undefined
                      }
                      status={errors.startDate ? 'error' : undefined}
                      onChange={onStartDateChanged}
                      popupStyle={{ position: 'absolute' }}
                    />
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={{
                        ...(customInputGroupStyles as any),
                        justifyContent: 'flex-start',
                      }}
                      customLabelStyles={customInputLabelStyles}
                      label={t('price_type').toString()}
                    >
                      <Select
                        defaultValue={defaultPriceType}
                        onChange={onPriceTypeChanged}
                        options={priceTypeOptions}
                        getPopupContainer={(node) => node.parentNode}
                        style={{ width: '140px' }}
                        status={errors.priceType ? 'error' : undefined}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                </StyledTopFormCol>
                <StyledTopFormCol>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={customInputGroupStyles as any}
                      customLabelStyles={customInputLabelStyles}
                      label={t('consumer').toString()}
                    >
                      <StyledSelect
                        onChange={onConsumerChanged as any}
                        options={consumersOptions}
                        getPopupContainer={(node) => node.parentNode}
                        value={values.consumerId}
                        style={{ width: '264px' }}
                        placeholder={t('select_consumer')}
                        status={errors.consumerId ? 'error' : undefined}
                        disabled={isEditMode}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={{
                        ...(customInputGroupStyles as any),
                        justifyContent: 'flex-end',
                      }}
                      customLabelStyles={{
                        ...customInputLabelStyles,
                        textTransform: 'uppercase',
                      }}
                      label={t('cppa').toString()}
                    >
                      <FormikInput
                        additionalStyles={{ height: '32px', width: '264px' }}
                        type="number"
                        fieldName={t('cppa')}
                        placeholder={'12345678'}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <StyledDateEndTitle>_</StyledDateEndTitle>
                    <StyledDatePicker
                      getPopupContainer={(node: HTMLElement) => node.parentNode}
                      locale={locale}
                      defaultValue={
                        initialData?.endDate
                          ? dayjs(initialData?.endDate, 'YYYY-MM-DD')
                          : undefined
                      }
                      status={errors.endDate ? 'error' : undefined}
                      onChange={onEndDateChanged}
                      popupStyle={{ position: 'absolute' }}
                    />
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={customInputGroupStyles as any}
                      customLabelStyles={customInputLabelStyles}
                      label={t('indexation').toString()}
                    >
                      <Select
                        onChange={onIndexationChanged}
                        options={indexationOptions}
                        getPopupContainer={(node) => node.parentNode}
                        value={values.indexation}
                        style={{ width: '264px' }}
                        status={errors.indexation ? 'error' : undefined}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                </StyledTopFormCol>
              </StyledTopFormBlock>
              <StyledBottomFormBlock>
                <InputGroup>
                  <div>
                    <div>
                      <strong>{t('hshp')}</strong>,{t('eur_kwh')}
                    </div>
                    <FormikInput
                      onChange={onSchedulePriceChanged}
                      type="number"
                      fieldName="HS_HH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <div>
                      <strong>{t('hshc')}</strong>,{t('eur_kwh')}
                    </div>
                    <FormikInput
                      disabled={isStaticPriceType}
                      type="number"
                      fieldName="HS_LH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <div>
                      <strong>{t('bshp')}</strong>,{t('eur_kwh')}
                    </div>
                    <FormikInput
                      disabled={isStaticPriceType}
                      type="number"
                      fieldName="LS_HH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <div>
                      <strong>{t('bshc')}</strong>,{t('eur_kwh')}
                    </div>
                    <FormikInput
                      disabled={isStaticPriceType}
                      type="number"
                      fieldName="LS_LH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <div>
                      <strong>{t('hdp')}</strong>,{t('eur_kwh')}
                    </div>
                    <FormikInput
                      disabled={isStaticPriceType}
                      type="number"
                      fieldName="RH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
              </StyledBottomFormBlock>
              {!isValid && (
                <Flex>
                  <ErrorIcon />
                  <ErrorText>
                    {t('please_fill_all_fields_to_proceed')}
                  </ErrorText>
                </Flex>
              )}
              <StyledBtnContainer>
                <BtnDefault onClick={closeModal}>{t('cancel')}</BtnDefault>
                {isEditMode && (
                  <BtnDefault
                    danger
                    onClick={onDelete}
                    disabled={isDeletePrmPriceLoading}
                  >
                    {t('delete')}
                  </BtnDefault>
                )}
                <BtnSmallPrimary
                  isDisabled={
                    !isValid || isLoadinAddPrmPrice || isLoadinEditPrmPrice
                  }
                  onClick={handleOnSave}
                >
                  {t(isEditMode ? 'save' : 'save_new_prices')}
                </BtnSmallPrimary>
              </StyledBtnContainer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PricesForm;
