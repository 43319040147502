import type { AppDispatch, RootState } from 'store';
import { getAuthUser } from 'utils';

import { useCallback, useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { SUPPORTED_LANGUAGES } from 'constants/global';
import {
  useGetUserMetadataQuery,
  useSetUserMetadataMutation,
} from 'services/userManagement';

import i18n from 'utils/i18n';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useAppLanguage = () => {
  const [lang, setLang] = useState(SUPPORTED_LANGUAGES.Français);
  const authUser = getAuthUser();
  const [userEmail, setUserEmail] = useState(authUser?.email);
  const [setUserMetadata] = useSetUserMetadataMutation();
  const {
    data: userMetadata,
    refetch: refetchUserMetadata,
    isSuccess: isSuccessLoadingMetadata,
  } = useGetUserMetadataQuery({}, { skip: !userEmail });

  const changeLanguage = useCallback(
    (value: string) => {
      setUserMetadata({ lang: value });
      i18n.changeLanguage(value);
      setLang(value);
    },
    [i18n, setUserMetadata],
  );

  const handleSignOut = useCallback(() => {
    setUserEmail(null);
  }, []);

  const handleSignIn = useCallback((email: string) => {
    setUserEmail(email);
  }, []);

  useEffect(() => {
    // initialize lang from server
    if (isSuccessLoadingMetadata) {
      setLang(userMetadata.lang || SUPPORTED_LANGUAGES.Français);
      i18n.changeLanguage(userMetadata.lang || SUPPORTED_LANGUAGES.Français);
    }
  }, [isSuccessLoadingMetadata, userMetadata]);

  useEffect(() => {
    // update lang after user changed
    if (!userEmail) {
      setLang(SUPPORTED_LANGUAGES.Français);
      i18n.changeLanguage(SUPPORTED_LANGUAGES.Français);
    }
    if (userEmail) {
      refetchUserMetadata();
    }
  }, [userEmail, refetchUserMetadata]);

  return {
    lang,
    changeLanguage,
    onLogout: handleSignOut,
    onLogin: handleSignIn,
  };
};
