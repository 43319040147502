import { getFormattedUserName } from 'utils';
import dayjs from 'dayjs';
import { FC, useCallback, useState, useMemo } from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { HomeIcon } from 'components/members/Dashboard/components/SummaryDetails/components/Title/styles';
import { EXPIRES_MONTH_DIFF } from '../ConsumerPrmItem';
import { capitalizeFirstLetter } from 'utils/forms';
import { SkeletonLine } from 'components/shared/Skeleton';
import ConsumerPrmItem from '../ConsumerPrmItem';
import { IFormInitialData } from '../index';
import {
  ArrowButton,
  ArrowDown,
  ArrowText,
  ArrowUp,
  StyledConsumerInfoItem,
  StyledConsumerName,
  StyledConsumerSubtitle,
  StyledConsumerWrapper,
  StyledDivider,
  StyledDurationText,
  FileIcon,
} from './styles';

interface ConsumerItemProps {
  consumer: any;
  openFormModal: (data: IFormInitialData) => void;
  isLoading?: boolean;
}

const ConsumerItem: FC<ConsumerItemProps> = ({
  consumer,
  openFormModal,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHistoricalPricesShown, setIsHistoricalPricesShown] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const currentDate = dayjs();

  const toggleShowHistoricalPrices = useCallback(
    () => setIsHistoricalPricesShown((val) => !val),
    [],
  );

  const hasHistoricalPrices = useMemo(() => {
    return consumer.prms?.some((prm: any) =>
      prm.prmPrices?.some((prmPrice: any) =>
        prmPrice.periods.some((period: any) => {
          const endDate = dayjs(period.endDate);
          return currentDate.isAfter(endDate);
        }),
      ),
    );
  }, [consumer, currentDate]);

  const hasSoonExpiredContract = useMemo(() => {
    return consumer.prms?.some((prm: any) =>
      prm.prmPrices?.some((prmPrice: any) => {
        const endDate = dayjs(prmPrice.endDate);
        const startDate = dayjs(prmPrice.startDate);
        return (
          currentDate.isBetween(startDate, endDate) &&
          dayjs(endDate).diff(currentDate, 'month') < EXPIRES_MONTH_DIFF
        );
      }),
    );
  }, [consumer, currentDate]);

  return (
    <Flex vertical gap={20}>
      <StyledConsumerWrapper>
        <Flex align="center" justify="space-between">
          <StyledConsumerInfoItem width="30%">
            <StyledConsumerName>
              {isLoading ? (
                <SkeletonLine active width={126} height={22} />
              ) : (
                getFormattedUserName(consumer)
              )}
            </StyledConsumerName>
          </StyledConsumerInfoItem>
          <StyledConsumerInfoItem width="25%">
            <Flex gap="small">
              <HomeIcon />
              <StyledConsumerSubtitle>
                {t('type').toUpperCase()}
              </StyledConsumerSubtitle>
              {isLoading ? (
                <SkeletonLine active width={126} height={22} />
              ) : (
                `${capitalizeFirstLetter(
                  t(consumer.additionalInfo.type!),
                )} ${capitalizeFirstLetter(t('company'))}`
              )}
            </Flex>
          </StyledConsumerInfoItem>
          <StyledConsumerInfoItem width="15%">
            <Flex gap="small">
              <ThunderboltIcon />
              <StyledConsumerSubtitle>
                {t('prm').toUpperCase()}
              </StyledConsumerSubtitle>
              {isLoading ? (
                <SkeletonLine active width={26} height={22} />
              ) : (
                consumer.prms.length
              )}
            </Flex>
          </StyledConsumerInfoItem>
          <StyledConsumerInfoItem width="20%">
            {hasSoonExpiredContract && [
              <FileIcon />,
              <StyledDurationText>
                {t('contract_expires_soon')}
              </StyledDurationText>,
            ]}
          </StyledConsumerInfoItem>
          <StyledConsumerInfoItem width="10%">
            {!isLoading && (
              <ArrowButton
                {...getToggleProps({
                  onClick: () => setIsExpanded((prevExpanded) => !prevExpanded),
                })}
              >
                {isExpanded ? <ArrowUp /> : <ArrowDown />}
              </ArrowButton>
            )}
          </StyledConsumerInfoItem>
        </Flex>
        <StyledDivider isExpanded={isExpanded}>
          <section {...getCollapseProps()}>
            {consumer.prms?.map((prm: any) => (
              <ConsumerPrmItem
                isHistoricalPricesShown={isHistoricalPricesShown}
                prm={prm}
                openFormModal={openFormModal}
              />
            ))}
          </section>
        </StyledDivider>
        {isExpanded && hasHistoricalPrices && (
          <ArrowButton
            onClick={toggleShowHistoricalPrices}
            style={{ textAlign: 'left', marginLeft: '48px' }}
          >
            {isHistoricalPricesShown ? <ArrowUp /> : <ArrowDown />}
            {isHistoricalPricesShown ? (
              <ArrowText>{t('hide_historical_prices')}</ArrowText>
            ) : (
              <ArrowText>{t('show_historical_prices')}</ArrowText>
            )}
          </ArrowButton>
        )}
      </StyledConsumerWrapper>
    </Flex>
  );
};

export default ConsumerItem;
