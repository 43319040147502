import { useEffect, useCallback, useMemo } from 'react';
import Joyride, {
  CallBackProps,
  ACTIONS,
  EVENTS,
  LIFECYCLE,
  STATUS,
} from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { styles } from './styles';
import { steps, STEP_COUNT, ONBOARDING_FLOW } from './steps';
import { useOnboardingTutorialContext } from './context';
import {
  useGetUserMetadataQuery,
  useSetUserMetadataMutation,
} from 'services/userManagement';
import { useGetPaymentsQuery } from 'services/paymentManagment';
import { getAppRole } from 'utils';
import {
  getSkipOnboardingLocalStorage,
  setSkipOnboardingLocalStorage,
} from 'utils/local-storage';
import { USER_ROLES } from 'constants/types';
import AppRoutes from 'constants/routes';

const isStepToPause = (stepIndex: ONBOARDING_FLOW) => {
  return (
    stepIndex === ONBOARDING_FLOW.STEP_5 ||
    stepIndex === ONBOARDING_FLOW.STEP_7 ||
    stepIndex === ONBOARDING_FLOW.STEP_8 ||
    stepIndex === ONBOARDING_FLOW.STEP_9 ||
    stepIndex === ONBOARDING_FLOW.STEP_11
  );
};

const isStepToRedirect = (stepIndex: ONBOARDING_FLOW) => {
  return (
    stepIndex === ONBOARDING_FLOW.STEP_6 ||
    stepIndex === ONBOARDING_FLOW.STEP_7 ||
    stepIndex === ONBOARDING_FLOW.STEP_8 ||
    stepIndex === ONBOARDING_FLOW.STEP_9 ||
    stepIndex === ONBOARDING_FLOW.STEP_10 ||
    stepIndex === ONBOARDING_FLOW.STEP_11
  );
};

export const useOnboardingTutorial = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    setIsActive,
    setShowStartModal,
    setStepIndex,
    run,
    stepIndex,
    setRun,
  } = useOnboardingTutorialContext();
  const userRole = getAppRole();
  const [setUserMetadata] = useSetUserMetadataMutation();
  const { data: userMetadata, isSuccess: isSuccessLoadingMetadata } =
    useGetUserMetadataQuery({}, { skip: userRole !== USER_ROLES.CONSUMER });
  const { data: paymentsData, isSuccess: isSuccessPayments } =
    useGetPaymentsQuery({}, { skip: userRole !== USER_ROLES.CONSUMER });
  const isSkippedBySession = !!getSkipOnboardingLocalStorage();

  const callback = useCallback(
    async (callbackData: CallBackProps) => {
      const { action, index, type, lifecycle, step, status } = callbackData;

      // finish onboarding by saving last step to userMetadata
      if (status === STATUS.FINISHED) {
        await setUserMetadata({ onboardingStep: index });
        setIsActive(false);
        setRun(false);
        navigate(AppRoutes.Dashboard);
      }
      // pause onboardig with skip button
      if (action === ACTIONS.SKIP) {
        await setUserMetadata({ onboardingStep: index });
        setSkipOnboardingLocalStorage();
        setIsActive(false);
        setRun(false);
      }
      // handle save step number
      if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
        setStepIndex(index + 1);
      }
      // pause onboarding before redirect to next step url
      if (
        isStepToPause(index) &&
        action === ACTIONS.NEXT &&
        type === EVENTS.STEP_AFTER
      ) {
        setRun(false);
      }
      // navigate to step url before tooltip showed
      if (isStepToRedirect(index) && lifecycle === LIFECYCLE.INIT) {
        if (pathname !== step.data.currentUrl) {
          navigate(step.data.currentUrl);
        }
      }
    },
    [navigate, pathname, setUserMetadata, setIsActive, setRun, setStepIndex],
  );

  useEffect(() => {
    if (
      isSuccessLoadingMetadata &&
      userMetadata.onboardingStep !== STEP_COUNT - 1 &&
      !isSkippedBySession &&
      isSuccessPayments &&
      paymentsData?.paymentMethods?.length
    ) {
      if (userMetadata.onboardingStep) {
        setStepIndex(userMetadata.onboardingStep);
        setIsActive(true);
        setRun(true);
      } else {
        setShowStartModal(true);
      }
    }
  }, [
    isSuccessLoadingMetadata,
    isSkippedBySession,
    userMetadata,
    isSuccessPayments,
    paymentsData,
    setStepIndex,
    setIsActive,
    setRun,
    setShowStartModal,
  ]);

  return { callback, run, stepIndex };
};

export const OnboardingTutorial = () => {
  const { run, stepIndex, callback } = useOnboardingTutorial();
  const { t: tOnboarding } = useTranslation('onboardingTour');

  const onboardingSteps = useMemo(() => steps(tOnboarding), [tOnboarding]);

  return (
    <Joyride
      disableOverlayClose
      stepIndex={stepIndex}
      styles={styles}
      hideBackButton
      callback={callback}
      continuous={stepIndex < STEP_COUNT}
      run={run}
      scrollToFirstStep
      showSkipButton
      steps={onboardingSteps}
      locale={{
        skip: tOnboarding('skip_for_now'),
        next: tOnboarding('next'),
        last: tOnboarding('done'),
      }}
      spotlightClicks
    />
  );
};
