import dayjs from 'dayjs';
import { DateTime } from 'luxon';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';

import { Select } from 'antd';
import engLocale from 'antd/es/date-picker/locale/en_US';
import frLocale from 'antd/es/date-picker/locale/fr_FR';

import { BtnDefault, BtnSmallPrimary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import Modal from 'components/shared/Modal';
import { SUPPORTED_LANGUAGES } from 'constants/global';

import { gridPricesSchema } from './grid-prices.schema';
import {
  ErrorIcon,
  ErrorText,
  StyledBottomFormBlock,
  StyledBtnContainer,
  StyledDatePicker,
  StyledDateTitle,
  StyledTopFormBlock,
  StyledTopFormCol,
  StyledTopFormRow,
  customInputGroupStyles,
  customInputLabelStyles,
  ErrorContainer,
  LabelWrapper,
} from './styles';
import { IGridPrices } from 'services/gridPrices.types';
import { PrmData } from '../PrmBody/types';
import { numberOrEmptyStr } from 'utils/forms';
import {
  useAddGridPricesMutation,
  useUpdateGridPricesMutation,
} from 'services/gridPrices.service';

enum PriceType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

type PricesFormProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  gridPrices?: IGridPrices;
  prmData?: PrmData;
  onRefetchGridPrices: () => void;
};

const mapGridPrices = (gridPrices?: IGridPrices, prmData?: PrmData) => ({
  prm: prmData?.id,
  prmName: `${prmData?.prmValue} / ${prmData?.name}`,
  startDate: gridPrices?.startDate,
  HS_HH: gridPrices?.HS_HH,
  HS_LH: gridPrices?.HS_LH,
  LS_HH: gridPrices?.LS_HH,
  LS_LH: gridPrices?.LS_LH,
  RH: gridPrices?.RH,
});

const ModifyGridPrices = ({
  isModalOpen,
  closeModal,
  gridPrices,
  prmData,
  onRefetchGridPrices,
}: PricesFormProps) => {
  const { t, i18n } = useTranslation();
  const [priceType, setPriceType] = useState(PriceType.DYNAMIC);

  const [addGridPrices] = useAddGridPricesMutation();
  const [updateGridPrices] = useUpdateGridPricesMutation();

  const priceTypeOptions = useMemo(
    () => [
      { label: t('static'), value: PriceType.STATIC },
      { label: t('dynamic'), value: PriceType.DYNAMIC },
    ],
    [t],
  );

  const isEditMode = useMemo(() => !!gridPrices, [gridPrices]);
  const gridPricesByHours = [
    gridPrices?.HS_HH,
    gridPrices?.HS_LH,
    gridPrices?.LS_HH,
    gridPrices?.LS_LH,
    gridPrices?.RH,
  ];

  const isGridPricesEquals =
    gridPrices &&
    gridPricesByHours.every((gridPrice) => gridPrice === gridPricesByHours[0]);
  const initialData = mapGridPrices(gridPrices, prmData);
  const defaultPriceType =
    gridPrices && isEditMode && isGridPricesEquals
      ? PriceType.STATIC
      : PriceType.DYNAMIC;
  const isStaticGridPricesType = priceType === PriceType.STATIC;

  const locale = useMemo(() => {
    if (i18n.language === SUPPORTED_LANGUAGES.English) return engLocale;
    return frLocale;
  }, [i18n.language]);

  const onSubmit = async (values: any) => {
    if (isEditMode) {
      await updateGridPrices({ ...values, _id: gridPrices?._id });
    } else {
      await addGridPrices(values);
    }

    await onRefetchGridPrices();
    closeModal();
  };

  return (
    <Modal
      isVisible={isModalOpen}
      title={t(isEditMode ? 'edit_grid_prices' : 'add_new_grid_prices')}
      onClose={closeModal}
    >
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={mapGridPrices(gridPrices, prmData)}
        validationSchema={gridPricesSchema(t)}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          isValid,
          isSubmitting,
          values,
          errors,
          validateForm,
          setFieldValue,
          setErrors,
          handleSubmit,
        }) => {
          const handleOnStartDateChanged = (_: unknown, date: string) => {
            setFieldValue('startDate', DateTime.fromISO(date, { zone: 'utc' }));
          };

          const handleOnChangePriceType = (priceType: PriceType) => {
            setPriceType(priceType);

            if (priceType === PriceType.DYNAMIC) {
              setFieldValue('HS_LH', null);
              setFieldValue('LS_HH', null);
              setFieldValue('LS_LH', null);
              setFieldValue('RH', null);
            }

            if (priceType === PriceType.STATIC) {
              setFieldValue('HS_LH', values?.HS_HH);
              setFieldValue('LS_HH', values?.HS_HH);
              setFieldValue('LS_LH', values?.HS_HH);
              setFieldValue('RH', values?.HS_HH);
            }
          };

          const handleOnPriceChange = (hsHH: any) => {
            setFieldValue('HS_HH', numberOrEmptyStr(hsHH));

            if (isStaticGridPricesType) {
              setFieldValue('HS_LH', numberOrEmptyStr(hsHH));
              setFieldValue('LS_HH', numberOrEmptyStr(hsHH));
              setFieldValue('LS_LH', numberOrEmptyStr(hsHH));
              setFieldValue('RH', numberOrEmptyStr(hsHH));
            }
          };

          const handleOnFocus = () => {
            setErrors({});
          };

          const handleOnSave = () => {
            validateForm();

            if (isValid) {
              handleSubmit();
            }
          };

          const isBtnDisabled = !isValid || isSubmitting;

          return (
            <Form>
              <StyledTopFormBlock>
                <StyledTopFormCol>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={customInputGroupStyles as any}
                      customLabelStyles={customInputLabelStyles}
                      label={t('prm').toString().toUpperCase()}
                    >
                      <Select
                        disabled
                        defaultValue={initialData?.prmName}
                        getPopupContainer={(node) => node.parentNode}
                        value={values?.prmName}
                        style={{ width: '264px' }}
                        status={errors.prmName ? 'error' : undefined}
                        placeholder={t('select_prm')}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <StyledDateTitle>{t('start_period')}</StyledDateTitle>
                    <StyledDatePicker
                      getPopupContainer={(node: HTMLElement) =>
                        node.parentNode as HTMLElement
                      }
                      popupStyle={{ position: 'absolute' }}
                      locale={locale}
                      defaultValue={
                        values?.startDate
                          ? dayjs(values?.startDate, 'YYYY-MM-DD')
                          : undefined
                      }
                      minDate={
                        isEditMode && values?.startDate
                          ? dayjs(values?.startDate, 'YYYY-MM-DD')
                          : undefined
                      }
                      status={errors.startDate ? 'error' : undefined}
                      onFocus={handleOnFocus}
                      onChange={handleOnStartDateChanged}
                    />
                  </StyledTopFormRow>
                  <StyledTopFormRow>
                    <InputGroup
                      isShowError={false}
                      customStyles={{
                        ...(customInputGroupStyles as any),
                        justifyContent: 'flex-start',
                        gap: '20px',
                      }}
                      customLabelStyles={customInputLabelStyles}
                      label={t('price_type').toString()}
                    >
                      <Select
                        defaultValue={defaultPriceType}
                        onFocus={handleOnFocus}
                        onChange={handleOnChangePriceType}
                        options={priceTypeOptions}
                        getPopupContainer={(node) => node.parentNode}
                        style={{ width: '140px' }}
                      />
                    </InputGroup>
                  </StyledTopFormRow>
                </StyledTopFormCol>
              </StyledTopFormBlock>
              <StyledBottomFormBlock>
                <InputGroup>
                  <div>
                    <LabelWrapper>
                      <strong>{t('hshp')}</strong>, {t('eur_kwh')}
                    </LabelWrapper>
                    <FormikInput
                      onFocus={handleOnFocus}
                      onChange={handleOnPriceChange}
                      type="number"
                      fieldName="HS_HH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <LabelWrapper>
                      <strong>{t('hshc')}</strong>, {t('eur_kwh')}
                    </LabelWrapper>
                    <FormikInput
                      disabled={isStaticGridPricesType}
                      type="number"
                      fieldName="HS_LH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <LabelWrapper>
                      <strong>{t('bshp')}</strong>, {t('eur_kwh')}
                    </LabelWrapper>
                    <FormikInput
                      disabled={isStaticGridPricesType}
                      type="number"
                      fieldName="LS_HH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <LabelWrapper>
                      <strong>{t('bshc')}</strong>, {t('eur_kwh')}
                    </LabelWrapper>
                    <FormikInput
                      disabled={isStaticGridPricesType}
                      type="number"
                      fieldName="LS_LH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <div>
                    <LabelWrapper>
                      <strong>{t('hdp')}</strong>, {t('eur_kwh')}
                    </LabelWrapper>
                    <FormikInput
                      disabled={isStaticGridPricesType}
                      type="number"
                      fieldName="RH"
                      placeholder={'0.530'}
                    />
                  </div>
                </InputGroup>
              </StyledBottomFormBlock>
              <ErrorContainer>
                {!isValid && (
                  <>
                    <ErrorIcon />
                    <ErrorText>
                      {t('please_fill_all_fields_to_proceed')}
                    </ErrorText>
                  </>
                )}
              </ErrorContainer>

              <StyledBtnContainer>
                <BtnDefault onClick={closeModal}>{t('cancel')}</BtnDefault>
                <BtnSmallPrimary
                  isDisabled={isBtnDisabled}
                  onClick={handleOnSave}
                >
                  {t(isEditMode ? 'save' : 'save_new_prices')}
                </BtnSmallPrimary>
              </StyledBtnContainer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModifyGridPrices;
