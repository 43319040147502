import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CostEarned from 'components/communities/Charts/CostEarned';
import ElectricityConsumption, {
  DataInfo,
} from 'components/communities/Charts/ElectricityConsumption';
import ProducerSummaryView from 'components/communities/Charts/ProducerSummaryView';
import SelfConsumption from 'components/communities/Charts/SelfConsumption';
import {
  LABELS,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import {
  selfConsumedEmptyStateByConsumerType,
  selfConsumedEmptyStateByHours,
  selfConsumedInfoItemsByConsumerType,
  selfConsumedInfoItemsByHours,
} from 'components/communities/constants';
import { Row } from 'components/communities/styles';
import {
  getFormattedInfoChartValue,
  getPieColorByConsumerType,
  getPieColorByHours,
} from 'components/communities/utils';
import { PERIOD } from 'constants/types';
import {
  useGetEarningsQuery,
  useGetElectricityProductionQuery,
  useGetElectricityTotalQuery,
  useGetFinancialDataQuery,
  useGetSelfConsumedBySourceQuery,
  useGetSelfConsumedByTypeQuery,
} from 'services/dashboardManagment';
import { ILabelMap } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import { FinancialDataTypes } from '../../types';
import { mapFinancialData, sortSelfConsumedByHoursData } from '../../utils';
import ProducerCommunityData from './components/CommunityData';
import ConsumersMap from './components/ConsumersMap';
import {
  getFinancialDataTotalByType,
  parseFinancialDataByType,
} from './components/FinancialDataTab/components/FinancialFollowUpChartWrapper/utils';
import {
  InfoItems,
  getColumnColorByType,
  legendCostEarnedItems,
} from './constants/CostEarned';
import {
  infoItems as ElectricityConsumptionItems,
  areaColors,
  legendItems,
  lineColors,
} from './constants/ElectricityConsumption';

const ProducerDashboard: FC = () => {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const [costEarnedChartData, setCostEarnedChartData] = useState([]);

  const { data: totalProducerEarnings } = useGetEarningsQuery({
    ...getDateRange(period),
    communityId: undefined,
  });
  const { data: costEarningData, isLoading: isLoadingCostEarning } =
    useGetFinancialDataQuery(getDateRange(period));
  const {
    data: electricityConsumptionData,
    isLoading: isLoadingElectricityConsumption,
  } = useGetElectricityProductionQuery(getDateRange(period));
  const { data: electricityTotalData, isLoading: isLoadingElectricityTotal } =
    useGetElectricityTotalQuery(getDateRange(period));
  const {
    data: selfConsumedByTypeData,
    isLoading: isLoadingSelfConsumedByType,
  } = useGetSelfConsumedByTypeQuery(getDateRange(period));
  const {
    data: selfConsumedBySourceData,
    isLoading: isLoadingSelfConsumedBySource,
  } = useGetSelfConsumedBySourceQuery(getDateRange(period));

  const electricityConsumptionInfoDataArray = [
    electricityTotalData?.production,
    electricityTotalData?.selfConsumption,
    electricityTotalData?.gridConsumption,
  ];

  const electricityConsumptionDataMapped = useMemo(() => {
    const labelsMap: ILabelMap = {
      totalProduction: LABELS.TOTAL_PRODUCTION,
      selfConsumption: LABELS.SELF_CONSUMPTION,
      overproduction: LABELS.OVERPRODUCTION,
    };

    return mapElectricityConsumptionData(
      electricityConsumptionData,
      period,
      labelsMap,
      t,
    );
  }, [electricityConsumptionData, period, t]);

  const overproductionValue = getFormattedInfoChartValue(
    electricityTotalData?.overproduction,
    electricityConsumptionInfoDataArray,
  );

  const dataInfo: DataInfo[] | undefined = [
    {
      title: LABELS.TOTAL_PRODUCTION,
      power: getFormattedInfoChartValue(
        electricityTotalData?.production,
        electricityConsumptionInfoDataArray,
      ),
    },
    {
      title: LABELS.SELF_CONSUMPTION,
      power: getFormattedInfoChartValue(
        electricityTotalData?.selfConsumption,
        electricityConsumptionInfoDataArray,
      ),
    },
    {
      title: LABELS.OVERPRODUCTION,
      power:
        overproductionValue !== 'N/A'
          ? Math.abs(electricityTotalData?.overproduction)
          : overproductionValue,
    },
  ];

  // Cost Earning mappers

  const isMonthlyStep = useMemo(() => {
    return (
      period === PERIOD.CURRENT_YEAR ||
      period === PERIOD.LAST_YEAR ||
      period === PERIOD.LAST_MONTH
    );
  }, [period]);

  const mappedCostEraningData = useMemo(() => {
    if (isMonthlyStep) return mapFinancialData(costEarnedChartData, t);
    return [];
  }, [costEarnedChartData, isMonthlyStep, t]);

  const costEarnedTotals = useMemo(() => {
    if (isMonthlyStep)
      return getFinancialDataTotalByType(
        costEarnedChartData,
        FinancialDataTypes.ALL_EARNINGS,
      );

    return [0, 0];
  }, [costEarnedChartData, isMonthlyStep]);

  useEffect(() => {
    setCostEarnedChartData(
      parseFinancialDataByType(
        costEarningData,
        FinancialDataTypes.ALL_EARNINGS,
      ),
    );
  }, [costEarningData]);

  // Pie charts mappers

  const selfConsumedByTypePresentableData =
    selfConsumedByTypeData && selfConsumedByTypeData.length
      ? selfConsumedByTypeData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.scheduleType,
        }))
      : selfConsumedEmptyStateByHours;

  const selfConsumedBySourcePresentableData =
    selfConsumedBySourceData && selfConsumedBySourceData.length
      ? selfConsumedBySourceData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.consumerType,
        }))
      : selfConsumedEmptyStateByConsumerType;

  return (
    <>
      <ProducerSummaryView period={period} />
      <ElectricityConsumption
        isConsumer
        title={t('energy_production')}
        lineColors={lineColors}
        areaColors={areaColors}
        infoItems={ElectricityConsumptionItems(t)}
        legendItems={legendItems(t)}
        dataInfo={dataInfo}
        electricityConsumption={electricityConsumptionDataMapped}
        isLoading={isLoadingElectricityConsumption || isLoadingElectricityTotal}
      />
      <Row>
        <SelfConsumption
          getPieColor={getPieColorByConsumerType}
          infoItems={selfConsumedInfoItemsByConsumerType(t)}
          chartData={selfConsumedBySourcePresentableData}
          title={t('self_consumption_by_consumer_type')}
          isLoading={isLoadingSelfConsumedBySource}
        />
        <SelfConsumption
          getPieColor={getPieColorByHours}
          infoItems={selfConsumedInfoItemsByHours(t)}
          chartData={sortSelfConsumedByHoursData(
            selfConsumedByTypePresentableData,
          )}
          title={t('energy_consumed_by_type_of_hours')}
          isLoading={isLoadingSelfConsumedByType}
        />
      </Row>
      <Row>
        <CostEarned
          isMonthlyStep={isMonthlyStep}
          chartData={mappedCostEraningData}
          totalCostEarned={totalProducerEarnings?.earnings}
          totals={costEarnedTotals}
          legendItems={legendCostEarnedItems(t)}
          getColumnColorByType={getColumnColorByType}
          title={t('cost_earned')}
          infoItems={InfoItems(t)}
          isLoading={isLoadingCostEarning}
        />
        <ProducerCommunityData />
      </Row>
      <ConsumersMap />
    </>
  );
};

export default ProducerDashboard;
