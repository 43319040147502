import { createLogger } from 'redux-logger';

import { configureStore } from '@reduxjs/toolkit';

import authReducer from 'components/auth/authSlice';
import createConsumer from 'components/members/createConsumer/slice';
import taxPricesReducer from 'components/pricing/components/TaxPrices/tax-prices.slice';
import ReportDatePickerReducer from 'components/shared/DatePicker/slice';
import DashboardPeriodReducer from 'components/shared/PeriodPicker/slice';
import { authApi } from 'services/auth';
import { communityManagement } from 'services/communityManagement';
import { dashboardApi } from 'services/dashboardManagment';
import { geocoderApi } from 'services/geocoder';
import { invoiceApi } from 'services/invoiceManagment';
import { createConsumer as consumerApi } from 'services/membersManagement';
import { paymentApi } from 'services/paymentManagment';
import { pmoDashboard } from 'services/pmoDashboardManagment';
import { pmoMemberDashboard } from 'services/pmoMemberDashboardManagment';
import { prmApi } from 'services/prmManagement';
import { prmPricesApi } from 'services/prmPricesManagement';
import { reportApi } from 'services/reportManagment';
import { taxPricesApi } from 'services/taxPrices.service';
import { userManagement } from 'services/userManagement';
import { gridPricesApi } from '../services/gridPrices.service';
import { documentApi } from '../services/documentManagement';

const logger = createLogger();

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userManagement.reducerPath]: userManagement.reducer,
    [consumerApi.reducerPath]: consumerApi.reducer,
    [communityManagement.reducerPath]: communityManagement.reducer,
    [geocoderApi.reducerPath]: geocoderApi.reducer,
    [pmoDashboard.reducerPath]: pmoDashboard.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [pmoMemberDashboard.reducerPath]: pmoMemberDashboard.reducer,
    [prmApi.reducerPath]: prmApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [taxPricesApi.reducerPath]: taxPricesApi.reducer,
    [prmPricesApi.reducerPath]: prmPricesApi.reducer,
    [gridPricesApi.reducerPath]: gridPricesApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,

    auth: authReducer,
    consumer: createConsumer,
    dashboardPeriod: DashboardPeriodReducer,
    reportDatePicker: ReportDatePickerReducer,
    taxPrices: taxPricesReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      reportApi.middleware,
      userManagement.middleware,
      consumerApi.middleware,
      communityManagement.middleware,
      geocoderApi.middleware,
      pmoDashboard.middleware,
      dashboardApi.middleware,
      pmoMemberDashboard.middleware,
      prmApi.middleware,
      paymentApi.middleware,
      invoiceApi.middleware,
      taxPricesApi.middleware,
      prmPricesApi.middleware,
      gridPricesApi.middleware,
      documentApi.middleware,
      logger,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
