import { FC } from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { initialValues } from '../../constants';
import PrmNumber from '../PrmNumber';
import { StyledPrmBody } from './styles';

const PrmBody: FC<{ isLoadingPrm?: boolean }> = ({ isLoadingPrm }) => {
  const { values } = useFormikContext<typeof initialValues>();

  return (
    <StyledPrmBody>
      <FieldArray
        name="additionalInfo.prms"
        render={(arrayHelpers) => (
          <>
            {values.prms &&
              values.prms.map((number, idx) => (
                <PrmNumber isLoadingPrm={isLoadingPrm} key={idx} index={idx} />
              ))}
          </>
        )}
      />
    </StyledPrmBody>
  );
};

export default PrmBody;
