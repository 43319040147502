import { mapDate, mapPeriodToValue } from 'utils';

import { TFunction } from 'i18next';

import { isValidCoordinate } from 'components/shared/MapboxMap';
import { REQUEST_METHOD, monthLabels } from 'constants/global';
import { IDBCommunity, IDBPrm, IDBUser, PERIOD } from 'constants/types';

import { LOCAL_STORAGE_KEYS } from 'utils/local-storage';

import { IFinancialDataItem } from './components/ConsumerDashboard/components/FinancialDataTab/components/FinancialFollowUpChartWrapper/types';
import { ISelfConsumedData } from './components/ProducerDashboard/types';

const filterWrongCoordinates = (user: IDBUser) => {
  const coordinates = user?.address?.location?.coordinates;

  if (!coordinates || coordinates.length < 2) {
    return false;
  }

  const [longitude, latitude] = coordinates;

  return isValidCoordinate(longitude) && isValidCoordinate(latitude);
};
export const getConsumersCoordinatesPresentable = (community: IDBCommunity) => {
  if (!community?.consumers) return [];

  return community.consumers
    .filter(({ user }) => filterWrongCoordinates(user))
    .map(({ user }) => ({
      id: user._id,
      type: user.role,
      latitude: user.address.location.coordinates[0],
      longitude: user.address.location.coordinates[1],
      title: user.middleName
        ? `${user.firstName} ${user.middleName} ${user.lastName}`
        : `${user.firstName} ${user.lastName}`,
      location: user.address.street,
    }));
};

export const getConsumersAndProducersCoordinatesPresentable = (
  members: IDBUser[],
) => {
  if (!members.length) return [];

  return members.filter(filterWrongCoordinates).map((user) => ({
    id: user._id,
    type: user.role,
    latitude: user.address.location.coordinates[0],
    longitude: user.address.location.coordinates[1],
    title: user.middleName
      ? `${user.firstName} ${user.middleName} ${user.lastName}`
      : `${user.firstName} ${user.lastName}`,
    location: user.address.street,
  }));
};

export const getConsumersPerimeterCoordinates = (community: IDBCommunity) => {
  const MIN_PERIMETER_DATA_AMOUNT = 3;

  if (!community?.consumers) return undefined;
  if (community?.consumers.length < MIN_PERIMETER_DATA_AMOUNT) return undefined;

  const communityId = community._id || community.consumers[0]?.community;

  const sortByCoordinates = (firstConsumer: IDBPrm, nextConsumer: IDBPrm) => {
    const coordinatesFirst = firstConsumer.user.address.location.coordinates;
    const coordinatesNext = nextConsumer.user.address.location.coordinates;

    // Compare the latitude (first coordinate)
    if (coordinatesFirst[0] < coordinatesNext[0]) return -1;
    if (coordinatesFirst[0] > coordinatesNext[0]) return 1;

    // If the latitude is the same, compare the longitude (second coordinate)
    if (coordinatesFirst[1] < coordinatesNext[1]) return -1;
    if (coordinatesFirst[1] > coordinatesNext[1]) return 1;

    // If both latitude and longitude are the same, consider them equal
    return 0;
  };

  const consumersCoordinates = community.consumers
    .filter(({ user }) => filterWrongCoordinates(user))
    .sort(sortByCoordinates)
    .map(({ user }) => [
      user.address.location.coordinates[1],
      user.address.location.coordinates[0],
    ]);

  return [
    {
      id: communityId,
      coordinates: [...consumersCoordinates, consumersCoordinates[0]],
    },
  ];
};

export const parseCSVFileNameFromContentDisposition = (
  contentDisposition: string,
) => {
  const regex = /filename=([^;]+)/;
  const match = contentDisposition?.match(regex);

  let filename = '';
  if (match && match[1]) {
    filename = match[1];
  }

  return filename;
};

export const sortSelfConsumedByHoursData = (data?: ISelfConsumedData[]) => {
  const order = ['HS_HH', 'HS_LH', 'LS_HH', 'LS_LH', 'RH'];

  return data?.sort((a, b) => {
    const indexA = order.indexOf(a.type);
    const indexB = order.indexOf(b.type);
    return indexA - indexB;
  });
};

export function mapRepartitionKeyData(data: any, period: PERIOD, t: TFunction) {
  const result: Array<any> = [];
  const value = mapPeriodToValue(period);

  data?.forEach((item: any) => {
    const sevenDaysDate = `${item.dayOfMonth} ${mapDate(item[value], period, t)
      .toUpperCase()
      .slice(0, 2)}`;
    result.push({
      date:
        period === PERIOD.LAST_SEVEN_DAYS
          ? sevenDaysDate
          : mapDate(item[value], period, t),
      value: item.repartitionKey,
      tooltipData: {
        ...item,
      },
    });
  });

  return result;
}

export const mapFinancialData = (data: any, t: TFunction) => {
  if (!data) return [];

  const result: Array<IFinancialDataItem> = [];
  data?.forEach((item: any) => {
    const monthValue = item['month'];
    result.push({
      type: item.status,
      date: t(monthLabels[monthValue]),
      value: item.total,
      tooltipData: {
        ...item,
      },
    });
  });

  return result;
};

export const fetchAndDownloadPdfFile = async (
  downloadLink: string,
  date: string,
  type: string = 'invoice',
) => {
  try {
    const getAuthorizationHeader = () => ({
      'Content-Type': 'text/csv',
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.AccessToken,
      )}`,
    });

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${downloadLink}`,
      {
        method: REQUEST_METHOD.GET,
        headers: getAuthorizationHeader(),
      },
    );

    if (!response.ok) {
      return;
    }
    //
    const blob = await response.blob();

    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${date} ${type}.pdf`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {}
};

export const fetchViewerPdfFile = async (
  downloadLink: string,
  date: string,
) => {
  try {
    const getAuthorizationHeader = () => ({
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.AccessToken,
      )}`,
    });

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${downloadLink}`,
      {
        method: REQUEST_METHOD.GET,
        headers: {
          ...getAuthorizationHeader(),
        },
        mode: 'cors',
      },
    );

    if (!response.ok) {
      return null;
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  } catch (error) {
    return null;
  }
};
