import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CommunityData from 'components/communities/Charts/CommunityData';
import ConsumerSummaryView from 'components/communities/Charts/ConsumerSummaryView';
import ElectricityConsumption from 'components/communities/Charts/ElectricityConsumption';
import RepartitionKey from 'components/communities/Charts/RepartitionKey';
import SelfConsumption from 'components/communities/Charts/SelfConsumption';
import EnergyTips from 'components/communities/Dashboard/components/ConsumerDashboard/components/EnergyTips';
import { tipsData } from 'components/communities/Dashboard/components/ConsumerDashboard/constants/EnergyTips';
import {
  mapRepartitionKeyData,
  sortSelfConsumedByHoursData,
} from 'components/communities/Dashboard/utils';
import {
  LABELS,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import {
  selfConsumedEmptyStateByHours,
  selfConsumedInfoItemsByHours,
} from 'components/communities/constants';
import { Row } from 'components/communities/styles';
import {
  getFormattedInfoChartValue,
  getPieColorByHours,
} from 'components/communities/utils';
import {
  useGetCommunityDataQuery,
  useGetElectricityTotalQuery,
  useGetElectricityUsageQuery,
  useGetRepartitionKeyQuery,
  useGetSelfConsumedByTypeQuery,
} from 'services/dashboardManagment';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { ILabelMap } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import {
  legendItems as ElectricityConsumptionLegendItems,
  areaColors,
  infoItems,
  lineColors,
} from './constants/ElectricityConsumption';

const CHART_KEYS = [
  'mySelfConsumptionRatio',
  'gridConsumptionRatio',
  'selfConsumptionRatio',
];

const PmoConsumerDashboard = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const period = useAppSelector((state) => state.dashboardPeriod.period);

  const { data: consumer } = useGetMemberByIdQuery(id);

  const { data: repartitionKeyData, isLoading: isLoadingRepartitionKeyData } =
    useGetRepartitionKeyQuery({
      ...getDateRange(period),
      userId: id,
    });

  const {
    data: electricityConsumptionData,
    isLoading: isLoadingElectricityConsumptionData,
  } = useGetElectricityUsageQuery({
    ...getDateRange(period),
    userId: id,
  });
  const { data: electricityTotalData, isLoading: isLoadingElectricityTotal } =
    useGetElectricityTotalQuery({
      ...getDateRange(period),
      userId: id,
    });
  const { data: communityData, isLoading: isLoadingCommunityData } =
    useGetCommunityDataQuery({
      ...getDateRange(period),
      userId: id,
    });

  const {
    data: selfConsumedByTypeData,
    isLoading: isLoadingSelfConsumedByType,
  } = useGetSelfConsumedByTypeQuery({
    ...getDateRange(period),
    userId: id,
  });

  const electricityConsumptionInfoDataArray = [
    electricityTotalData?.consumption,
    electricityTotalData?.selfConsumption,
    electricityTotalData?.gridConsumption,
  ];

  const electricityConsumptionDataMapped = useMemo(() => {
    const labelsMap: ILabelMap = {
      totalConsumption: LABELS.TOTAL_CONSUMPTION,
      selfConsumption: LABELS.SELF_CONSUMPTION,
      gridConsumption: LABELS.GRID_CONSUMPTION,
    };

    return mapElectricityConsumptionData(
      electricityConsumptionData,
      period,
      labelsMap,
      t,
    );
  }, [electricityConsumptionData, period, t]);

  const repartitionKeyDataMapped = useMemo(() => {
    return mapRepartitionKeyData(repartitionKeyData?.byPeriod, period, t);
  }, [repartitionKeyData, period, t]);

  const communityDataMapped = useMemo(() => {
    if (communityData) {
      return Object.keys(communityData)
        .filter((item) => CHART_KEYS.includes(item))
        .map((item, index) => ({
          date: index,
          value: communityData[item],
          type: item,
        }));
    }
    return [];
  }, [communityData]);

  const selfConsumedByTypePresentableData =
    selfConsumedByTypeData && selfConsumedByTypeData.length
      ? selfConsumedByTypeData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.scheduleType,
        }))
      : selfConsumedEmptyStateByHours;

  const consumerName = `${consumer?.firstName} ${consumer?.middleName} ${consumer?.lastName}`;

  const isLoadingElectricityConsumption = useMemo(
    () => isLoadingElectricityTotal || isLoadingElectricityConsumptionData,
    [isLoadingElectricityTotal, isLoadingElectricityConsumptionData],
  );

  return (
    <>
      <ConsumerSummaryView period={period} userId={id} />
      <ElectricityConsumption
        isConsumer
        title={t('electricity_consumption')}
        legendItems={ElectricityConsumptionLegendItems(t)}
        infoItems={infoItems(t)}
        lineColors={lineColors}
        areaColors={areaColors}
        total={getFormattedInfoChartValue(
          electricityTotalData?.consumption,
          electricityConsumptionInfoDataArray,
        )}
        selfConsumption={getFormattedInfoChartValue(
          electricityTotalData?.selfConsumption,
          electricityConsumptionInfoDataArray,
        )}
        gridConsumption={getFormattedInfoChartValue(
          electricityTotalData?.gridConsumption,
          electricityConsumptionInfoDataArray,
        )}
        electricityConsumption={electricityConsumptionDataMapped}
        isLoading={isLoadingElectricityConsumption}
      />
      <Row>
        <RepartitionKey
          data={repartitionKeyDataMapped}
          percent={repartitionKeyData?.average}
          isLoading={isLoadingRepartitionKeyData}
        />
        <CommunityData
          chartData={communityDataMapped}
          data={communityData}
          benchMarkData={undefined}
          communityName={communityData?.communityName}
          isLoading={isLoadingCommunityData}
        />
      </Row>
      <Row>
        <SelfConsumption
          getPieColor={getPieColorByHours}
          infoItems={selfConsumedInfoItemsByHours(t)}
          chartData={sortSelfConsumedByHoursData(
            selfConsumedByTypePresentableData,
          )}
          title={t('energy_self_consumed_by_type_of_hours')}
          isLoading={isLoadingSelfConsumedByType}
        />
        <EnergyTips
          tip={tipsData[consumer?.additionalInfo?.type || 'none']}
          consumerName={consumerName}
        />
      </Row>
    </>
  );
};

export default PmoConsumerDashboard;
