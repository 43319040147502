export default {
  selfProduction: {
    percent: 24,
    isLoading: false,
  },
  costSaved: {
    isLoading: false,
    costSavedAmount: 4345,
  },
  emissions: {
    isLoading: false,
    amount: 4345,
  },
  selfConsuption: {
    isLoading: false,
    percent: 11,
  },
};
