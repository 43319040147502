import React, { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOnboardingTutorialContext } from 'utils/onboardingTutorial/context';
import { OnboardingTutorial } from 'utils/onboardingTutorial/useOnboardingTutorial';
import { useSetUserMetadataMutation } from 'services/userManagement';
import Header from 'components/shared/Header';
import { StyledPageWrapper } from 'components/shared/MainOutlet/styles';
import ModalMessage from 'components/shared/ModalMessage';
import Container from '../Container';
import { STEP_COUNT } from 'utils/onboardingTutorial/steps';

const MainOutlet: React.FC = () => {
  const {
    showStartModal,
    setIsActive,
    setShowStartModal,
    setRun,
    run: onboardingRun,
  } = useOnboardingTutorialContext();
  const { t: tOnboarding } = useTranslation('onboardingTour');
  const [setUserMetadata] = useSetUserMetadataMutation();

  const startOnboarding = useCallback(() => {
    setIsActive(true);
    setRun(true);
    setShowStartModal(false);
  }, [setIsActive, setRun, setShowStartModal]);

  const skipOnboarding = useCallback(async () => {
    await setUserMetadata({ onboardingStep: STEP_COUNT });
    setIsActive(false);
    setRun(false);
    setShowStartModal(false);
  }, [setUserMetadata, setIsActive, setRun, setShowStartModal]);

  const disableClick = useCallback(
    (event: any) => {
      if (onboardingRun) {
        event.stopPropagation();
      }
    },
    [onboardingRun],
  );

  return (
    <Container>
      {showStartModal && (
        <ModalMessage
          messageType="success"
          title={tOnboarding('start_modal_title')}
          subTitle={tOnboarding('start_modal_text')}
          btnText={tOnboarding('guide_me')}
          handleBtn={startOnboarding}
          onCancelClick={skipOnboarding}
          cancelText={tOnboarding('skip_intro') as string}
          btnWidth="auto"
        />
      )}
      <OnboardingTutorial />
      <div onClickCapture={disableClick}>
        <Header />
      </div>
      <StyledPageWrapper>
        <Outlet />
      </StyledPageWrapper>
    </Container>
  );
};

export default MainOutlet;
