import { downloadFile, getAppRole } from 'utils';

import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ALL_CONSUMERS,
  DATA_TYPE_ITEMS,
} from 'components/communities/Dashboard/constants';
import CustomCheckboxSelect from 'components/communities/components/CustomCheckboxSelect';
import { BtnPrimary } from 'components/shared/Button';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import CustomDatePicker from 'components/shared/DatePicker';
import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import ModalMessage from 'components/shared/ModalMessage';
import { CustomSelectItem, USER_ROLES } from 'constants/types';
import { useGetConsumersQuery } from 'services/communityManagement';

import { useAppSelector } from 'utils/hooks';

import {
  StyledLabel,
  StyledProducerReportButton,
  StyledReportSelectRow,
  StyledReportWrapper,
} from './styles';

interface CreateReportProps {
  setDataTypeValue?: Dispatch<SetStateAction<CustomSelectItem>>;
  dataTypeValue?: CustomSelectItem;
  datePickerType?: 'month' | 'date';
  fetchCsvFile: ({
    dates,
    consumerIds,
    userId,
  }: {
    dates: any;
    consumerIds?: string[];
    userId?: string;
  }) => void;
}

interface IReport {
  fileName: string;
  data: Blob | string;
}

const ProducerCreateReport: FC<CreateReportProps> = ({
  dataTypeValue,
  setDataTypeValue,
  fetchCsvFile,
  datePickerType,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState<IReport | null>({
    fileName: '',
    data: '',
  });
  const [selectedConsumers, setSelectedConsumers] = useState<string[]>([]);
  const reportDatePickerDates = useAppSelector(
    (state) => state.reportDatePicker.dates,
  );
  const { isFetching, data } = useGetConsumersQuery({
    userId: id,
  });
  const [consumerItems, setConsumerItems] = useState([
    {
      label: t(ALL_CONSUMERS),
      key: ALL_CONSUMERS,
    },
  ]);

  const userRole = getAppRole();
  const isTaxRefundReportHidden = useMemo(
    () => userRole !== USER_ROLES.PRODUCER && userRole !== USER_ROLES.ADMIN,
    [userRole],
  );
  const reportDataTypes = useMemo(() => {
    return isTaxRefundReportHidden
      ? DATA_TYPE_ITEMS(t).filter(
          ({ key }: { key: string }) => key !== 'tax_refund_data',
        )
      : DATA_TYPE_ITEMS(t);
  }, [isTaxRefundReportHidden, t]);

  const onCreateReport = async () => {
    try {
      setIsLoading(true);
      const consumerIds =
        selectedConsumers.length === 0 ? undefined : selectedConsumers;
      const { fileName, data }: any = await fetchCsvFile({
        consumerIds,
        dates: {
          startDate: reportDatePickerDates.start,
          endDate: reportDatePickerDates.end,
        },
        userId: id,
      });

      if (data || fileName) {
        setReport({ fileName, data });
      }

      setIsLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(true);
    }
  };

  const handleButtonClick = () => {
    setIsModalOpen(false);
    downloadFile(report?.data, report?.fileName);
  };

  const onConsumerSelectChange = (selectedConsumers: CustomSelectItem[]) => {
    setSelectedConsumers(
      selectedConsumers.map((consumer) => consumer?.key?.toString()),
    );
  };

  const onDataTypeChange = (dataType: CustomSelectItem) => {
    setDataTypeValue?.(dataType);
  };

  useEffect(() => {
    if (!isFetching && Array.isArray(data?.consumers)) {
      const consumersIds = new Set();
      const newConsumers: any[] = [];

      data?.consumers.forEach((consumer: any) => {
        if (consumersIds.has(consumer.user?._id)) return;

        consumersIds.add(consumer.user?._id);
        newConsumers.push({
          label: `${consumer.user?.firstName} ${consumer.user?.lastName}`,
          key: consumer.user?._id,
        });
      });

      setConsumerItems(newConsumers);
    }
  }, [data, isFetching]);

  return (
    <StyledReportWrapper>
      <StyledReportSelectRow>
        <CustomDatePicker type={datePickerType} />
      </StyledReportSelectRow>
      {dataTypeValue && (
        <StyledReportSelectRow>
          <StyledLabel>{t('show_data_for')}</StyledLabel>
          <CustomSelect
            width={200}
            items={reportDataTypes as any}
            value={dataTypeValue}
            onChange={onDataTypeChange}
          />
        </StyledReportSelectRow>
      )}
      <StyledReportSelectRow>
        <StyledLabel>{t('consumers_amount')}</StyledLabel>
        <CustomCheckboxSelect
          isDisabled={dataTypeValue?.label === t('tax_refund_data')} // TODO: refactor to use ENUM for 'dataTypeValue'
          items={consumerItems}
          onChange={onConsumerSelectChange}
          selectWidth="170px"
        />
      </StyledReportSelectRow>
      <StyledProducerReportButton>
        <BtnPrimary onClick={onCreateReport}>{t('create_report')}</BtnPrimary>
      </StyledProducerReportButton>
      {isLoading && <FullScreenLoader />}
      {isModalOpen && (
        <ModalMessage
          messageType="info"
          title={''}
          subTitle={t('notification_report')}
          btnText={t('ok')}
          handleBtn={handleButtonClick}
        />
      )}
    </StyledReportWrapper>
  );
};

export default ProducerCreateReport;
