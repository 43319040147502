import { TFunction } from 'i18next';
import { Placement, StylesWithFloaterStyles } from 'react-joyride';
import AppRoutes from 'constants/routes';

export enum ONBOARDING_FLOW {
  STEP_0 = 0,
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
  STEP_5 = 5,
  STEP_6 = 6,
  STEP_7 = 7,
  STEP_8 = 8,
  STEP_9 = 9,
  STEP_10 = 10,
  STEP_11 = 11,
}

export const STEP_COUNT = Object.keys(ONBOARDING_FLOW).length / 2;

export const steps = (t: TFunction) => [
  {
    target: '.onboarding-step-1',
    content: t('step_1_text'),
    title: t('step_num', { currentStep: '1', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-2',
    content: t('step_2_text'),
    title: t('step_num', { currentStep: '2', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-3',
    content: t('step_3_text'),
    title: t('step_num', { currentStep: '3', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-4',
    content: t('step_4_text'),
    title: t('step_num', { currentStep: '4', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-5',
    content: t('step_5_text'),
    title: t('step_num', { currentStep: '5', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-6',
    content: t('step_6_text'),
    title: t('step_num', { currentStep: '6', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-7',
    content: t('step_7_text'),
    title: t('step_num', { currentStep: '7', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    data: {
      currentUrl: AppRoutes.EnergyData,
    },
    styles: {
      spotlight: {
        background: 'none',
      },
    },
    placement: 'bottom' as Placement,
    offset: -10,
  },
  {
    target: '.onboarding-step-8',
    content: t('step_8_text'),
    title: t('step_num', { currentStep: '8', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    data: {
      currentUrl: AppRoutes.EnergyData,
    },
    styles: {
      options: {
        zIndex: 100001,
      },
    },
  },
  {
    target: '.onboarding-step-9',
    content: t('step_9_text'),
    title: t('step_num', { currentStep: '9', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    data: {
      currentUrl: AppRoutes.FinancialData,
    },
    styles: {
      spotlight: {
        background: 'none',
      },
    },
    placement: 'bottom' as Placement,
    offset: -10,
  },
  {
    target: '.onboarding-step-10',
    content: t('step_10_text'),
    title: t('step_num', { currentStep: '10', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    data: {
      currentUrl: AppRoutes.Profile,
    },
    placement: 'left-start' as Placement,
    offset: -4,
    styles: {
      floaterStyles: {
        options: {
          zIndex: 100001,
        },
      },
      spotlight: {
        background: 'none',
      },
    } as StylesWithFloaterStyles,
  },
  {
    target: '.onboarding-step-11',
    content: t('step_11_text'),
    title: t('step_num', { currentStep: '11', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    data: {
      currentUrl: AppRoutes.Settings,
    },
    placement: 'bottom-end' as Placement,
    offset: -185,
  },
  {
    target: '.onboarding-step-12',
    content: t('step_12_text'),
    title: t('step_num', { currentStep: '12', stepsCount: STEP_COUNT }),
    disableBeacon: true,
    data: {
      currentUrl: AppRoutes.Settings,
    },
    placement: 'bottom' as Placement,
  },
  // {
  //   target: '.onboarding-step-13',
  //   content: t('step_13_text'),
  //   title: t('step_num', { currentStep: '13', stepsCount: STEP_COUNT }),
  //   disableBeacon: true,
  //   data: {
  //     currentUrl: AppRoutes.HelpCenter,
  //   },
  //   placement: 'left-start' as Placement,
  //   styles: {
  //     floaterStyles: {
  //       // arrow: {
  //       //   // margin: -124,
  //       //   top: "0!important"
  //       // },
  //       options: {
  //         zIndex: 100001,
  //       }
  //     }
  //   } as StylesWithFloaterStyles,
  // },
];
