import type { TFunction } from 'i18next';
import { COLORS } from 'constants/colors';
import { CONSUMER_INVOICE_STATUSES } from 'constants/types';
import { StyledTag, StyledTagWrapper } from './styles';

export const columns = (t: TFunction) => [
  {
    title: t('date_created').toUpperCase(),
    dataIndex: 'dateCreated',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      return (
        (new Date(
          firstInvoice.dateCreated.split('.').reverse().join('-'),
        ) as any) -
        (new Date(
          nextInvoice.dateCreated.split('.').reverse().join('-'),
        ) as any)
      );
    },
  },
  {
    title: t('invoice_id').toUpperCase(),
    dataIndex: 'invoiceNumber',
    render: (text: string) => `#${text}`,
    sorter: (firstInvoice: any, nextInvoice: any) =>
      firstInvoice.invoiceId
        .toString()
        .localeCompare(nextInvoice.invoiceId.toString()),
  },
  {
    title: t('billing_period').toUpperCase(),
    dataIndex: 'billingPeriod',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      if (!firstInvoice?.billingPeriod || !nextInvoice?.billingPeriod) {
        return firstInvoice?.billingPeriod ? -1 : 1;
      }

      const parseStartDate = (period: string) => {
        const parts = period.split(' – ')[0].split('/');
        return new Date(`${parts[2]}/${parts[1]}/${parts[0]}`);
      };

      const startDate1: any = parseStartDate(firstInvoice.billingPeriod);
      const startDate2: any = parseStartDate(nextInvoice.billingPeriod);
      return startDate1 - startDate2;
    },
  },
  {
    title: t('due_date').toUpperCase(),
    dataIndex: 'dueDate',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      return (
        (new Date(firstInvoice.dueDate.split('.').reverse().join('-')) as any) -
        (new Date(nextInvoice.dueDate.split('.').reverse().join('-')) as any)
      );
    },
  },
];

export const getPrmStatusTagByInvoiceType = (
  invoiceType: string,
  t: TFunction,
) => {
  switch (invoiceType) {
    case CONSUMER_INVOICE_STATUSES.PAID:
      return (
        <StyledTagWrapper>
          <StyledTag color="green">{t('paid')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.ISSUED:
      return (
        <StyledTagWrapper>
          <StyledTag color="lime">{t('issued')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.OVERDUE:
      return (
        <StyledTagWrapper>
          <StyledTag color="red">{t('overdue')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.ERROR:
      return (
        <StyledTagWrapper>
          <StyledTag color="red">{t('invoice_generation_error')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.PAYMENT_ERROR:
      return (
        <StyledTagWrapper>
          <StyledTag color="red">{t('payment_error')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.CANCELLED:
      return (
        <StyledTagWrapper>
          <StyledTag color={COLORS.TextSecondary}>{t('cancelled')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.PAYMENT_IN_PROGRESS:
      return (
        <StyledTagWrapper>
          <StyledTag color="gold">{t('awaiting_payment')}</StyledTag>
        </StyledTagWrapper>
      );
    case CONSUMER_INVOICE_STATUSES.AWAITING_PAYMENT:
      return (
        <StyledTagWrapper>
          <StyledTag color="gold">{t('awaiting_payment')}</StyledTag>
        </StyledTagWrapper>
      );
  }
};
