import React from 'react';
import type { DrawerProps } from 'antd';
import { Drawer } from 'antd';
import UploadDocumentForm from './components/UploadDocumentForm';
import { USER_ROLES } from '../../../constants/global';
type IUploadDocumentDrawer = DrawerProps & {
  userRole: USER_ROLES;
  onUploadSuccess: any;
};
const UploadDocumentDrawer = ({
  open,
  onClose,
  placement,
  title,
  userRole,
  onUploadSuccess,
}: IUploadDocumentDrawer) => {
  return (
    <Drawer
      title={title}
      placement={placement}
      closable={true}
      onClose={onClose}
      open={open}
      key={placement}
      zIndex={10000000}
      width={400}
      styles={{
        body: {
          paddingBottom: 80,
        },
        header: {
          textTransform: 'capitalize',
        },
      }}
    >
      <UploadDocumentForm
        userRole={userRole}
        onUploadSuccess={onUploadSuccess}
      />
    </Drawer>
  );
};

export default UploadDocumentDrawer;
