import { styled } from 'styled-components';

import { DownOutlined, FileDoneOutlined, UpOutlined } from '@ant-design/icons';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledConsumerWrapper = styled.div`
  background-color: ${COLORS.BgContainer};
  padding: 16px 0;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

export const StyledConsumerSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
`;

export const StyledConsumerName = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-left: 16px;
`;

export const StyledConsumerInfoItem = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
`;

export const ArrowDown = styled(DownOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowUp = styled(UpOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowButton = styled.div`
  text-align: end;
  cursor: pointer;
  margin-right: 16px;
`;

export const FileIcon = styled(FileDoneOutlined)`
  color: ${COLORS.ErrorBase};
  font-size: 12px;
  margin-right: 6px;
`;

export const StyledDurationText = styled.span`
  color: ${COLORS.ErrorBase};
  text-transform: uppercase;
`;

export const StyledDivider = styled.div<{ isExpanded: boolean }>`
  border-top: ${({ isExpanded }) =>
    isExpanded ? '1px solid #0000000F' : 'none'};
  margin-top: ${({ isExpanded }) => (isExpanded ? '16px' : '0')};
  padding-top: ${({ isExpanded }) => (isExpanded ? '8px' : '0')};
`;

export const ArrowText = styled(TextRegular)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`;
