import {
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

export interface OnboardingTutorialState {
  run: boolean;
  stepIndex: number;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  setStepIndex: Dispatch<SetStateAction<number>>;
  showStartModal: boolean;
  setShowStartModal: Dispatch<SetStateAction<boolean>>;
  setRun: Dispatch<SetStateAction<boolean>>;
}

const onboardingTutorialState = {
  run: false,
  stepIndex: 0,
  isActive: false,
  showStartModal: false,
  setStepIndex: (val: number) => {},
  setIsActive: (val: boolean) => {},
  setShowStartModal: (val: boolean) => {},
  setRun: (val: boolean) => {},
};

export const OnboardingTutorialContext = createContext(onboardingTutorialState);

export function OnboardingTutorialProvider(props: any) {
  const [showStartModal, setShowStartModal] = useState<boolean>(
    onboardingTutorialState.showStartModal,
  );
  const [isActive, setIsActive] = useState<boolean>(
    onboardingTutorialState.isActive,
  );
  const [run, setRun] = useState<boolean>(onboardingTutorialState.run);
  const [stepIndex, setStepIndex] = useState<number>(
    onboardingTutorialState.stepIndex,
  );

  const value = useMemo(
    () => ({
      isActive,
      stepIndex,
      setIsActive,
      setStepIndex,
      showStartModal,
      setShowStartModal,
      run,
      setRun,
    }),
    [
      isActive,
      stepIndex,
      setIsActive,
      setStepIndex,
      showStartModal,
      setShowStartModal,
      run,
      setRun,
    ],
  );

  return <OnboardingTutorialContext.Provider value={value} {...props} />;
}

export function useOnboardingTutorialContext() {
  const context = useContext(OnboardingTutorialContext);

  return context;
}
