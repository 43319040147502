import { Document } from '../../services/document.types';

export const placement = 'right';
export const formInitialValues: Document = {
  startDate: '',
  endDate: '',
  signingDate: '',
  type: '',
  file: '',
};
