import { styled } from 'styled-components';
import { COLORS } from 'constants/colors';
import {
  MAX_CONTENT_WIDTH,
  ScreenContentMaxSize,
} from 'constants/screen-breakpoints';

export const StyledHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  float: left;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledContainer = styled.div`
  padding: 32px;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 0 auto;
  box-sizing: content-box;
  ${ScreenContentMaxSize`
    box-sizing: border-box;  
  `}
`;

export const StyledBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

export const StyledEmptyDataWrapper = styled.div`
  width: 100%;
  height: 316px;
  background: #00000008;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubHeader = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: ${COLORS.Grey5};
`;
export const StyledButtonDiv = styled.div`
  float: right;
`;

export const customAddDocumentBtnStyles = {
  fontSize: '14px',
  padding: '0 45px',
  borderRadius: '4px',
  boxShadow: 'none',
};
