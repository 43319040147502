import { useTranslation, Trans } from 'react-i18next';
import { Flex } from 'antd';
import FaqItem from './components/FaqItem';
import ContactForm from './components/ContactForm';
import { StyledWrapper, StyledHeader, StyledContactUs } from './styles';

const Faq = () => {
  const { t } = useTranslation();
  const { t: tFaq, i18n: i18nFaq } = useTranslation('faq');

  const resourcesKeys = Object.keys(
    i18nFaq.getResourceBundle(i18nFaq.language, 'faq'),
  );

  return (
    <StyledWrapper>
      <StyledHeader>{t('frequently_asked_questions')}</StyledHeader>
      <Flex vertical gap={16}>
        {resourcesKeys.map((key: string) => (
          <FaqItem
            key={key}
            question={tFaq(`${key}.question`)}
            answer={
              <Trans
                i18nKey={`${key}.answer`}
                ns="faq"
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                components={{ ul: <ul />, li: <li />, a: <a />, br: <br /> }}
              />
            }
          />
        ))}
      </Flex>
      <StyledContactUs>{t('still_have_questions')}</StyledContactUs>
      <ContactForm />
    </StyledWrapper>
  );
};

export default Faq;
