import { getDocumentStatus, uppercaseWord } from 'utils';
import { Button, Flex, Space } from 'antd';
import {
  InfoCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  FilePdfFilled,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';
import i18n from 'utils/i18n';
import { TableHeader } from './styles';
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPES,
  IDocument,
} from '../../../services/document.types';
import { COLORS } from '../../../constants/colors';
import { formatDate, getDateRange } from '../../../utils/date/date-range';
import { ReactNode } from 'react';
import { TextBold, TextRegular } from '../../shared/Text';
import CustomTooltip from '../../shared/Tooltip';

export const statusIcons: { [key: string]: ReactNode } = {
  ACTIVE: <CheckCircleOutlined style={{ color: COLORS.SuccessBase }} />,
  EXPIRED: <CloseCircleOutlined style={{ color: COLORS.ErrorBase }} />,
  EXPIRE_SOON: <InfoCircleOutlined style={{ color: COLORS.TextBase }} />,
};
export const columns: (
  t: TFunction,
  onPreview: any,
  onDownload: any,
) => ColumnsType<IDocument> = (t, onPreview, onDownload) => [
  {
    title: (
      <TableHeader>
        <TextBold>{uppercaseWord(i18n.t('document_type'))}</TextBold>
      </TableHeader>
    ),
    dataIndex: 'type',
    render: (type) => (
      <>
        <span>{t(`document_types.${type.toUpperCase()}`)}</span>
        <FilePdfFilled
          style={{ color: COLORS.Red7, fontSize: 16, float: 'right' }}
          size={16}
        />
      </>
    ),
    filters: Object.keys(DOCUMENT_TYPES).map((type) => ({
      text: t(`document_types.${type.toUpperCase()}`),
      value: type,
    })),
    onFilter: (value, record) => record.type.toUpperCase() === value,
    width: '15%',
  },
  {
    title: (
      <TableHeader>
        <TextBold>{uppercaseWord(i18n.t('duration'))}</TextBold>
      </TableHeader>
    ),
    render: (record) =>
      getDateRange({ startDate: record.startDate, endDate: record.endDate }),
    sorter: (firstDocument, nextDocument) =>
      firstDocument.startDate.localeCompare(nextDocument.startDate),
    width: '15%',
  },
  {
    title: (
      <TableHeader>
        <TextBold>{uppercaseWord(i18n.t('signing_date'))}</TextBold>
      </TableHeader>
    ),
    render: (signingDate) => formatDate(signingDate),
    dataIndex: 'signingDate',
    width: '10%',
    sorter: (firstDocument, nextDocument) =>
      firstDocument.signingDate.localeCompare(nextDocument.signingDate),
  },
  {
    title: (
      <TableHeader>
        <TextBold>{uppercaseWord(i18n.t('status'))}</TextBold>
      </TableHeader>
    ),
    sorter: (firstDocument, nextDocument) => {
      const order = {
        [DOCUMENT_STATUS.ACTIVE]: 1,
        [DOCUMENT_STATUS.EXPIRE_SOON]: 2,
        [DOCUMENT_STATUS.EXPIRED]: 3,
      };
      const firstDocumentStatus = getDocumentStatus(firstDocument);
      const nextDocumentStatus = getDocumentStatus(nextDocument);
      return order[firstDocumentStatus] - order[nextDocumentStatus];
    },
    width: '5%',
    render: (record) => {
      const fileStatus = getDocumentStatus(record);
      return (
        <CustomTooltip
          withoutIcon={true}
          tooltip={<TextRegular>{t(fileStatus.toLowerCase())}</TextRegular>}
        >
          {statusIcons[fileStatus]}
        </CustomTooltip>
      );
    },
  },
  {
    title: (
      <TableHeader>
        <TextBold>{uppercaseWord(i18n.t('actions'))}</TextBold>
      </TableHeader>
    ),
    width: '5%',
    render: (record: IDocument) => (
      <Flex gap={6}>
        <Space>
          <Button
            onClick={() => onPreview(record)}
            size="small"
            icon={<EyeOutlined style={{ color: COLORS.Blue5 }} />}
          />
        </Space>
        <Space>
          <Button
            onClick={() => onDownload(record)}
            size="small"
            icon={<DownloadOutlined style={{ color: COLORS.Blue5 }} />}
          />
        </Space>
      </Flex>
    ),
  },
];
