import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormikProvider, useFormik } from 'formik';

import { Tooltip } from 'antd';

import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import { StyledTabWrapper } from '../../../Profile/styles';
import {
  StlyedProfileSupportText,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledContainer,
  StyledProfileSupportEmail,
} from '../ProfileDataTab/styles';
import PrmBody from './components/PrmBody';
import { initialValues } from './constants';
import { StyledAddPrmButton, StyledPlusIcon } from './styles';

const ProducerSettingsTab = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: prmData, isLoading: isLoadingPrm } = useGetPrmByUserIdQuery({
    userId: id,
  });
  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    onSubmit: async (values: typeof initialValues) => {},
  });

  useEffect(() => {
    if (Array.isArray(prmData)) {
      const prms = prmData?.map(({ prmValue, name, status, _id }) => {
        return {
          id: _id,
          prmValue,
          name,
          status,
        };
      });
      formik.setFieldValue('prms', prms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prmData]);

  return (
    <StyledTabWrapper>
      <StyledContainer>
        <StyledBlockWrapper>
          <StyledBlockHeader>
            <StyledBlockTitle>{t('prm_numbers')}</StyledBlockTitle>
            <Tooltip
              placement="top"
              color="white"
              title={
                <StlyedProfileSupportText>
                  {t('to_add_another_prm_please_contact_us_at')}
                  <StyledProfileSupportEmail href="mailto:support@oyo.ai">
                    support@oyo.ai
                  </StyledProfileSupportEmail>
                </StlyedProfileSupportText>
              }
            >
              <StyledAddPrmButton>
                <StyledPlusIcon /> {t('add_another_prm_number')}
              </StyledAddPrmButton>
            </Tooltip>
          </StyledBlockHeader>
          <FormikProvider value={formik}>
            <PrmBody isLoadingPrm={isLoadingPrm} />
          </FormikProvider>
        </StyledBlockWrapper>
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default ProducerSettingsTab;
