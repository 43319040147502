import * as yup from 'yup';

import { SIREN_LENGTH } from 'constants/global';

import {
  EXACT_PRM_NUMBER_LENGTH,
  EXACT_VAT_NUMBER_LENGTH,
  MAX_DISTANCE,
  MAX_POWER,
  MIN_DISTANCE,
  MIN_MEMBERS,
  MIN_POWER,
  MAX_FILE_SIZE,
  DOCUMENTS_ALLOWED_FORMATS,
  emailValidation,
  phoneValidation,
  postalCodeValidation,
  siretNumberValidation,
} from './constants';
import { CONSUMER_TYPE } from '../../constants/types';

export const getSignInSchema = (t: (key: string) => string) =>
  yup.object().shape({
    username: yup
      .string()
      .required(t('validation.email_required'))
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t('validation.email_format'),
      ),
    password: yup.string().required(t('validation.password_required')),
  });

export const getCreatePasswordSchema = (t: (key: string) => string) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('validation.password_required'))
      .matches(/\d+/, t('validation.password_at_least_one_number'))
      .matches(/[a-z]+/, t('validation.password_at_least_one_lowercase'))
      .matches(/[A-Z]+/, t('validation.password_at_least_one_uppercase'))
      .matches(/[!@#$%^&*()-+]+/, t('validation.password_at_least_one_special'))
      .min(8, t('validation.password_length')),
  });

export const getForgotPasswordSchema = (t: (key: string) => string) =>
  yup.object().shape({
    email: emailValidation(t, 'validation.email_required'),
  });

export const getCommunitySchema = (t: (key: string) => string) =>
  yup.object().shape({
    name: yup.string().required(t('validation.community_name_required')),
    address: yup.object().shape({
      country: yup.string(),
      postalCode: postalCodeValidation(t),
      city: yup.string().required(t('validation.address_is_required')),
      location: yup.object().shape({
        coordinates: yup
          .array()
          .of(
            yup
              .number()
              .nullable()
              .required(t('validation.community_location_required')),
          )
          .required(t('validation.please_fill_all_fields')),
      }),
    }),
    pmoOrgName: yup
      .string()
      .required(t('validation.organization_name_required')),
    sirenNumber: yup
      .number()
      .nullable()
      .required(t('validation.serial_number_required'))
      .test('is-enough-digits', t('validation.wrong_format'), (value) => {
        if (value) {
          const numberString = value.toString();
          return numberString.length === SIREN_LENGTH;
        }
        return false;
      }),
    pmo: yup.object().shape({
      firstName: yup
        .string()
        .required(t('validation.legal_representative_required')),
      lastName: yup
        .string()
        .required(t('validation.legal_representative_required')),
      middleName: yup.string(),
      email: emailValidation(t, 'validation.representative_email_required'),
      contactNumber: yup
        .number()
        .nullable()
        .phoneNumber()
        .required(t('validation.phone_number_required')),
      address: yup.object().shape({
        postalCode: postalCodeValidation(t),
        city: yup.string().required(t('validation.address_is_required')),
        country: yup.string(),
        street: yup.string().required(t('validation.address_is_required')),
        building: yup.string(),
      }),
    }),
    enedisAgreed: yup
      .boolean()
      .oneOf([true], t('validation.is_checkbox_signed'))
      .required('validation.is_checkbox_signed'),
    supportAgreed: yup
      .boolean()
      .oneOf([true], t('validation.is_checkbox_signed'))
      .required('validation.is_checkbox_signed'),
    maxConsumers: yup
      .number()
      .nullable()
      .required(t('validation.max_of_consumers_expected_required'))
      .min(MIN_MEMBERS, t('validation.wrong_format')),
    maxProducers: yup
      .number()
      .nullable()
      .required(t('validation.max_of_producers_expected_required'))
      .min(MIN_MEMBERS, t('validation.wrong_format')),
    maxDistance: yup
      .number()
      .nullable()
      .required(t('validation.perimeter_required'))
      .min(MIN_DISTANCE, t('validation.min_distance_must_be_greater_than'))
      .max(MAX_DISTANCE, t('validation.max_distance_must_be_lower_than')),
    maxPower: yup
      .number()
      .required(t('validation.max_power_of_production_required'))
      .min(MIN_POWER, t('validation.max_power_must_be_greater_than'))
      .max(MAX_POWER, t('validation.max_power_must_be_lower_than')),
    agreementId: yup
      .string()
      .required(t('validation.agreement_id_is_required'))
      .matches(/^ACC[0-9]{8}$/, t('validation.wrong_format')),
  });

export const getConsumerSchema = (
  t: (key: string) => string,
  isPrivateHouse: boolean,
) =>
  yup.object().shape({
    firstName: yup.string().required(t('validation.consumer_name_required')),
    lastName: yup.string().required(t('validation.consumer_name_required')),
    middleName: yup.string(),
    email: emailValidation(t, 'validation.legal_person_email_is_required'),
    contactNumber: phoneValidation(
      t,
      'validation.legal_person_phone_number_is_required',
    ).optional(),
    role: yup.string(),
    address: yup.object().shape({
      postalCode: postalCodeValidation(t),
      city: yup.string().required(t('validation.address_is_required')),
      country: yup.string(),
      street: yup.string().required(t('validation.address_is_required')),
      building: yup.string(),
      location: yup.object().shape({
        coordinates: yup.array().of(yup.number()),
      }),
    }),
    additionalInfo: yup.object().shape({
      type: yup.string(),
      siretNumber: isPrivateHouse
        ? yup.string().nullable()
        : siretNumberValidation(t),
      prms: yup.array().of(
        yup.object().shape({
          prmValue: yup
            .string()
            .required(t('validation.at_least_one_prm_number_required'))
            .test('is-14-digits', t('validation.wrong_format'), (value) => {
              if (value) {
                return value.length === EXACT_PRM_NUMBER_LENGTH;
              }
              return true;
            }),
          power: yup
            .number()
            .required(t('validation.subscribed_power_amount_required'))
            .min(0, t('validation.min_subscription_power_must_be_greater_than'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('validation.max_subscription_power_must_be_lower_than'),
            ),

          name: yup.string(),
          gridPrice: yup
            .object()
            .shape({
              dynamic: yup.object().shape({
                RH: yup.number().nullable(),
                HS_HH: yup.number().nullable(),
                HS_LH: yup.number().nullable(),
                LS_HH: yup.number().nullable(),
                LS_LH: yup.number().nullable(),
              }),
              static: yup.number().nullable(),
            })
            .optional(),
        }),
      ),
      position: yup.string(),
      billingAddress: yup.object().shape({
        postalCode: postalCodeValidation(t),
        city: yup.string().required(t('validation.address_is_required')),
        country: yup.string(),
        street: yup.string().required(t('validation.address_is_required')),
        building: yup.string(),
      }),
      legalPerson: yup.object().shape({
        firstName: isPrivateHouse
          ? yup.string()
          : yup.string().required(t('validation.legal_person_name_required')),
        lastName: isPrivateHouse
          ? yup.string()
          : yup.string().required(t('validation.legal_person_name_required')),
        middleName: yup.string(),
        email: emailValidation(t, 'validation.legal_person_email_is_required'),
        contactNumber: yup
          .number()
          .nullable()
          .phoneNumber()
          .required(t('validation.legal_person_phone_number_is_required')),
        position: isPrivateHouse
          ? yup.string()
          : yup
              .string()
              .required(t('validation.legal_person_position_required')),
      }),
      billingPerson: yup.object().shape({
        firstName: yup
          .string()
          .required(t('validation.billing_responsible_required')),
        lastName: yup
          .string()
          .required(t('validation.billing_responsible_required')),
        middleName: yup.string(),
        email: emailValidation(
          t,
          'validation.billing_responsible_email_address_is_required',
        ),
        contactNumber: yup
          .number()
          .nullable()
          .phoneNumber()
          .required(
            t('validation.billing_responsible_phone_number_is_required'),
          ),
      }),
      cppaSigned: yup
        .boolean()
        .oneOf(
          [true],
          t('validation.please_choose_the_consumer_type_to_proceed'),
        )
        .required('validation.please_choose_the_consumer_type_to_proceed'),
      agreementSigned: yup
        .boolean()
        .oneOf(
          [true],
          t('validation.please_choose_the_consumer_type_to_proceed'),
        )
        .required('validation.please_choose_the_consumer_type_to_proceed'),
      enedisConsentSigned: yup
        .boolean()
        .oneOf(
          [true],
          t('validation.please_accept_enedis_consent_form_is_signed'),
        )
        .required(t('validation.please_accept_enedis_consent_form_is_signed')),
    }),
  });

export const getProducerSchema = (t: (key: string) => string) =>
  yup.object().shape({
    firstName: yup.string().required(t('validation.producer_name_required')),
    lastName: yup.string().required(t('validation.producer_name_required')),
    middleName: yup.string(),
    contactNumber: yup.string(),
    email: yup.string(),
    role: yup.string(),

    address: yup.object().shape({
      city: yup.string().required(t('validation.address_is_required')),
      country: yup.string(),
      postalCode: postalCodeValidation(t),
      street: yup.string().required(t('validation.address_is_required')),
      building: yup.string(),
      location: yup.object().shape({
        coordinates: yup.array().of(yup.number()),
      }),
    }),

    additionalInfo: yup.object().shape({
      siretNumber: siretNumberValidation(t),
      vatNumber: yup
        .number()
        .required(t('validation.vat_number_required'))
        .test('is-11-digits', t('validation.wrong_format'), (value) => {
          if (value) {
            return value.toString().length === EXACT_VAT_NUMBER_LENGTH;
          }
          return true;
        }),
      sharedCapital: yup
        .string()
        .required(t('validation.shared_capital_required')),
      power: yup
        .number()
        .required(t('validation.production_power_is_required'))
        .min(
          MIN_POWER,
          t('validation.production_power_between_500_kw_and_3_mw'),
        )
        .max(
          MAX_POWER,
          t('validation.production_power_between_500_kw_and_3_mw'),
        ),
      billingAddress: yup.object().shape({
        city: yup.string().required(t('validation.address_is_required')),
        country: yup.string(),
        postalCode: postalCodeValidation(t),
        street: yup.string().required(t('validation.address_is_required')),
        building: yup.string(),
        location: yup.object().shape({
          coordinates: yup.array().of(yup.number()),
          type: yup.string(),
        }),
      }),
      billingPerson: yup.object().shape({
        firstName: yup
          .string()
          .required(t('validation.billing_responsible_required')),
        lastName: yup
          .string()
          .required(t('validation.billing_responsible_required')),
        middleName: yup.string(),
        email: emailValidation(
          t,
          'validation.billing_responsible_email_address_is_required',
        ),
        contactNumber: yup
          .number()
          .nullable()
          .phoneNumber()
          .required(
            t('validation.billing_responsible_phone_number_is_required'),
          ),
      }),
      legalPerson: yup.object().shape({
        firstName: yup
          .string()
          .required(t('validation.legal_person_name_required')),
        lastName: yup
          .string()
          .required(t('validation.legal_person_name_required')),
        middleName: yup.string(),
        email: emailValidation(t, 'validation.legal_person_email_is_required'),
        contactNumber: yup
          .number()
          .nullable()
          .phoneNumber()
          .required(t('validation.legal_person_phone_number_is_required')),
        position: yup
          .string()
          .required(t('validation.legal_person_position_required')),
      }),
      prms: yup.array().of(
        yup.object().shape({
          prmValue: yup
            .string()
            .required(t('validation.at_least_one_prm_number_required'))
            .test('is-14-digits', t('validation.wrong_format'), (value) => {
              if (value) {
                return value.length === EXACT_PRM_NUMBER_LENGTH;
              }
              return true;
            }),
        }),
      ),
      agreementSigned: yup
        .boolean()
        .oneOf([true], t('validation.pmo_membership_agreement_sign_required'))
        .required(t('validation.pmo_membership_agreement_sign_required')),
      licenseOperationSigned: yup
        .boolean()
        .oneOf([true], t('validation.license_of_operation_is_signed_required'))
        .required(t('validation.license_of_operation_is_signed_required')),
      consumptionAgreementSigned: yup
        .boolean()
        .oneOf(
          [true],
          t(
            'validation.collective_self-consumption_management_agreement_is_signed_required',
          ),
        )
        .required(
          t(
            'validation.collective_self-consumption_management_agreement_is_signed_required',
          ),
        ),
      enedisConsentSigned: yup
        .boolean()
        .oneOf([true], t('validation.enedis_consent_form_sign_required'))
        .required(t('validation.enedis_consent_form_sign_required')),
    }),
  });

export const editMemberSchema = (t: (key: string) => string) =>
  yup.object().shape({
    firstName: yup.string().required(t('validation.consumer_name_required')),
    lastName: yup.string().required(t('validation.consumer_name_required')),
    middleName: yup.string(),
    email: emailValidation(t, 'validation.legal_person_email_is_required'),
    contactNumber: phoneValidation(
      t,
      'validation.legal_person_phone_number_is_required',
    ).optional(),
    address: yup.object().shape({
      street: yup.string().required(t('validation.address_is_required')),
      location: yup.object().shape({
        coordinates: yup
          .array(yup.number())
          .required(t('validation.coordinates_is_required')),
      }),
    }),

    additionalInfo: yup.object().shape({
      siretNumber: siretNumberValidation(t),
      legalPerson: yup.object().shape({
        firstName: yup
          .string()
          .required(t('validation.legal_person_name_required')),
        lastName: yup
          .string()
          .required(t('validation.legal_person_name_required')),
        middleName: yup.string(),
        email: emailValidation(t, 'validation.legal_person_email_is_required'),
        contactNumber: yup
          .string()
          .required(t('validation.legal_person_phone_number_is_required')),
        position: yup
          .string()
          .required(t('validation.legal_person_position_required')),
      }),
    }),
  });

export const editConsumerSchema = (
  t: (key: string) => string,
  consumerType: CONSUMER_TYPE,
) => {
  if (consumerType === CONSUMER_TYPE.PRIVATE) {
    return yup.object().shape({
      firstName: yup.string().required(t('validation.consumer_name_required')),
      lastName: yup.string().required(t('validation.consumer_name_required')),
      middleName: yup.string(),
      email: emailValidation(t, 'validation.legal_person_email_is_required'),
      contactNumber: phoneValidation(
        t,
        'validation.legal_person_phone_number_is_required',
      ).optional(),
      address: yup.object().shape({
        street: yup.string().required(t('validation.address_is_required')),
        location: yup.object().shape({
          coordinates: yup
            .array(yup.number())
            .required(t('validation.coordinates_is_required')),
        }),
      }),
    });
  }

  return yup.object().shape({
    firstName: yup.string().required(t('validation.consumer_name_required')),
    lastName: yup.string().required(t('validation.consumer_name_required')),
    middleName: yup.string(),
    email: emailValidation(t, 'validation.legal_person_email_is_required'),
    contactNumber: phoneValidation(
      t,
      'validation.legal_person_phone_number_is_required',
    ).optional(),
    address: yup.object().shape({
      street: yup.string().required(t('validation.address_is_required')),
      location: yup.object().shape({
        coordinates: yup
          .array(yup.number())
          .required(t('validation.coordinates_is_required')),
      }),
    }),

    additionalInfo: yup.object().shape({
      siretNumber: siretNumberValidation(t),
      legalPerson: yup.object().shape({
        firstName: yup
          .string()
          .required(t('validation.legal_person_name_required')),
        lastName: yup
          .string()
          .required(t('validation.legal_person_name_required')),
        middleName: yup.string(),
        email: emailValidation(t, 'validation.legal_person_email_is_required'),
        contactNumber: yup
          .string()
          .required(t('validation.legal_person_phone_number_is_required')),
        position: yup
          .string()
          .required(t('validation.legal_person_position_required')),
      }),
    }),
  });
};

export const billingMemberSchema = (t: (key: string) => string) =>
  yup.object().shape({
    additionalInfo: yup.object().shape({
      billingPerson: yup.object().shape({
        firstName: yup
          .string()
          .required(t('validation.billing_responsible_required')),
        lastName: yup
          .string()
          .required(t('validation.billing_responsible_required')),
        middleName: yup.string(),
        email: emailValidation(
          t,
          'validation.billing_responsible_email_address_is_required',
        ),
        contactNumber: yup
          .string()
          .required(
            t('validation.billing_responsible_phone_number_is_required'),
          ),
      }),
      billingAddress: yup.object().shape({
        street: yup.string().required(t('validation.address_is_required')),
      }),
    }),
  });

export const editAdminSchema = (t: (key: string) => string) =>
  yup.object().shape({
    firstName: yup.string().required(t('validation.consumer_name_required')),
    lastName: yup.string().required(t('validation.consumer_name_required')),
    middleName: yup.string(),
    email: emailValidation(t, 'validation.legal_person_email_is_required'),
    contactNumber: phoneValidation(
      t,
      'validation.legal_person_phone_number_is_required',
    ),
    address: yup.object().shape({
      street: yup.string().required(t('validation.address_is_required')),
    }),
  });

export const editPmoSchema = (t: (key: string) => string) =>
  yup.object().shape({
    pmoOrgName: yup
      .string()
      .required(t('validation.organization_name_required')),
    firstName: yup.string().required(t('validation.consumer_name_required')),
    lastName: yup.string().required(t('validation.consumer_name_required')),
    middleName: yup.string(),
    email: emailValidation(t, 'validation.legal_person_email_is_required'),
    contactNumber: phoneValidation(
      t,
      'validation.legal_person_phone_number_is_required',
    ),
  });
export const getUploadDocumentSchema = (
  t: (key: string, options?: any) => string,
) => {
  return yup.object().shape({
    type: yup.string().required(t('validation.document_type_required')),
    startDate: yup
      .date()
      .required(t('validation.document_start_date_required')),
    endDate: yup
      .date()
      .required(t('validation.document_end_date_required'))
      .when('startDate', ([startDate]): any => {
        if (startDate) {
          return yup
            .date()
            .required(t('validation.document_end_date_required'))
            .min(startDate, t('validation.end_date_must_be_after_start_date'));
        }
      }),
    signingDate: yup
      .date()
      .required(t('validation.document_signing_date_required'))
      .when('endDate', ([endDate]): any => {
        if (endDate) {
          return yup
            .date()
            .required(t('validation.document_signing_date_required'))
            .max(endDate, t('validation.signing_date_must_be_before_end_date'));
        }
      }),
    file: yup
      .mixed()
      .required(t('validation.document_file_required'))
      .test(
        'fileFormat',
        t('validation.only_types_files_are_allowed', {
          types: DOCUMENTS_ALLOWED_FORMATS.join(', ').toUpperCase(),
        }),
        (value: any) => {
          if (value && value.name) {
            const valueName: string = value.name.split('.').pop();
            return DOCUMENTS_ALLOWED_FORMATS.includes(valueName);
          }
          return true;
        },
      )
      .test(
        'fileSize',
        `${t('validation.file_size_must_be_less_than')} ${MAX_FILE_SIZE} MB`,
        (value: any) => {
          if (value) {
            return value.size <= MAX_FILE_SIZE * 1024 * 1024;
          }
          return true;
        },
      ),
  });
};
