import React, { useCallback, useMemo, useReducer } from 'react';
import { TableWrapper } from './styles';
import type {
  IDocument,
  IDocumentsTable,
} from '../../../services/document.types';
import { Table } from 'antd';
import { columns } from './columns';
import { t } from 'i18next';
import {
  fetchAndDownloadPdfFile,
  fetchViewerPdfFile,
} from '../../communities/Dashboard/utils';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
export const PAGE_SIZE = 10;
enum ACTION_TYPES {
  SET_FILTERED_DATA = 'SET_FILTERED_DATA',
  SET_PAGINATION = 'SET_PAGINATION',
}
const initialState = {
  filteredData: [] as IDocument[],
  tablePagination: {
    current: 1,
    pageSize: PAGE_SIZE,
  } as TablePaginationConfig,
};
type State = typeof initialState;
type Action =
  | { type: ACTION_TYPES.SET_FILTERED_DATA; payload: IDocument[] }
  | { type: ACTION_TYPES.SET_PAGINATION; payload: TablePaginationConfig };
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPES.SET_FILTERED_DATA:
      return { ...state, filteredData: action.payload };
    case ACTION_TYPES.SET_PAGINATION:
      return { ...state, tablePagination: action.payload };
    default:
      return state;
  }
};
const DocumentsTable = ({ documents, isLoadingDocuments }: IDocumentsTable) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayPagination = useMemo(() => {
    return (
      !isLoadingDocuments &&
      documents &&
      documents.length > 10 &&
      (state.filteredData.length > 10 || state.filteredData.length === 0)
    );
  }, [state.filteredData, documents, isLoadingDocuments]);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
      extra: TableCurrentDataSource<IDocument>,
    ) => {
      dispatch({
        type: ACTION_TYPES.SET_FILTERED_DATA,
        payload: extra.currentDataSource,
      });
    },
    [],
  );

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      dispatch({
        type: ACTION_TYPES.SET_PAGINATION,
        payload: { current: page, pageSize },
      });
    },
    [],
  );

  const onDownload = useCallback(async (record: IDocument) => {
    await fetchAndDownloadPdfFile(record.src, record.startDate, record.type);
  }, []);

  const onPreview = useCallback(async (record: IDocument) => {
    const blobUrl: string | null = await fetchViewerPdfFile(
      record.src,
      record.startDate,
    );
    if (blobUrl) {
      window.open(blobUrl);
    }
  }, []);
  const memoizedColumns = useMemo(
    () => columns(t, onPreview, onDownload),
    [onPreview, onDownload],
  );
  return (
    <TableWrapper>
      <Table
        columns={memoizedColumns}
        rowKey={(record) =>
          record._id + record.type + record.startDate + record.endDate
        }
        dataSource={documents}
        onChange={handleTableChange}
        pagination={
          displayPagination
            ? {
                ...state.tablePagination,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: handlePaginationChange,
                showTotal: (total) => `${t('total')} ${total} ${t('items')}`,
              }
            : false
        }
      />
    </TableWrapper>
  );
};
export default DocumentsTable;
