import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardDeliveredBody,
  CardHeader,
  CardMedium,
  ChartTitle,
} from 'components/communities/styles';

import AreaChart from './components/AreaChart';
import RingProgressInfo from './components/RingProgressInfo';

type RepartitionKeyDataType = {
  date: string;
  value: number | null;
};
interface RepartitionKeyProps {
  data: RepartitionKeyDataType[] | undefined;
  percent: number | undefined;
  isLoading?: boolean;
}

const RepartitionKey: FC<RepartitionKeyProps> = ({
  data,
  percent,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CardMedium className="onboarding-step-6" height={366}>
      <CardHeader>
        <ChartTitle>{t('repartition_key')}</ChartTitle>
      </CardHeader>
      <CardDeliveredBody verticalDirection="start">
        <AreaChart isLoading={isLoading} data={data} />
        <RingProgressInfo isLoading={isLoading} percent={percent} />
      </CardDeliveredBody>
    </CardMedium>
  );
};

export default RepartitionKey;
