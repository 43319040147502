import { COLORS } from 'constants/colors';

export const styles = {
  options: {
    arrowColor: COLORS.Green8,
    backgroundColor: COLORS.Green8,
    overlayColor: 'rgba(0, 0, 0, 0.4)',
    primaryColor: '#000',
    textColor: '#fff',
    zIndex: 100,
    width: 300,
  },
  tooltipTitle: {
    color: COLORS.Green4,
    textAlign: 'left' as const,
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'uppercase' as const,
  },
  buttonClose: {
    display: 'none',
    fontSize: 14,
    fontWeight: 400,
  },
  buttonNext: {
    backgroundColor: COLORS.Green4,
    color: '#000',
    outline: 'none',
    fontSize: 14,
    fontWeight: 400,
  },
  buttonSkip: {
    outline: 'none',
    border: `1px solid ${COLORS.ColorBorder}`,
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: '#000',
    marginRight: 8,
    borderColor: COLORS.ColorBorder,
    padding: '7px 8px',
  },
  tooltipContent: {
    padding: 0,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    textAlign: 'left' as const,
  },
  tooltipFooterSpacer: {
    flex: '0 1 120px',
  },
};
