import React from 'react';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadFileSvg } from './UploadFileSvg';
import {
  StyledUploadFileTypeAndMaxSize,
  StyledUploadHint,
  StyledUploadIcon,
  StyledUploadText,
} from './style';
import { StyledInputError } from '../InputGroup/styles';
import { ErrorConfirmPasswordIcon } from '../PasswordForm/styles';
import { COLORS } from '../../../constants/colors';

const { Dragger } = Upload;
interface FileUploadProps {
  uploaderProps: UploadProps;
  maxFileSize: number;
  errors?: string;
  style?: {
    borderColor: string;
    backgroundColor: string;
  };
}
const FileUpload: React.FC<FileUploadProps> = ({
  uploaderProps,
  maxFileSize,
  errors,
}) => {
  const { t } = useTranslation();
  uploaderProps.style = {
    ...uploaderProps.style,
    borderColor: errors ? COLORS.ErrorBase : COLORS.DraggerInitialBorderColor,
  };

  return (
    <>
      <Dragger {...uploaderProps}>
        <StyledUploadIcon className="ant-upload-drag-icon">
          <UploadFileSvg />
        </StyledUploadIcon>
        <StyledUploadText className="ant-upload-text">
          {t('choose_file')}
        </StyledUploadText>
        <StyledUploadHint className="ant-upload-hint">
          {t('or_drag_and_drop')}
        </StyledUploadHint>
      </Dragger>
      <StyledUploadFileTypeAndMaxSize>
        {uploaderProps.accept ? (
          <span>
            {t('supported_format')}: {uploaderProps.accept.toLowerCase()}
          </span>
        ) : (
          ''
        )}
        {maxFileSize ? (
          <span>
            {t('max_size')} {maxFileSize} MB
          </span>
        ) : (
          ''
        )}
      </StyledUploadFileTypeAndMaxSize>
      {errors && (
        <StyledInputError errorCentered={false}>
          {errors ? (
            <>
              <ErrorConfirmPasswordIcon />
              {errors}
            </>
          ) : (
            '\u00A0'
          )}
        </StyledInputError>
      )}
    </>
  );
};

export default FileUpload;
