import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import GenericFormName from 'components/communities/components/GenericFormName';
import {
  DetailCardLabel,
  StatusCardInfoIcon,
} from 'components/communities/Dashboard/components/Profile/components/Card/styles';
import { LegalPerson } from 'services/types';

interface UserEditFormData {
  isAdminOrPMO: boolean;
  personCoordinates: string | null;
  name: string;
  siretNumber?: string | null;
  location?: string;
  legalPersonName?: string;
  legalPerson?: LegalPerson;
}

export const useUserEditFormData = ({
  isAdminOrPMO,
  personCoordinates,
  name,
  siretNumber,
  location,
  legalPersonName,
  legalPerson,
}: UserEditFormData) => {
  const { t } = useTranslation();

  return {
    consumer_data: [
      {
        id: uuidv4(),
        label: 'producer_name',
        key: '',
        value: name,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'siret_number',
        key: 'additionalInfo.siretNumber',
        value: siretNumber,
        additionalBottomComponent: <DetailCardLabel width="100%" />,
      },
      {
        id: uuidv4(),
        label: 'location',
        key: 'address.street',
        value: location,
      },
      {
        id: uuidv4(),
        label: 'coordinates',
        key: 'address.location.coordinates',
        value: personCoordinates,
      },
    ],
    legal_representative: [
      {
        id: uuidv4(),
        label: 'legal_person_name',
        key: 'additionalInfo.legalPerson',
        value: legalPersonName,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'legal_person_email_address',
        key: 'additionalInfo.legalPerson.email',
        value: legalPerson?.email,
        disabled: !isAdminOrPMO,
        additionalBottomComponent: !isAdminOrPMO && (
          <DetailCardLabel width="100%">
            <StatusCardInfoIcon />
            {t('legal_person_email_change_info')}
          </DetailCardLabel>
        ),
      },
      {
        id: uuidv4(),
        label: 'legal_person_position',
        key: 'additionalInfo.legalPerson.position',
        value: legalPerson?.position,
      },
      {
        id: uuidv4(),
        label: 'legal_person_phone_number',
        key: 'additionalInfo.legalPerson.contactNumber',
        value: legalPerson?.contactNumber,
      },
    ],
  };
};
