import React, { FC, useState } from 'react';

import { StyledInfoKw } from 'components/communities/Charts/ElectricityConsumption/styles';
import EnergyIcon from 'components/communities/Charts/SummaryView/components/EnergyIcon';
import {
  NotAvailableValue,
  WidgetWrapper,
} from 'components/communities/styles';
import CommunityCardChart from 'components/shared/PieChart';
import Popover from 'components/shared/Popover';
import { LegendText } from 'components/shared/Text/LegendText';
import { COLORS } from 'constants/colors';
import { EnergyTypes } from 'constants/energy-types';

import { popoverOverlayStyles } from '../../../Communities/styles';
import { WidgetAmount, WidgetTitle } from '../../ConsumerSummaryView/styles';
import { ChartCardLineItem } from '../../SummaryView/components/Card/styles';
import {
  PieChartContainer,
  WidgeRatioTitlesRow,
  WidgetChartPercent,
  WidgetRatioRow,
  WidgetRatioTitle,
} from './styles';

const INNER_RADIUS = 0.6;
const MAX_CHART_PERCENT = 100;
const SKELETON_RADIUS = 99;

interface WidgetRatioProps {
  title: string;
  tooltipText: string;
  firstEnergyTitle: string;
  secondEnergyTitle: string;
  percent?: number;
  chartColors: COLORS[];
  isLoading?: boolean;
  wrapperClassName?: string;
}

const WidgetRatio: FC<WidgetRatioProps> = ({
  title,
  tooltipText,
  firstEnergyTitle,
  secondEnergyTitle,
  percent,
  chartColors,
  isLoading,
  wrapperClassName,
}) => {
  const [hoveredType, setHoveredType] = useState(EnergyTypes.NONE);
  const hasValue = Boolean(percent);
  const isSelfConsumptionHighlighted =
    hasValue && hoveredType === EnergyTypes.SELF_CONSUMPTION;
  const isCommunitySelfConsumptionHighlighted =
    hasValue && hoveredType === EnergyTypes.COMMUNITY_SELF_CONSUMPTION;

  return (
    <WidgetWrapper className={wrapperClassName}>
      <WidgetRatioTitle>
        <WidgetTitle>{title}</WidgetTitle>
        <Popover context={tooltipText} overlayStyles={popoverOverlayStyles} />
      </WidgetRatioTitle>
      <WidgetRatioRow>
        <WidgeRatioTitlesRow>
          <ChartCardLineItem>
            <EnergyIcon color={chartColors[0]} />
            <LegendText bold={isSelfConsumptionHighlighted}>
              {firstEnergyTitle}
            </LegendText>
          </ChartCardLineItem>
          <ChartCardLineItem>
            <EnergyIcon color={chartColors[1]} />
            <LegendText bold={isCommunitySelfConsumptionHighlighted}>
              {secondEnergyTitle}
            </LegendText>
          </ChartCardLineItem>
        </WidgeRatioTitlesRow>
        <PieChartContainer>
          <WidgetChartPercent>
            {percent || percent === 0 ? (
              <WidgetAmount>
                {percent}
                <StyledInfoKw>%</StyledInfoKw>
              </WidgetAmount>
            ) : (
              <NotAvailableValue>N/A</NotAvailableValue>
            )}
          </WidgetChartPercent>
          <CommunityCardChart
            innerRadius={INNER_RADIUS}
            color={percent ? chartColors : [COLORS.Grey3]}
            chartData={[
              {
                type: EnergyTypes.SELF_CONSUMPTION,
                value: percent || 0,
              },
              {
                type: EnergyTypes.COMMUNITY_SELF_CONSUMPTION,
                value: percent ? MAX_CHART_PERCENT - percent : 0,
              },
            ]}
            handleOnHover={setHoveredType}
            handleOnBlur={() => setHoveredType(EnergyTypes.NONE)}
            isLoading={isLoading}
            skeletonRadius={SKELETON_RADIUS}
          />
        </PieChartContainer>
      </WidgetRatioRow>
    </WidgetWrapper>
  );
};

export default WidgetRatio;
