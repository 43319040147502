import { styled } from 'styled-components';
import { Button, Input, Select } from 'antd';
import {
  DeleteTwoTone,
  CheckCircleFilled,
  CheckCircleTwoTone,
  CloseCircleFilled,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { COLORS } from 'constants/colors';
import { SubHeader } from 'components/shared/Text';

export const StyledFormRow = styled.div`
  padding: 0 16px;
`;

export const StyledGridFormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 0 16px;
`;

export const StyledGridPriceTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 16px 0;
  span {
    font-weight: 400;
    color: #595959;
  }
`;

export const UploadDocumentBtn = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const StyledDocContainer = styled.div`
  background: ${COLORS.Green3};
  padding: 16px;
  margin-bottom: 20px;
`;

export const StyledDocTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDocTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const StyledDeleteIcon = styled(DeleteTwoTone).attrs(() => ({
  twoToneColor: COLORS.ErrorBorder,
}))`
  cursor: pointer;
  svg > path:first-child {
    fill: ${COLORS.Grey1};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmailInput = styled(Input)`
  width: 324px;
  height: 40px;
  margin-top: 2px;
`;

export const DocumentTypeSelect = styled(Select)`
  width: 324px;
  height: 40px;
  margin-top: 2px;
`;
export const PasswordInput = styled(Input.Password)`
  input {
    width: 324px;
    height: 32px;
  }
  margin-top: 2px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const ValidationList = styled.div`
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-bottom: 10px;
`;

export const CheckMarkUnselected = styled(CheckCircleFilled)`
  font-size: 16px;
  color: ${COLORS.Grey1};
  margin-right: 10px;
`;

export const CheckMarkSelected = styled(CheckCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green7,
}))`
  font-size: 16px;
  margin-right: 10px;
`;

export const ErrorValidationIcon = styled(CloseCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.ErrorBase,
}))`
  font-size: 14px;
  margin-right: 10px;
`;

export const ErrorConfirmPasswordIcon = styled(CloseCircleFilled)`
  font-size: 12px;
  color: ${COLORS.ErrorBase};
  margin-right: 4px;
`;

export const ValidationItem = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  min-height: 20px;
`;

export const FieldsWithValidation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledDurationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 12;
  gap: 20px;
  align-items: flex-start;
`;

export const StyledCommunityTitle = styled(SubHeader)<{
  isUppercase?: boolean;
}>`
  width: 100%;
  text-align: left;
  margin: 20px 0;
  color: ${COLORS.TextBase};
  text-transform: ${(props) => props.isUppercase && 'uppercase'};
`;

export const StyledDateWrapper = styled.div`
  display: flex;
  flex: 6;
`;

export const StyledDropDownWrapper = styled.div`
  flex: 3;
  height: 40px;
`;

export const StyledSelect = styled(Select)<{
  isError?: boolean;
}>`
  width: 100%;
  height: 100%;
  & .ant-select-selector {
    height: 40px !important;
  }
  & .ant-select-selection-item {
    line-height: 40px !important;
  }
  & .ant-select-selector {
    border-color: ${(props) => props.isError && COLORS.ErrorBase}!important;
  }
`;
