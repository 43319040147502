import React, { FC } from 'react';

import { LABELS } from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import { ChartTitle } from 'components/communities/styles';
import { CardHeader } from 'components/communities/styles';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import { CustomSelectItem } from 'constants/types';

import AreaChart from './components/AreaChart';
import ElectricityConsumptionInfo from './components/ElectricityConsumptionInfo';
import { Card, CardBody } from './styles';
import { InfoItemsType, LegendItemsType } from './types';

type AreaChartDataType = {
  value: number;
  date: number | string;
  category: string;
};

export type DataInfo = {
  title: LABELS;
  power?: number | string;
  percent?: number;
};

interface ElectricityConsumptionProps {
  isConsumer?: boolean;
  title: string;
  legendItems: LegendItemsType[];
  infoItems: InfoItemsType[];
  areaColors: string[];
  lineColors: string[];
  total?: number | string;
  selfConsumption?: number | string;
  gridConsumption?: number | null | string;
  overproduction?: number | string;
  electricityConsumption?: AreaChartDataType[];
  dataInfo?: DataInfo[];
  selectItems?: CustomSelectItem[];
  selectValue?: CustomSelectItem;
  onChangeSelectValue?: (item: CustomSelectItem) => void;
  isLoading?: boolean;
}

const ElectricityConsumption: FC<ElectricityConsumptionProps> = ({
  isConsumer,
  title,
  legendItems,
  infoItems,
  lineColors,
  areaColors,
  total,
  selfConsumption,
  gridConsumption,
  overproduction,
  dataInfo,
  electricityConsumption,
  selectItems,
  selectValue,
  onChangeSelectValue,
  isLoading,
}) => {
  const chartData: AreaChartDataType[] | undefined =
    electricityConsumption && electricityConsumption.length
      ? electricityConsumption
      : undefined;

  const defaultInfo: DataInfo[] | undefined = [
    {
      title: LABELS.TOTAL_CONSUMPTION,
      power: total,
    },
    {
      title: LABELS.SELF_CONSUMPTION,
      power: selfConsumption,
    },
    ...(gridConsumption === null
      ? []
      : [
          {
            title: LABELS.GRID_CONSUMPTION,
            power: gridConsumption,
          },
        ]),
    {
      title: LABELS.OVERPRODUCTION,
      power: overproduction && Math.abs(+overproduction),
    },
  ];

  return (
    <Card className="onboarding-step-5">
      <CardHeader>
        <ChartTitle>{title}</ChartTitle>
        {selectItems && selectValue && (
          <CustomSelect
            width={60}
            items={selectItems}
            onChange={onChangeSelectValue}
            value={selectValue}
            withNoBorder={true}
          />
        )}
      </CardHeader>
      <CardBody>
        <ElectricityConsumptionInfo
          isConsumer={isConsumer}
          data={dataInfo || defaultInfo}
          infoItems={infoItems}
          isLoading={isLoading}
        />
        <AreaChart
          areaColors={areaColors}
          lineColors={lineColors}
          legendItems={legendItems}
          data={chartData}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};

export default React.memo(ElectricityConsumption);
