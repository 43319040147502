import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import {
  MapWrapper,
  StyledCommunityTitle,
} from 'components/communities/CreateCommunity/setup-community/styles';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import MapboxMap from 'components/shared/MapboxMap';
import { MarginTop24px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';
import { BESANCON_COORDINATES, SIRET_LENGTH } from 'constants/global';

import { useAppSelector } from 'utils/hooks';
import { regexOnlyNumbers } from 'utils/validation/constants';
import { ConsumerType } from '../ConsumerType/constants';
import BillingFullName from '../ContactInfo/BillingInfo/BillingFullName';
import { initialValues } from '../constants';
import ConsumerAddressForm from './ConsumerAddress';
import PrmNumber from './PrmNumber';
import { StyledSwitch, SwitchWrapper } from './styles';
import { validatePrmsValues } from './utils';

interface ConsumerDetailsProps {
  onConfirmForm: (isValid: boolean) => void;
  areValidFields: (fields: string[]) => boolean;
  validateFields: (fields: string[]) => Promise<boolean>;
  onBackButtonClick: () => void;
  hasToBeValidated: boolean;
}

const currentFields = [
  'firstName',
  'lastName',
  'address.city',
  'address.postalCode',
  'address.street',
  'additionalInfo.prms',
];

const consumerNameValues = {
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  email: '',
  phoneNumber: '',
};

const ConsumerDetails: React.FC<ConsumerDetailsProps> = ({
  hasToBeValidated,
  onConfirmForm,
  areValidFields,
  validateFields,
  onBackButtonClick,
}) => {
  const { consumerType } = useAppSelector((state) => state.consumer);

  const { t } = useTranslation();

  const { errors, values, setSubmitting, setFieldError, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const [coordinates, setMapCoordinates] = useState([
    BESANCON_COORDINATES.latitude,
    BESANCON_COORDINATES.longitude,
  ]);

  const siretNumberField =
    consumerType !== ConsumerType.privateHouse
      ? ['additionalInfo.siretNumber']
      : [];

  const validatedFields = [...currentFields, ...siretNumberField];

  const isFieldDataValid = areValidFields(validatedFields);

  const onConfirmClick = async () => {
    setSubmitting(true);
    await setValidPrmValues();
    const isValid = await validateFields(validatedFields);

    setSubmitting(false);
    onConfirmForm(isValid);
    if (
      consumerType !== ConsumerType.privateHouse &&
      !values.additionalInfo.siretNumber
    ) {
      if (String(values.additionalInfo.siretNumber).length === SIRET_LENGTH) {
        setFieldError(
          'additionalInfo.siretNumber',
          t('validation.wrong_format').toString(),
        );
      } else {
        setFieldError(
          'additionalInfo.siretNumber',
          t('validation.siret_number_required').toString(),
        );
      }
    }
  };

  const setValidPrmValues = async () => {
    const prms = [...values.additionalInfo.prms];
    const validatedPrms = validatePrmsValues(prms);

    await setFieldValue('additionalInfo.prms', validatedPrms);
  };

  const setFieldsCoordinates = async (coordinates: number[]) => {
    const [latitude, longitude] = coordinates;

    await setFieldValue('address.location.coordinates.[0]', latitude);
    await setFieldValue('address.location.coordinates.[1]', longitude);
  };

  const handleOnDragDropEnd = (coordinates: number[]) => {
    setMapCoordinates(coordinates);
    setFieldsCoordinates(coordinates);
  };

  useEffect(() => {
    const setCoordinates = async () => {
      await setFieldValue(
        'address.location.coordinates.[0]',
        BESANCON_COORDINATES.latitude,
      );
      await setFieldValue(
        'address.location.coordinates.[1]',
        BESANCON_COORDINATES.longitude,
      );
    };
    setCoordinates();
  }, [setFieldValue]);

  return (
    <StyledForm>
      <BillingFullName
        label={t('consumer_name').toString()}
        values={consumerNameValues}
      />

      {consumerType !== ConsumerType.privateHouse && (
        <>
          <InputGroup
            label={t('siret_number').toString()}
            error={errors?.additionalInfo?.siretNumber}
          >
            <FormikInput
              fieldName="additionalInfo.siretNumber"
              placeholder="Ex. 123 568 941 00056"
              type="string"
              onChange={(e) => {
                if (
                  e.target.value === '' ||
                  regexOnlyNumbers.test(e.target.value)
                ) {
                  setFieldValue('additionalInfo.siretNumber', e.target.value);
                }
              }}
            />
          </InputGroup>
          <SwitchWrapper>
            <StyledSwitch
              checked={values.additionalInfo.hasSpecialTariffs}
              onChange={() =>
                setFieldValue(
                  'additionalInfo.hasSpecialTariffs',
                  !values.additionalInfo.hasSpecialTariffs,
                )
              }
            />
            <StyledCommunityTitle>{t('special_tariffs')}</StyledCommunityTitle>
          </SwitchWrapper>
        </>
      )}

      <ConsumerAddressForm setCoordinates={handleOnDragDropEnd} />

      <MapWrapper>
        <StyledCommunityTitle>{t('adjust_map_marker')}</StyledCommunityTitle>
        <MapboxMap
          coordinates={coordinates}
          onDragDropEnd={handleOnDragDropEnd}
        />
      </MapWrapper>

      <PrmNumber />
      <MarginTop24px />
      <BtnPrimary
        onClick={onConfirmClick}
        isDisabled={hasToBeValidated && !isFieldDataValid}
      >
        {t('proceed')}
      </BtnPrimary>
      <MarginTop24px />

      <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
        {t('back')}
      </BtnSecondary>
    </StyledForm>
  );
};

export default ConsumerDetails;
