import dayjs from 'dayjs';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

import {
  FileIcon,
  StyledCalendarIcon,
  StyledHistoricalPriceConatiner,
  StyledHistoricalPriceLeftCol,
  StyledHistoricalPriceRow,
  StyledHistoricalPriceWrapper,
  StyledHistoricalPricesContainer,
  StyledIndexIcon,
  StyledPriceCol,
  StyledPriceContainer,
  StyledPriceValue,
  StyledPricesTitle,
  StyledPricesTitleContainer,
  customTooltipStyles,
  StyledContractInfoCol,
  StyledUpcomingPriceInfoCol,
  StyledUpcomingPriceItemCol,
} from './styles';

interface HistoricalPricesProps {
  prmPrices: any[];
  isShown: boolean;
}

const HistoricalPrices: FC<HistoricalPricesProps> = ({
  prmPrices,
  isShown,
}) => {
  const { t } = useTranslation();

  return (
    <StyledHistoricalPricesContainer isShown={isShown}>
      <StyledHistoricalPriceRow>
        <StyledContractInfoCol>
          <StyledPricesTitleContainer>
            <StyledPricesTitle>{t('historical_prices')}</StyledPricesTitle>
            <CustomTooltip
              tooltip={
                <TextRegular>{t('historical_prices_tooltip')}</TextRegular>
              }
              iconSize={16}
              wrapperStyles={customTooltipStyles}
              containerStyles={customTooltipStyles}
            />
          </StyledPricesTitleContainer>
        </StyledContractInfoCol>
      </StyledHistoricalPriceRow>
      {Object.keys(prmPrices).map((cppa: any) => {
        const prmPrice = prmPrices[cppa.toString()];
        return (
          <StyledHistoricalPriceRow>
            <StyledHistoricalPriceLeftCol>
              <div>
                <FileIcon />
                <span>{cppa}</span>
              </div>
              <div>
                <StyledCalendarIcon />
                <span>
                  {`${dayjs(prmPrice.startDate).format('DD.MM.YYYY')} - ${dayjs(
                    prmPrice.endDate,
                  ).format('DD.MM.YYYY')}`}
                </span>
              </div>
            </StyledHistoricalPriceLeftCol>
            <StyledHistoricalPriceWrapper>
              {prmPrice.periods?.map(
                ({ scheduleTypes, startDate, endDate, index }: any) => (
                  <StyledHistoricalPriceConatiner key={index}>
                    <StyledUpcomingPriceInfoCol>
                      <div>
                        <StyledCalendarIcon />
                        <span>
                          {`${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
                            endDate,
                          ).format('DD.MM.YYYY')}`}
                        </span>
                      </div>
                      <div>
                        <StyledIndexIcon />
                        <span>{index}</span>
                      </div>
                    </StyledUpcomingPriceInfoCol>
                    <StyledUpcomingPriceItemCol>
                      <StyledPriceContainer>
                        <StyledPriceValue>
                          {scheduleTypes.HS_HH}
                        </StyledPriceValue>
                      </StyledPriceContainer>
                    </StyledUpcomingPriceItemCol>
                    <StyledUpcomingPriceItemCol>
                      <StyledPriceContainer>
                        <StyledPriceValue>
                          {scheduleTypes.HS_LH}
                        </StyledPriceValue>
                      </StyledPriceContainer>
                    </StyledUpcomingPriceItemCol>
                    <StyledUpcomingPriceItemCol>
                      <StyledPriceContainer>
                        <StyledPriceValue>
                          {scheduleTypes.LS_HH}
                        </StyledPriceValue>
                      </StyledPriceContainer>
                    </StyledUpcomingPriceItemCol>
                    <StyledUpcomingPriceItemCol>
                      <StyledPriceContainer>
                        <StyledPriceValue>
                          {scheduleTypes.LS_LH}
                        </StyledPriceValue>
                      </StyledPriceContainer>
                    </StyledUpcomingPriceItemCol>
                    <StyledUpcomingPriceItemCol>
                      <StyledPriceContainer>
                        <StyledPriceValue>{scheduleTypes.RH}</StyledPriceValue>
                      </StyledPriceContainer>
                    </StyledUpcomingPriceItemCol>
                    <StyledPriceCol width="5%" />
                  </StyledHistoricalPriceConatiner>
                ),
              )}
            </StyledHistoricalPriceWrapper>
          </StyledHistoricalPriceRow>
        );
      })}
    </StyledHistoricalPricesContainer>
  );
};

export default HistoricalPrices;
