import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import { StyledCommunityTitle } from 'components/communities/CreateCommunity/setup-community/styles';
import BillingFullName from 'components/members/createConsumer/ContactInfo/BillingInfo/BillingFullName';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import MapboxMap from 'components/shared/MapboxMap';
import { MarginTop24px, MarginTop40px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';
import { BESANCON_COORDINATES } from 'constants/global';
import { regexOnlyNumbers } from 'utils/validation/constants';

import { initialValues } from '../constants';
import ProducerAddressForm from './ProducerAddressForm';

interface ProducerDetailsProps {
  onConfirmForm: (isValid: boolean) => void;
  areValidFields: (fields: string[]) => boolean;
  validateFields: (fields: string[]) => Promise<boolean>;
  onBackButtonClick: () => void;
  hasToBeValidated: boolean;
}

const currentFields = [
  'additionalInfo.siretNumber',
  'address.city',
  'address.postalCode',
  'address.street',
];

const producerNameValues = {
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  email: '',
  phoneNumber: '',
};

const ProducerDetails: FC<ProducerDetailsProps> = ({
  onBackButtonClick,
  onConfirmForm,
  hasToBeValidated,
  areValidFields,
  validateFields,
}) => {
  const { errors, setSubmitting, setFieldValue, values } =
    useFormikContext<typeof initialValues>();
  const [coordinates, setMapCoordinates] = useState(() => {
    return [BESANCON_COORDINATES.latitude, BESANCON_COORDINATES.longitude];
  });
  const { t } = useTranslation();

  const isFieldDataValid = areValidFields(currentFields);

  const onConfirmClick = async () => {
    setSubmitting(true);
    const isValid = await validateFields(currentFields);
    setSubmitting(false);
    onConfirmForm(isValid);
  };

  const setFieldsCoordinates = async (coordinates: number[]) => {
    const [latitude, longitude] = coordinates;

    await setFieldValue('address.location.coordinates.[0]', latitude);
    await setFieldValue('address.location.coordinates.[1]', longitude);
  };

  const handleOnDragDropEnd = (coordinates: number[]) => {
    setMapCoordinates(coordinates);
    setFieldsCoordinates(coordinates);
  };

  useEffect(() => {
    const setCoordinates = async () => {
      await setFieldValue(
        'address.location.coordinates.[0]',
        BESANCON_COORDINATES.latitude,
      );
      await setFieldValue(
        'address.location.coordinates.[1]',
        BESANCON_COORDINATES.longitude,
      );
    };
    setCoordinates();
  }, [setFieldValue]);

  return (
    <StyledForm>
      <BillingFullName
        label={t('producer_name').toString()}
        values={producerNameValues}
      />

      <InputGroup
        label={t('siret_number').toString()}
        error={errors?.additionalInfo?.siretNumber}
      >
        <FormikInput
          fieldName="additionalInfo.siretNumber"
          placeholder="Ex. 123 568 941 00056"
          type="string"
          onChange={(e) => {
            if (
              e.target.value === '' ||
              regexOnlyNumbers.test(e.target.value)
            ) {
              setFieldValue('additionalInfo.siretNumber', e.target.value);
            }
          }}
        />
      </InputGroup>

      <InputGroup
        label={t('vat_number').toString()}
        error={errors?.additionalInfo?.vatNumber}
      >
        <FormikInput
          fieldName="additionalInfo.vatNumber"
          placeholder="Ex. FR12 345 678 901"
          prefix={values.additionalInfo.vatNumber ? 'FR' : <span />}
          type="number"
        />
      </InputGroup>

      <InputGroup
        label={t('shared_capital').toString()}
        error={errors?.additionalInfo?.sharedCapital}
      >
        <FormikInput
          fieldName="additionalInfo.sharedCapital"
          placeholder={t('sas_with_capital').toString()}
          type="text"
        />
      </InputGroup>

      <StyledCommunityTitle isUppercase={true}>
        {t('producer_location')}
      </StyledCommunityTitle>

      <ProducerAddressForm setCoordinates={handleOnDragDropEnd} />
      <StyledCommunityTitle>{t('adjust_map_marker')}</StyledCommunityTitle>
      <MapboxMap
        coordinates={coordinates}
        onDragDropEnd={handleOnDragDropEnd}
      />
      <MarginTop40px />
      <BtnPrimary
        onClick={onConfirmClick}
        isDisabled={hasToBeValidated && !isFieldDataValid}
      >
        {t('proceed')}
      </BtnPrimary>
      <MarginTop24px />

      <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
        {t('cancel')}
      </BtnSecondary>
    </StyledForm>
  );
};

export default ProducerDetails;
