export const UploadFileSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M13.0156 8.75V3.6875H5.9375V21.3125H19.0625V9.73438H14C13.7389 9.73438 13.4885 9.63066 13.3039 9.44606C13.1193 9.26145 13.0156 9.01107 13.0156 8.75Z"
        fill="#98A2B3"
      />
      <path
        d="M20.5297 7.26406L15.4859 2.22031C15.3453 2.07969 15.1555 2 14.9562 2H5C4.58516 2 4.25 2.33516 4.25 2.75V22.25C4.25 22.6648 4.58516 23 5 23H20C20.4148 23 20.75 22.6648 20.75 22.25V7.79609C20.75 7.59687 20.6703 7.40469 20.5297 7.26406ZM14.6094 3.72969L19.0203 8.14062H14.6094V3.72969ZM19.0625 21.3125H5.9375V3.6875H13.0156V8.75C13.0156 9.01107 13.1193 9.26145 13.3039 9.44606C13.4885 9.63066 13.7389 9.73438 14 9.73438H19.0625V21.3125Z"
        fill="#98A2B3"
      />
      <circle cx="18.5" cy="18.5" r="6" fill="#98A2B3" />
      <path
        d="M17.625 16.9826H18.2023V19.6255C18.2023 19.6599 18.2305 19.688 18.2648 19.688H18.7336C18.768 19.688 18.7961 19.6599 18.7961 19.6255V16.9826H19.375C19.4273 16.9826 19.4562 16.9224 19.4242 16.8818L18.5492 15.774C18.5434 15.7665 18.5359 15.7605 18.5274 15.7563C18.5188 15.7522 18.5095 15.75 18.5 15.75C18.4905 15.75 18.4812 15.7522 18.4726 15.7563C18.4641 15.7605 18.4566 15.7665 18.4508 15.774L17.5758 16.881C17.5438 16.9224 17.5727 16.9826 17.625 16.9826ZM21.3594 19.3912H20.8906C20.8562 19.3912 20.8281 19.4193 20.8281 19.4537V20.6568H16.1719V19.4537C16.1719 19.4193 16.1438 19.3912 16.1094 19.3912H15.6406C15.6062 19.3912 15.5781 19.4193 15.5781 19.4537V21.0005C15.5781 21.1388 15.6898 21.2505 15.8281 21.2505H21.1719C21.3102 21.2505 21.4219 21.1388 21.4219 21.0005V19.4537C21.4219 19.4193 21.3938 19.3912 21.3594 19.3912Z"
        fill="black"
      />
    </svg>
  );
};
