import { styled } from 'styled-components';
import { Input, Select } from 'antd';

import { SubHeader } from 'components/shared/Text';

import {
  CheckCircleFilled,
  CheckCircleTwoTone,
  CloseCircleFilled,
  CloseCircleTwoTone,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmailInput = styled(Input)`
  width: 324px;
  height: 40px;
  margin-top: 2px;
`;

export const DocumentTypeSelect = styled(Select)`
  width: 324px;
  height: 40px;
  margin-top: 2px;
`;
export const PasswordInput = styled(Input.Password)`
  input {
    width: 324px;
    height: 32px;
  }
  margin-top: 2px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const ValidationList = styled.div`
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-bottom: 10px;
`;

export const CheckMarkUnselected = styled(CheckCircleFilled)`
  font-size: 16px;
  color: ${COLORS.Grey1};
  margin-right: 10px;
`;

export const CheckMarkSelected = styled(CheckCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green7,
}))`
  font-size: 16px;
  margin-right: 10px;
`;

export const ErrorValidationIcon = styled(CloseCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.ErrorBase,
}))`
  font-size: 14px;
  margin-right: 10px;
`;

export const ErrorConfirmPasswordIcon = styled(CloseCircleFilled)`
  font-size: 12px;
  color: ${COLORS.ErrorBase};
  margin-right: 4px;
`;

export const ValidationItem = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  min-height: 20px;
`;

export const FieldsWithValidation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledDurationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 12;
  gap: 20px;
  align-items: flex-start;
`;

export const StyledCommunityTitle = styled(SubHeader)<{
  isUppercase?: boolean;
}>`
  width: 100%;
  text-align: left;
  margin: 20px 0;
  color: ${COLORS.TextBase};
  text-transform: ${(props) => props.isUppercase && 'uppercase'};
`;

export const StyledDateWrapper = styled.div`
  display: flex;
  flex: 6;
`;

export const StyledDropDownWrapper = styled.div`
  flex: 3;
  height: 40px;
`;

export const StyledSelect = styled(Select)<{
  isError?: boolean;
}>`
  width: 100%;
  height: 100%;
  & .ant-select-selector {
    height: 40px !important;
  }
  & .ant-select-selection-item {
    line-height: 40px !important;
  }
  & .ant-select-selector {
    border-color: ${(props) => props.isError && COLORS.ErrorBase}!important;
  }
`;
