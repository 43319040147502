import { TFunction } from 'i18next';

export const columns = (t: TFunction) => [
  {
    title: t('period').toUpperCase(),
    dataIndex: 'period',
    sorter: (report: any, nextReport: any) => {
      if (!report?.period || !nextReport?.period) {
        return report?.period ? -1 : 1;
      }
      const parseStartDate = (period: string) => {
        const parts = period.split(' – ')[0].split('/');
        return new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);
      };

      const startDate1: any = parseStartDate(report.period);
      const startDate2: any = parseStartDate(nextReport.period);
      return startDate1 - startDate2;
    },
  },
];
