import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormikProvider, useFormik } from 'formik';

import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import { StyledTabWrapper } from '../../../Profile/styles';
import {
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledContainer,
} from '../ProfileDataTab/styles';
import PrmBody from './components/PrmBody';
import { initialValues } from './constants';
import { PageWrapper, StyledAddPrmButton, StyledPlusIcon } from './styles';
import AddPrmForm from './components/AddPrmForm';

const ConsumerSettingsTab = () => {
  const { id } = useParams();
  const {
    data: prmData,
    refetch,
    isLoading: isLoadingPrmData,
  } = useGetPrmByUserIdQuery({ userId: id });
  const [communityId, setCommunityId] = useState('');
  const [addPrmFormOpen, setAddPrmFormOpen] = useState(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    onSubmit: async (values: typeof initialValues) => {},
  });

  const closeAddPrmForm = useCallback(() => setAddPrmFormOpen(false), []);
  const openAddPrmForm = useCallback(() => setAddPrmFormOpen(true), []);
  const handleAddPrm = useCallback(() => {
    setAddPrmFormOpen(true);
    refetch();
    closeAddPrmForm();
  }, [refetch]);

  useEffect(() => {
    if (Array.isArray(prmData)) {
      setCommunityId(prmData[0]?.community);
      const prms = prmData?.map(
        ({
          prmValue,
          name,
          power,
          gridPrice,
          status,
          _id,
          prmType,
          deactivatedAt,
        }) => {
          return {
            id: _id,
            prmValue,
            power,
            name,
            gridPrice,
            status,
            prmType,
            deactivatedAt,
          };
        },
      );
      formik.setFieldValue('prms', prms);
    }
  }, [prmData]);

  return (
    <StyledTabWrapper>
      <AddPrmForm
        userId={id as string}
        communityId={communityId}
        open={addPrmFormOpen}
        onSuccess={handleAddPrm}
        onClose={closeAddPrmForm}
      />
      <StyledContainer>
        <PageWrapper>
          <StyledBlockWrapper>
            <StyledBlockHeader>
              <StyledBlockTitle>{t('prm_numbers')}</StyledBlockTitle>
              <StyledAddPrmButton onClick={openAddPrmForm}>
                <StyledPlusIcon /> {t('add_another_prm_number')}
              </StyledAddPrmButton>
            </StyledBlockHeader>
            <FormikProvider value={formik}>
              <PrmBody
                refetchPrm={refetch}
                communityId={communityId}
                isLoadingPrm={isLoadingPrmData}
              />
            </FormikProvider>
          </StyledBlockWrapper>
        </PageWrapper>
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default ConsumerSettingsTab;
