import { useParams } from 'react-router-dom';

import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';

import Security from '../../../Profile/components/Security';
import { StyledTabWrapper } from '../../../Profile/styles';
import BillingData from './components/BillingData';
import ProfileData from './components/ProfileData';
import { StyledContainer } from './styles';
import { getAppRole } from 'utils';
import { USER_ROLES } from 'constants/types';

const ProducerProfileTab = () => {
  const { id } = useParams();

  const {
    data: member,
    refetch: refetchMember,
    isLoading: isLoadingMember,
  } = useGetMemberByIdQuery(id);
  const userRole = getAppRole();
  const isProducer = userRole === USER_ROLES.PRODUCER;

  return (
    <StyledTabWrapper>
      <StyledContainer>
        <ProfileData
          isLoadingMember={isLoadingMember}
          member={member}
          refetchMember={refetchMember}
        />
        <BillingData
          isLoadingMember={isLoadingMember}
          member={member}
          refetchMember={refetchMember}
        />
        {isProducer && <Security />}
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default ProducerProfileTab;
