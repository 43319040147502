export type IDocument = {
  _id?: string;
  pmo: string;
  prm?: string;
  type: string;
  startDate: string;
  endDate: string;
  signingDate: string;
  extensionType: string;
  src: string;
  createdAt?: string;
  updatedAt?: string;
};
export type IDocumentsTable = {
  documents: Array<IDocument>;
  isLoadingDocuments: boolean;
};
export interface Document {
  id?: string;
  startDate: string;
  endDate: string;
  signingDate: string;
  type: keyof typeof DOCUMENT_TYPES | string;
  file: File | string;
}

export enum DOCUMENT_TYPES {
  CPPA_AGREEMENT = 'cppa_agreement',
  ENEDIS_CONSENT_FORM = 'enedis_consent_form',
  PMO_MEMBERSHIP_AGREEMENT = 'pmo_membership_agreement',
  COLLECTIVE_SELF_CONSUMPTION_MANAGEMENT_AGREEMENT = 'collective_self_consumption_management_agreement',
  LICENSE_OF_OPERATION = 'license_of_operation',
  SUPPORT_AGREEMENT = 'support_agreement',
}

export enum DOCUMENT_STATUS {
  EXPIRED = 'EXPIRED',
  EXPIRE_SOON = 'EXPIRE_SOON',
  ACTIVE = 'ACTIVE',
}
