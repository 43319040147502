import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react';
import {
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
  EnergyContainer,
} from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { useGetEnergyReportsByUserIdQuery } from 'services/reportManagment';
import { capitalizeAllWords, getAppRole } from 'utils/index';
import { useOnboardingTutorialContext } from 'utils/onboardingTutorial/context';
import ConsumerCreateReport from '../../../FinancialData/components/CreateReport/ConsumerCreateReport';
import { Card } from '../FinancialDataTab/styles';
import EnergyDataChart from './components/EnergyDataChart';
import EnergyFollowUpTable from './components/EnergyFollowUpTable';
import { StyledEnergyTable } from './components/EnergyFollowUpTable/styles';
import { getCsvEnergyReport } from './utils';
import { generateEmptyArray } from 'utils';

const ConsumerEnergyData = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const role = getAppRole();
  const { data: consumer } = useGetMemberByIdQuery(id);
  const { data: energyReportsData, isLoading: isLoadingEnergyReports } =
    useGetEnergyReportsByUserIdQuery({
      userId: consumer?._id,
    });
  const [isMounted, setIsMounted] = useState(false);

  const { isActive: isOnboardingTourActive, setRun: setRunOnboardingTour } =
    useOnboardingTutorialContext();

  const isPmoAdminOrOyoAdmin = [
    USER_ROLES.PMO_ADMIN,
    USER_ROLES.ADMIN,
  ].includes(role);

  const tableData = useMemo(
    () => (isLoadingEnergyReports ? generateEmptyArray(10) : energyReportsData),
    [energyReportsData, isLoadingEnergyReports],
  );
  const showEmptyTable = useMemo(
    () =>
      !isLoadingEnergyReports &&
      (!energyReportsData || !energyReportsData.length),
    [energyReportsData, isLoadingEnergyReports],
  );

  useEffect(() => setIsMounted(true), []);
  useEffect(() => {
    if (isOnboardingTourActive && isMounted) {
      setRunOnboardingTour(true);
    }
  }, [isOnboardingTourActive, isMounted]);

  return (
    <Wrapper paddingTop={isPmoAdminOrOyoAdmin ? '0' : '32px'}>
      {!isPmoAdminOrOyoAdmin && (
        <PageHeader background={COLORS.BgLayout}>
          <HeaderContainer>
            <HeaderRow>
              <HeaderRowItem>
                <Header>{capitalizeAllWords(t('energy_data'))}</Header>
              </HeaderRowItem>
              <HeaderRowItem position="flex-end">
                <TextRegular>{t('show_data_for')}</TextRegular>
                <PeriodPicker />
              </HeaderRowItem>
            </HeaderRow>
          </HeaderContainer>
        </PageHeader>
      )}
      <EnergyContainer>
        <EnergyDataChart />
        <div className="onboarding-step-8">
          <ConsumerCreateReport
            datePickerType="date"
            fetchCsvFile={getCsvEnergyReport}
          />
        </div>
        <HeaderRow>
          <HeaderRowItem>
            <Header>{capitalizeAllWords(t('energy_follow_up'))}</Header>
          </HeaderRowItem>
        </HeaderRow>
        {showEmptyTable ? (
          <Card isEmpty fullWidth>
            <TextRegular>{t('no_reports_yet').toUpperCase()}</TextRegular>
          </Card>
        ) : (
          <StyledEnergyTable>
            <EnergyFollowUpTable
              isLoading={isLoadingEnergyReports}
              data={tableData}
            />
          </StyledEnergyTable>
        )}
      </EnergyContainer>
    </Wrapper>
  );
};

export default ConsumerEnergyData;
