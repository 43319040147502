import { LoginResponse } from 'services/auth';

export enum LOCAL_STORAGE_KEYS {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  IdToken = 'id_token',
  SkipOnboardingTutorial = 'skip_onboarding_tutorial',
}

export const setAuthTokens = (tokens: LoginResponse): void => {
  if (!tokens) return;

  if (tokens.access_token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, tokens.access_token);
  if (tokens.refresh_token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.RefreshToken, tokens.refresh_token);
  if (tokens.id_token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.IdToken, tokens.id_token);
};

export const removeAuthTokens = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.AccessToken);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.RefreshToken);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.IdToken);
};

export const setSkipOnboardingLocalStorage = () =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.SkipOnboardingTutorial, 'true');
export const getSkipOnboardingLocalStorage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.SkipOnboardingTutorial);
export const removeSkipOnboardingLocalStorage = () =>
  localStorage.removeItem(LOCAL_STORAGE_KEYS.SkipOnboardingTutorial);
