import React, { useMemo, useState } from 'react';

import styled from 'styled-components';
import { Skeleton } from 'antd';
import { Pie } from '@ant-design/plots';

import { COLORS } from 'constants/colors';

const DEFAULT_CHART_SIZE = 101;
const DEFAULT_COLORS = [COLORS.Yellow5, COLORS.Orange5];
const NO_DATA_COLORS = [COLORS.Grey3];
const DEFAULT_INNERRADIUS = 0.6;
export interface IPieItem {
  type: any;
  value: number;
}

interface IPieChart {
  size?: number;
  color?: COLORS[];
  chartData: IPieItem[];
  innerRadius?: number;
  content?: any;
  handleOnHover?: (type: any) => void;
  handleOnBlur?: (type: any) => void;
  isLoading?: boolean;
  skeletonRadius?: number;
}

const ChartContainer = styled.div`
  border-radius: 50%;
  position: relative;
`;

const CommunityCardChart = React.memo(
  ({
    chartData,
    size = DEFAULT_CHART_SIZE,
    color = DEFAULT_COLORS,
    innerRadius = DEFAULT_INNERRADIUS,
    handleOnHover,
    handleOnBlur,
    isLoading,
    skeletonRadius,
  }: IPieChart) => {
    const [hasAnimation, setHasAnimation] = useState(true);

    const onHover = (event: any) => {
      handleOnHover?.(event?.data?.data?.type);
      setHasAnimation(false);
    };

    const onBlur = (event: any) => {
      handleOnBlur?.(event?.data?.data?.type);
      setHasAnimation(false);
    };

    const hasValue = useMemo(() => {
      return (
        typeof chartData[0].value === 'number' &&
        !Number.isNaN(chartData[0].value)
      );
    }, [chartData]);

    const chartColors = useMemo(
      () => (hasValue ? color : NO_DATA_COLORS),
      [hasValue, color],
    );
    const editedChartData = useMemo(
      () =>
        hasValue
          ? chartData
          : chartData.map((item: IPieItem) => ({ ...item, value: 50 })),
      [hasValue, chartData],
    );

    const config = {
      color: chartColors,
      width: size,
      height: size,
      data: editedChartData,
      tooltip: false,
      legend: false,
      label: false,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius,
      outerRadius: 1,
      appendPadding: 3,
      statistic: {
        title: undefined,
        content: false,
      },
      pieStyle: {
        lineWidth: 3,
        fillOpacity: 0.9,
      },
      animation: hasAnimation,
      interactions: hasValue ? [{ type: 'element-active' }] : false,
      interaction: {
        elementHighlight: true,
      },
      state: {
        active: {
          style: {
            stroke: COLORS.BgContainer,
            fillOpacity: 1,
            lineWidth: hasValue ? 8 : 3,
          },
        },
      },
      onReady: (plot: any) => {
        plot.on('element:mouseenter', onHover);
        plot.on('element:mouseleave', onBlur);
        plot.on('element:mouseout', onBlur);
      },
    };

    return (
      <ChartContainer>
        {/* @ts-ignore */}
        <Pie
          {...config}
          loading={isLoading}
          loadingTemplate={<Skeleton.Avatar size={skeletonRadius} active />}
        />
      </ChartContainer>
    );
  },
);

export default CommunityCardChart;
