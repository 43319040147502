import { TFunction } from 'i18next';

export const columns = (t: TFunction) => [
  {
    title: t('date_created').toUpperCase(),
    width: '12%',
    dataIndex: 'dateCreated',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      return (
        (new Date(
          firstInvoice.dateCreated.split('.').reverse().join('-'),
        ) as any) -
        (new Date(
          nextInvoice.dateCreated.split('.').reverse().join('-'),
        ) as any)
      );
    },
  },
  {
    title: t('invoice_id').toUpperCase(),
    width: '12%',
    dataIndex: 'invoiceId',
    render: (text: string) => `#${text}`,
    sorter: (firstnInvoice: any, nextInvoice: any) =>
      firstnInvoice.invoiceId
        .toString()
        .localeCompare(nextInvoice.invoiceId.toString()),
  },
  {
    title: t('billing_period').toUpperCase(),
    dataIndex: 'billingPeriod',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      if (!firstInvoice?.billingPeriod || !nextInvoice?.billingPeriod) {
        return firstInvoice?.billingPeriod ? -1 : 1;
      }

      const parseStartDate = (period: string) => {
        const parts = period.split(' – ')[0].split('/');
        return new Date(`${parts[2]}/${parts[1]}/${parts[0]}`);
      };

      const startDate1: any = parseStartDate(firstInvoice.billingPeriod);
      const startDate2: any = parseStartDate(nextInvoice.billingPeriod);
      return startDate1 - startDate2;
    },
  },
  {
    title: t('turpe_refund_eur').toUpperCase(),
    dataIndex: 'turpleRefund',
    sorter: (firstnInvoice: any, nextInvoice: any) =>
      parseInt(firstnInvoice.turpleRefund) - parseInt(nextInvoice.turpleRefund),
    render: (amount: number) => amount.toFixed(2),
  },
  {
    title: t('prime_refund_eur').toUpperCase(),
    dataIndex: 'primeRefund',
    sorter: (firstnInvoice: any, nextInvoice: any) =>
      parseInt(firstnInvoice.primeRefund) - parseInt(nextInvoice.primeRefund),
    render: (amount: number) => amount.toFixed(2),
  },
  {
    title: t('accise_refund_eur').toUpperCase(),
    dataIndex: 'acciseRefund',
    sorter: (firstnInvoice: any, nextInvoice: any) =>
      parseInt(firstnInvoice.acciseRefund) - parseInt(nextInvoice.acciseRefund),
    render: (amount: number) => amount.toFixed(2),
  },
  {
    title: t('invoice_amount_eur').toUpperCase(),
    dataIndex: 'invoiceAmount',
    width: '14%',
    render: (amount: number) => amount.toFixed(2),
    sorter: (firstnInvoice: any, nextInvoice: any) =>
      parseInt(firstnInvoice.invoiceAmount) -
      parseInt(nextInvoice.invoiceAmount),
  },
];
