import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import Container from 'components/shared/Container';
import { getUploadDocumentSchema } from '../../../../utils/validation/schemas';
import { useTranslation } from 'react-i18next';
import { StyledWrapper } from '../../styles';
import { useUploadMutation } from '../../../../services/documentManagement';
import { InputGroup } from 'components/shared/InputGroup';
import { Select, UploadProps } from 'antd';
import ActionInfoTab from '../../../shared/ActionInfoTab';
import {
  StyledDurationWrapper,
  StyledDropDownWrapper,
  StyledSelect,
  StyledDateWrapper,
} from './styles';
import FormikInput from '../../../shared/Input/FormikInput';
import FileUpload from '../../../shared/FileUpload';
import { StyledInputGroup } from '../../../shared/InputGroup/styles';
import { BtnPrimary } from '../../../shared/Button';
import { MarginTop16px } from '../../../shared/Margin';
import { formInitialValues } from '../../constants';
import {
  MAX_FILE_SIZE,
  DOCUMENTS_ALLOWED_FORMATS,
  getDocumentTypeByUserRole,
} from '../../../../utils/validation/constants';
import { COLORS } from '../../../../constants/colors';
import { USER_ROLES } from '../../../../constants/global';
import { useParams } from 'react-router-dom';
import { useUrlQuery } from '../../../../utils/url/getUrlQuery';
import Loader from '../../../shared/Loader';
import { Document } from '../../../../services/document.types';

export const FileUploaderProps: UploadProps = {
  multiple: false,
  accept: DOCUMENTS_ALLOWED_FORMATS.join(','),
  maxCount: 1,
  beforeUpload: () => false,
  style: {
    borderColor: COLORS.DraggerInitialBorderColor,
    backgroundColor: COLORS.DraggerBgColor,
  },
};
const UploadDocumentForm: React.FC<{
  userRole: USER_ROLES;
  onUploadSuccess: () => void;
}> = ({ userRole, onUploadSuccess }) => {
  const { t } = useTranslation();
  const [hasToBeValidated, setHasToBeValidated] = useState(false);
  const { id } = useParams();
  const query = useUrlQuery();
  const role = query.get('role') as USER_ROLES;
  const [uploadDocument, { isLoading, isSuccess, reset }] = useUploadMutation();

  const handleUpload = async () => {
    formik.setSubmitting(true);
    const validationErrors = await formik.validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setHasToBeValidated(false);
      formik.handleSubmit();
    } else {
      formik.setSubmitting(false);
      setHasToBeValidated(true);
    }
  };
  const handleSelectChange = (value: unknown) => {
    formik.setFieldValue('type', value);
  };
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: getUploadDocumentSchema(t),
    validateOnChange: hasToBeValidated,
    validateOnBlur: false,
    onSubmit: async (values: Document) => {
      formik.setSubmitting(true);
      await formik.validateForm();
      if (formik.isValid) {
        try {
          await uploadDocument({ documentData: values, prmId: id }).unwrap();
        } catch (err) {
          console.error(err); // TODO add error handling
        }
      }
    },
  });
  const onUploaderChange = async (event: any) => {
    await formik.setFieldValue('file', event.file);
    return formik.validateForm();
  };
  const handleSuccessClick = () => {
    formik.resetForm();
    reset();
    setHasToBeValidated(true);
    onUploadSuccess();
  };

  FileUploaderProps.onChange = onUploaderChange;
  FileUploaderProps.onDrop = onUploaderChange;
  return (
    <Container offset={1}>
      <StyledWrapper>
        {isLoading && <Loader />}
        {!isSuccess ? (
          <>
            <FormikProvider value={formik}>
              <StyledDropDownWrapper>
                <StyledInputGroup>
                  <InputGroup
                    label={t('document_type') as string}
                    error={formik.errors?.type}
                  >
                    <StyledSelect
                      isError={!!formik.errors?.type}
                      placeholder={t('choose_type')}
                      onChange={handleSelectChange}
                    >
                      {getDocumentTypeByUserRole(role || userRole).map(
                        (type) => (
                          <Select.Option
                            key={`document-type-${type}`}
                            value={type}
                          >
                            {t(`document_types.${type.toUpperCase()}`)}
                          </Select.Option>
                        ),
                      )}
                    </StyledSelect>
                  </InputGroup>
                </StyledInputGroup>
              </StyledDropDownWrapper>
              <StyledInputGroup>
                <InputGroup
                  label={t('duration').toString()}
                  error={formik.errors?.startDate || formik.errors?.endDate}
                >
                  <StyledDurationWrapper>
                    <StyledDateWrapper>
                      <FormikInput fieldName="startDate" type="date" />
                    </StyledDateWrapper>
                    <StyledDateWrapper>
                      <FormikInput fieldName="endDate" type="date" />
                    </StyledDateWrapper>
                  </StyledDurationWrapper>
                </InputGroup>
              </StyledInputGroup>
              <StyledInputGroup>
                <InputGroup
                  label={t('signing_date').toString()}
                  error={formik.errors?.signingDate}
                >
                  <StyledDateWrapper>
                    <FormikInput fieldName="signingDate" type="date" />
                  </StyledDateWrapper>
                </InputGroup>
              </StyledInputGroup>
              <MarginTop16px />
              <StyledInputGroup>
                <FileUpload
                  uploaderProps={FileUploaderProps}
                  maxFileSize={MAX_FILE_SIZE}
                  errors={formik.errors.file}
                />
              </StyledInputGroup>
              <MarginTop16px />
              <BtnPrimary onClick={handleUpload}>
                {t('add_document')}
              </BtnPrimary>
            </FormikProvider>
          </>
        ) : (
          <ActionInfoTab
            title="document_uploaded"
            text="new_document_has_been_successfully_uploaded"
            handleClick={handleSuccessClick}
            buttonText="back_to_documents"
          />
        )}
      </StyledWrapper>
    </Container>
  );
};

export default UploadDocumentForm;
