import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  SuccessIcon,
} from 'components/auth/CreatePassword/components/styles';
import { BtnPrimary } from 'components/shared/Button';
import { MarginTop20vh, MarginTop40px } from 'components/shared/Margin';
import { Header, TextRegular } from 'components/shared/Text';

import ContactUs from '../ContactUs';
import { StyledContactUsWrapper } from './styles';

type ActionInfoTabProps = {
  title: string;
  text: string;
  buttonText: string;
  redirectOnClick?: string;
  handleClick?: () => void;
};
const ActionInfoTab: React.FC<ActionInfoTabProps> = ({
  text,
  title,
  buttonText,
  redirectOnClick,
  handleClick,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (handleClick) {
      handleClick();
    }
    if (redirectOnClick) navigate(redirectOnClick);
  };

  return (
    <Container>
      <MarginTop20vh />
      <SuccessIcon />
      <Header>{t(title)}</Header>
      <TextRegular>{t(text)}</TextRegular>
      <MarginTop40px />
      <BtnPrimary onClick={handleOnClick}>{t(buttonText)}</BtnPrimary>
      <StyledContactUsWrapper>
        <ContactUs />
      </StyledContactUsWrapper>
    </Container>
  );
};

export default ActionInfoTab;
