import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import OYO_background from './images/OYO_back_sunshine_1920x1080_2x-2-2.webp';
import LogoImage from 'components/shared/AuthWrapper/images/logo.svg';
import {
  BackgroundImage,
  BottomLine,
  BottomText,
  Container,
  FormWrapper,
  ImageWrapper,
  InnerWrapper,
  Logo,
  LogoContainer,
  LogoWrapper,
  Picture,
  Text,
  TopLine,
} from 'components/shared/AuthWrapper/styles';

interface IAuthWrapper {
  children: ReactNode;
}
const AuthWrapper = ({ children }: IAuthWrapper) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <FormWrapper>
        <TopLine></TopLine>
        <InnerWrapper>{children}</InnerWrapper>
        <BottomLine>
          <BottomText>{t('copyright', { currentYear })}</BottomText>
        </BottomLine>
      </FormWrapper>
      <ImageWrapper>
        <Picture>
          <BackgroundImage src={OYO_background} alt="Background Image" />
          <LogoWrapper>
            <LogoContainer>
              <Logo src={LogoImage} alt="Logo Image" />
              <Text>{t('green_and_autonomous_local_energy')}</Text>
            </LogoContainer>
          </LogoWrapper>
        </Picture>
      </ImageWrapper>
    </Container>
  );
};

export default AuthWrapper;
