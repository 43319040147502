import { TFunction } from 'i18next';

export const columns = (t: TFunction) => [
  {
    title: t('date_created').toUpperCase(),
    dataIndex: 'dateCreated',
    width: '11%',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      return (
        (new Date(
          firstInvoice.dateCreated.split('.').reverse().join('-'),
        ) as any) -
        (new Date(
          nextInvoice.dateCreated.split('.').reverse().join('-'),
        ) as any)
      );
    },
  },
  {
    title: t('invoice_id').toUpperCase(),
    dataIndex: 'invoiceNumber',
    render: (text: string) => `#${text}`,
    sorter: (firstInvoice: any, nextInvoice: any) =>
      firstInvoice.invoiceId
        .toString()
        .localeCompare(nextInvoice.invoiceId.toString()),
  },
  {
    title: t('billing_period').toUpperCase(),
    dataIndex: 'billingPeriod',
    sorter: (firstInvoice: any, nextInvoice: any) => {
      if (!firstInvoice?.billingPeriod || !nextInvoice?.billingPeriod) {
        return firstInvoice?.billingPeriod ? -1 : 1;
      }

      const parseStartDate = (period: string) => {
        const parts = period.split(' – ')[0].split('/');
        return new Date(`${parts[2]}/${parts[1]}/${parts[0]}`);
      };

      const startDate1: any = parseStartDate(firstInvoice.billingPeriod);
      const startDate2: any = parseStartDate(nextInvoice.billingPeriod);
      return startDate1 - startDate2;
    },
  },
];
