import * as yup from 'yup';
import { TFunction } from 'i18next';
import { getUploadDocumentSchema } from 'utils/validation/schemas';

export const prmSchema = (t: TFunction) =>
  yup.object().shape({
    prmValue: yup
      .string()
      .length(14, t('validation.prm_length').toString())
      .required(t('validation.prm_number_required').toString()),
    power: yup
      .number()
      .required(t('validation.subscribed_power_required').toString()),
    user: yup.string().required(),
    community: yup.string().required(),
    gridPriceType: yup.string(),
    gridPrice: yup.object().shape({
      static: yup.number(),
      dynamic: yup.object().shape({
        RH: yup.number().required(),
        HS_HH: yup.number().required(),
        HS_LH: yup.number().required(),
        LS_HH: yup.number().required(),
        LS_LH: yup.number().required(),
      }),
    }),
    documents: yup.array().of(getUploadDocumentSchema(t)),
  });
