import { useCallback, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Formik, FieldArray } from 'formik';
import { DownloadOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import UploadDocumentForm from './uploadFile';
import { BtnPrimary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { useCreateUserPrmMutation } from 'services/prmManagement';
import { useUploadMutation } from 'services/documentManagement';
import { formInitialValues as documentInitialValues } from 'components/documents/constants';
import { prmSchema } from './add-prm.schema';
import {
  StyledFormRow,
  StyledGridPriceTitle,
  StyledGridFormRow,
  UploadDocumentBtn,
  StyledDocContainer,
  StyledDocTitleRow,
  StyledDocTitle,
  StyledDeleteIcon,
} from './styles';

enum PriceType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

type PricesFormProps = {
  onSuccess: any;
  initialData: { user: string; community: string };
};

const GridPriceLabel = ({ type }: { type: string }) => {
  const { t } = useTranslation();
  return (
    <div>
      <strong>{type},</strong>
      <div>{t('eur_kwh')}</div>
    </div>
  );
};

const PrmForm = ({ onSuccess, initialData }: PricesFormProps) => {
  const { t } = useTranslation();
  const [canValidateOnChange, setCanValidateOnChange] = useState(false);

  const [addPrm, { isLoading: isLoadinAddPrm }] = useCreateUserPrmMutation();
  const [uploadDocument, { isLoading: isUploadingDocument }] =
    useUploadMutation();

  const priceTypeOptions = useMemo(
    () => [
      { label: t('static'), value: PriceType.STATIC },
      { label: t('dynamic'), value: PriceType.DYNAMIC },
    ],
    [t],
  );

  const onSubmit = useCallback(
    async (values: any, { resetForm }: any) => {
      const {
        prmValue,
        power,
        user,
        community,
        gridPriceType,
        gridPrice,
        documents,
      } = values;
      const newPrm: any = await addPrm({
        prmValue: prmValue.toString(),
        power,
        user,
        community,
        gridPriceType,
        gridPrice:
          gridPriceType === PriceType.STATIC
            ? { static: gridPrice.static }
            : { dynamic: gridPrice.dynamic },
      });
      if (newPrm && documents.length) {
        const promises = documents.map((doc: any) =>
          uploadDocument({
            documentData: doc,
            prmId: newPrm.data._id,
          }).unwrap(),
        );
        await Promise.all(promises);
      }
      onSuccess();
      resetForm();
    },
    [addPrm, onSuccess, uploadDocument],
  );

  return (
    <Formik
      validateOnChange={canValidateOnChange}
      validateOnBlur={canValidateOnChange}
      initialValues={{
        ...initialData,
        documents: [],
        prmValue: '',
        power: '',
        gridPriceType: PriceType.DYNAMIC,
        gridPrice: {
          static: 0,
          dynamic: { RH: 0, HS_HH: 0, HS_LH: 0, LS_HH: 0, LS_LH: 0 },
        },
      }}
      validationSchema={prmSchema(t)}
      onSubmit={onSubmit}
    >
      {({
        isValid,
        values,
        errors,
        validateForm,
        setFieldValue,
        handleSubmit,
      }) => {
        const onPriceTypeChanged = (value: PriceType) => {
          if (value === PriceType.STATIC) {
            setFieldValue(
              'gridPrice.dynamic.RH',
              values.gridPrice.dynamic.HS_HH,
            );
            setFieldValue(
              'gridPrice.dynamic.LS_LH',
              values.gridPrice.dynamic.HS_HH,
            );
            setFieldValue(
              'gridPrice.dynamic.LS_HH',
              values.gridPrice.dynamic.HS_HH,
            );
            setFieldValue(
              'gridPrice.dynamic.HS_LH',
              values.gridPrice.dynamic.HS_HH,
            );
            setFieldValue('gridPrice.static', values.gridPrice.dynamic.HS_HH);
          }
          setFieldValue('gridPriceType', value);
        };

        const isStaticPriceType = values.gridPriceType === PriceType.STATIC;

        const onPriceChanged = (numValue: any) => {
          if (isStaticPriceType) {
            setFieldValue('gridPrice.dynamic.RH', numValue);
            setFieldValue('gridPrice.dynamic.LS_LH', numValue);
            setFieldValue('gridPrice.dynamic.LS_HH', numValue);
            setFieldValue('gridPrice.dynamic.HS_LH', numValue);
            setFieldValue('gridPrice.static', numValue);
          }
          setFieldValue('gridPrice.dynamic.HS_HH', numValue);
        };

        const handleOnSave = () => {
          validateForm();
          setCanValidateOnChange(true);
          if (isValid) {
            handleSubmit();
          }
        };

        const addDoc = () => {
          setFieldValue('documents', [
            ...values.documents,
            { ...documentInitialValues },
          ]);
        };

        return (
          <Form>
            <StyledFormRow>
              <InputGroup
                error={errors.prmValue}
                label={t('prm_number').toString().toUpperCase()}
              >
                <FormikInput
                  type="number"
                  fieldName="prmValue"
                  placeholder={'Ex. 22516914714270'}
                />
              </InputGroup>
            </StyledFormRow>
            <StyledFormRow>
              <InputGroup
                error={errors.power}
                label={t('subscripted_power').toString()}
              >
                <FormikInput
                  type="number"
                  fieldName="power"
                  placeholder={'Ex. 36 KVA'}
                  suffix={t('kva')}
                />
              </InputGroup>
            </StyledFormRow>
            <StyledFormRow>
              <StyledGridPriceTitle>
                <Trans
                  i18nKey="add_grid_prices_optional"
                  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                  components={{ span: <span /> }}
                />
              </StyledGridPriceTitle>
            </StyledFormRow>
            <StyledFormRow>
              <InputGroup label={t('price_type').toString()}>
                <Select
                  defaultValue={PriceType.DYNAMIC}
                  onChange={onPriceTypeChanged}
                  options={priceTypeOptions}
                  getPopupContainer={(node) => node.parentNode}
                  status={errors.gridPriceType ? 'error' : undefined}
                />
              </InputGroup>
            </StyledFormRow>
            <StyledGridFormRow>
              <InputGroup label={<GridPriceLabel type={t('hshp')} />}>
                <FormikInput
                  onChange={onPriceChanged}
                  type="number"
                  fieldName="gridPrice.dynamic.HS_HH"
                  placeholder={'0.530'}
                />
              </InputGroup>
              <InputGroup label={<GridPriceLabel type={t('hshc')} />}>
                <FormikInput
                  disabled={isStaticPriceType}
                  type="number"
                  fieldName="gridPrice.dynamic.HS_LH"
                  placeholder={'0.530'}
                />
              </InputGroup>
              <InputGroup label={<GridPriceLabel type={t('bshp')} />}>
                <FormikInput
                  disabled={isStaticPriceType}
                  type="number"
                  fieldName="gridPrice.dynamic.LS_HH"
                  placeholder={'0.530'}
                />
              </InputGroup>
            </StyledGridFormRow>
            <StyledGridFormRow>
              <InputGroup label={<GridPriceLabel type={t('bshc')} />}>
                <FormikInput
                  disabled={isStaticPriceType}
                  type="number"
                  fieldName="gridPrice.dynamic.LS_LH"
                  placeholder={'0.530'}
                />
              </InputGroup>
              <InputGroup label={<GridPriceLabel type={t('hdp')} />}>
                <FormikInput
                  disabled={isStaticPriceType}
                  type="number"
                  fieldName="gridPrice.dynamic.RH"
                  placeholder={'0.530'}
                />
              </InputGroup>
              <div />
            </StyledGridFormRow>

            <FieldArray
              name="documents"
              render={({ remove }) =>
                values.documents.map((_: any, index: number) => (
                  <StyledDocContainer key={index}>
                    <StyledDocTitleRow>
                      <StyledDocTitle>{t('add_document')}</StyledDocTitle>
                      <StyledDeleteIcon onClick={() => remove(index)} />
                    </StyledDocTitleRow>
                    <UploadDocumentForm index={index} />
                  </StyledDocContainer>
                ))
              }
            />

            <StyledFormRow>
              <UploadDocumentBtn onClick={addDoc} icon={<DownloadOutlined />}>
                {t('upload_document')}
              </UploadDocumentBtn>

              <BtnPrimary
                isDisabled={!isValid || isLoadinAddPrm || isUploadingDocument}
                onClick={handleOnSave}
              >
                {t('add_prm')}
              </BtnPrimary>
            </StyledFormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PrmForm;
